import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { IProjectResources } from '@app/core/contracts/project';
import { User } from '@app/core/contracts/users';
import { AuthService } from '@app/core/services/auth-service';
import { DataService } from '@app/core/services/data-service';
import { PeriodicElement } from '@app/features/compliance/pages/tenant-lifecycle/tenant-lifecycle.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-switch-user-selector',
  templateUrl: './switch-user.component.html',
  styleUrls: ['./switch-user.component.scss']
})
export class SwitchUserComponent implements OnInit {
  @Output() changed = new EventEmitter<string[]>();
  UserDataSource: User[] = [];
  filterDataSource = new MatTableDataSource<User>([]);
  displayedColumns: string[] = ['select', 'userName', 'resourceTypes'];
  selection = new SelectionModel<any>(false, []);
  componentDestroyed$: Subject<boolean> = new Subject();
  searchText: string = '';
  resources!: IProjectResources[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<any>, private dataService: DataService, private authService: AuthService) {}

  ngOnInit() {
    this.getUsers();
    this.getResourceTypes();
  }

  // #region GET DATA
  getUsers() {
    this.dataService
      .getAllUsers()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.UserDataSource = data;
        this.filterDataSource = new MatTableDataSource(data);
      });
  }

  getResourceTypes() {
    this.dataService.getResourceTypes().subscribe((val) => {
      this.resources = [{ id: 'ALL', name: 'ALL' }, ...val];
    });
  }
  // #endregion

  // #region ROW MANAGEMENT
  selectRow(event: any) {
    event.stopPropagation();
    this.selection.clear();
    if (!this.selection.isSelected(event)) {
      this.selection.toggle(event);
    }
  }

  switchUser() {
    this.authService.userSwitch(this.selection.selected[0].id).subscribe(() => {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    });
  }
  // #endregion

  // #region FILTERS
  applyFilter() {
    this.filterDataSource.filter = this.searchText.trim().toLowerCase();
  }

  onResourceTypeChange(event: any) {
    const resourceTypeId = event.value;

    if (resourceTypeId === 'ALL') {
      this.filterDataSource = new MatTableDataSource(this.UserDataSource);
    } else {
      let filteredUsers = this.UserDataSource.filter((user) => user.children.some((type) => type.id === resourceTypeId));
      this.filterDataSource = new MatTableDataSource(filteredUsers);
    }
  }
  // #endregion
}
