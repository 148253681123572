

<div *ngIf="title!==''">
  <h2 class="title">
    {{title}}
  </h2>
  <hr class="horizontal-line ">

</div>

<div style="display: flex; justify-content: center;">
  <div *ngIf="header || footer " class="number-card-container" [style.background-color]="fillColor"
    [style.background-color]="fillColor"
    [style]="'width:' + width +'px;max-width:' + width +'px;'">
    <div *ngIf="header" class="header" [style.color]="textColor">{{header}}</div>
    <ngx-charts-number-card
      [view]="view"
      [scheme]="colorScheme"
      [results]="dataSource"
      [cardColor]="cardColor"
      [textColor]="textColor"
      (select)="onSelect($event)">
    </ngx-charts-number-card>
    <div *ngIf="footer" class="footer" [style.color]="textColor">{{footer}}</div>
  </div>
  <ngx-charts-number-card
    *ngIf="!header && !footer "
    [view]="view"
    [scheme]="colorScheme"
    [results]="dataSource"
    [textColor]="textColor"
    [animations]="true"
    [cardColor]="cardColor"
    (select)="onSelect($event)">
  </ngx-charts-number-card>
</div>
