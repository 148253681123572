import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { AuthService } from './auth-service';
import { Observable, from, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(private authService: AuthService, private router: Router, private snackbar: MatSnackBar) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.permissions.length < 1) {
      this.authService.setPermissions();
    }
    return this.check(route, state);
  }

  canLoad(route: Route): boolean {
    const url: any = route.path;
    if (this.authService.urlallowed(url)) {
      return true;
    } else {
      this.snackbar.open(`User does not have access to view this page: ${url}`, 'Ok', { duration: 5000 });
    }
    this.router.navigate(['secure/dashboard']);
    return false;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.check(route, state);
  }

  check(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return from(this.authService.authenticated()).pipe(
      switchMap((authenticated) => {
        if (!authenticated) {
          this.router.navigate([''], { queryParams: { returnUrl: state.url } });
          return of(false);
        } else {
          return from(this.authService.authorised(route.data.roles)).pipe(
            map((authorised) => {
              if (!authorised) {
                this.router.navigate(['unauthorized'], { queryParams: { returnUrl: state.url } });
              }
              return authorised;
            })
          );
        }
      })
    );
  }
}
