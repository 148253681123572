<mat-toolbar style="background-color: #04172E; color: white; position: sticky; top: 0px; z-index: 1;">
  <span fxFlex>Switch User</span>

  <div fxLayout="row" fxLayoutAlign="end">
    <button mat-raised-button (click)="switchUser()">Switch User</button>
  </div>

  <div class="backButton">
    <button mat-icon-button class="icon" mat-dialog-close hint="cancel">
      <mat-icon matTooltip="Close">close</mat-icon>
    </button>
  </div>
</mat-toolbar>
<div>
  <div>
    <!-- CONTENT -->
<div style="display: flex; padding: 10px;">
<div style="width: 49%; padding-right: 10px;">
  <mat-form-field style="width: 100%;">
    <mat-label>Resource Type Filter</mat-label>
    <mat-select (selectionChange)="onResourceTypeChange($event)" value="ALL">
      <mat-option *ngFor="let resourceType of resources" [value]="resourceType.id">
        {{resourceType.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

  <div style="width: 49%;">
    <mat-form-field style="width: 100%; padding-left: 10px;">
      <mat-label>User Search</mat-label>
      <input matInput [(ngModel)]="searchText" (keyup)="applyFilter()">
    </mat-form-field>
  </div>
</div>



<div style="height: 400px; overflow: auto;">
  <mat-table [dataSource]="filterDataSource" (click)="selectRow($event)" matSort matSortActive="userName" matSortDirection="asc" matSortDisableClear class="custom-mat-table">
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef>
        <!-- Remove the "select all" checkbox -->
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="userName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> User Name </mat-header-cell>
      <mat-cell *matCellDef="let user"> {{user.firstname}} {{user.lastname}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="resourceTypes">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Resource Types </mat-header-cell>
      <mat-cell *matCellDef="let user">
        <ng-container *ngFor="let resourceType of user.resourceType; let last = last">
          {{ resourceType.name }}{{ !last ? ', ' : '' }}
        </ng-container>
      </mat-cell>
    </ng-container>
  
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>
    <!-- CONTENT -->
  </div>
</div>
