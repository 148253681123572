import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Pager {
  totalItems = 0;
  currentPage = 0;
  pageSize = 0;
  totalPages = 0;
  startPage = 0;
  endPage = 0;
  startIndex = 0;
  endIndex = 0;
  pages: number[] = [];
}

@Injectable()
export class PagerService {
  getPager(totalItems: number = 0, currentPage: number = 1, pageSize: number = 10) {
    // calculate total pages
    const totalPages: number = Math.ceil(totalItems / pageSize);
    let startPage: number;
    let endPage: number;

    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    const startIndex: number = (currentPage - 1) * pageSize;
    const endIndex: number = Math.min(startIndex + pageSize - 1, totalItems - 1);
    // create an array of pages to ng-repeat in the pager control
    const pages: number[] = [...Array(endIndex - startIndex + 1).keys()].map((i) => i + startPage);

    // return object with all pager properties required by the view

    const p = new Pager();
    p.totalItems = totalItems;
    p.currentPage = currentPage;
    p.pageSize = pageSize;
    p.totalPages = totalPages;
    p.startPage = startPage;
    p.endPage = endPage;
    p.startIndex = startIndex;
    p.endIndex = endIndex;
    p.pages = pages;

    return p;
  }
}
