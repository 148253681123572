
<div *ngIf="title!==''">
  <h2 class="title">
    {{title}}
  </h2>
  <hr class="horizontal-line ">

</div>

<div style="display: flex; justify-content: center;">
  <ngx-charts-bar-vertical
    [view]="view"
    [scheme]="colorScheme"
    [results]="dataSource"
    [gradient]="gradient"
    [xAxis]="showXAxis"
    [yAxis]="showYAxis"
    [legend]="showLegend"
    [showXAxisLabel]="showXAxisLabel"
    [showYAxisLabel]="showYAxisLabel"
    [xAxisLabel]="xName"
    [showDataLabel]="true"
    [yAxisLabel]="yName"
    (select)="onSelect($event)">
    <ng-template #tooltipTemplate let-model="model">
      <!-- Your custom tooltip content here -->
      <div>{{ model.name }}: {{ model.value }}</div>
    </ng-template>
  </ngx-charts-bar-vertical>
</div>
