<div fxLayout="row" [fxLayoutAlign]="centerHeader ? 'center' : 'left center'">
  <span *ngIf="!filteredDataSource && !smartFiltersActivated.includes(column)">{{ friendlyName }}</span>
  <span class="columnfilterButton" *ngIf="filteredDataSource && !smartFiltersActivated.includes(column) && !columnFiltersActivated.includes(column)" (click)="selectHeader()">🔽</span>
  <span class="columnfilterButton" *ngIf="filteredDataSource && !smartFiltersActivated.includes(column) && columnFiltersActivated.includes(column)" (click)="removeColumn()">❌ </span>
  <span *ngIf="filteredDataSource && !smartFiltersActivated.includes(column)" class="columnfilterButton">
    {{ friendlyName }}
  </span>
</div>

<mat-form-field class="filter-field" *ngIf="smartFiltersActivated.includes(column) && columnSearch" appearance="fill">
  <mat-label>{{ friendlyName }}</mat-label>
  <mat-select class="cust-mat-select" [(value)]="selectedOptions" (openedChange)="columnHeaderClosed($event)" #filterSelection mat-select multiple (selectionChange)="filter($event)">
    <div fxLayout="row" class="search-container">
      <mat-icon matTooltip="Select all" class="select-all" (click)="toggleSelectAll()"> select_all </mat-icon>
      <input class="search-input" matInput [formControl]="inputFilter_txt" [textContent]="'Search'" />
    </div>

    <mat-select-trigger *ngIf="ShowAllText">
      {{ 'All' }}
    </mat-select-trigger>

    <mat-option class="select-option" *ngFor="let columnSearch of filteredDate | async" [value]="columnSearch?.key ? columnSearch?.key + '/' + columnSearch?.value : columnSearch">
      {{ formatData(columnSearch?.value ? columnSearch?.value : columnSearch === '' ? 'Blank' : columnSearch === null ? 'Blank' : columnSearch === undefined ? 'Blank' : columnSearch) }}
    </mat-option>
  </mat-select>
</mat-form-field>
