import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ExpandSettings } from '../../../classes/expandSettings';
import { TableSettings } from '../../../classes/tableSettings';

@Component({
  selector: 'app-expand-column',
  templateUrl: './expand-column.component.html',
  styleUrls: ['./expand-column.component.scss']
})
export class ExpandColumnComponent implements OnInit, OnChanges {
  @Input() elementIn: string = '';
  @Input() tableSettingsIn: TableSettings = new TableSettings();
  @Input() expandSettingsIn: ExpandSettings = new ExpandSettings();
  @Input() currentLevel: number = 1;
  @Output() rowExpand = new EventEmitter<any>();

  element: any;
  expandSettings!: ExpandSettings;
  tableSettings!: TableSettings;

  matIconStyle = '';

  constructor() {}

  ngOnInit() {
    this.element = this.elementIn;
    this.expandSettings = this.expandSettingsIn;
    this.tableSettings = this.tableSettingsIn;
    this.matIconStyle = this.getStyle();
  }

  ngOnChanges() {
    this.element = this.elementIn;
    this.tableSettings = this.tableSettingsIn;
    this.matIconStyle = this.getStyle();
  }

  expandRow() {
    this.rowExpand.emit(this.element);
  }

  getStyle() {
    if (this.currentLevel == 1) {
      var color = this.tableSettings.row1_Styling?.color;
      color = color === '' ? this.expandSettings?.iconColor : color;
      return `color:${color}`;
    } else if (this.currentLevel == 2) {
      var color = this.tableSettings.row2_Styling?.color;
      color = color === '' ? this.expandSettings?.iconColor : color;
      return `color:${color}`;
    } else if (this.currentLevel == 3) {
      var color = this.tableSettings.row3_Styling?.color;
      color = color === '' ? this.expandSettings?.iconColor : color;
      return `color:${color}`;
    } else if (this.currentLevel == 4) {
      var color = this.tableSettings.row4_Styling?.color;
      color = color === '' ? this.expandSettings?.iconColor : color;
      return `color:${color}`;
    } else {
      return `color:${this.expandSettings.iconColor}`;
    }
  }
}
