export interface ISAPOAType {
  id: string;
  name: string;
  code?: number;
}

export enum SapoaTypeEnum {
  Usable = 1,
  PrimaryCommon = 2,
  SharedUsable = 3,
  CommonBlock = 4,
  CommonProperty = 5,
  Supplementary = 6,
  Rentable = 7,
  Lettable = 8
}
