<mat-toolbar class="toolbar">
  <mat-icon>apartment</mat-icon> <span style="padding-left: 10px;" fxFlex> Data Import for {{property}}</span>

  <div fxLayout="row" fxLayoutAlign="end">
    <mat-icon class="icons" mat-dialog-close matTooltip="Close">close</mat-icon>
  </div>

</mat-toolbar>

<mat-dialog-content>

</mat-dialog-content>
<div fxLayout="column" fxLayoutAlign="center center" class="message-container">
  <div class="restrict-width">
    <span class="material-symbols-outlined icon">
      {{icon}}
    </span>
    <p class="message">
      {{message}}
    </p>
  </div>

</div>
