
<div *ngIf="title!==''">
  <h2 class="title">
    {{title}}
  </h2>
  <hr class="horizontal-line ">

</div>

<div style="display: flex; justify-content: center;">
  <ngx-charts-pie-grid
    [view]="view"
    [results]="dataSource"
    [scheme]="colorScheme"
    [animations]="true"
    (select)="onSelect($event)">
    <ng-template #label let-entry="data">
      {{ entry.label }}: {{ entry.value }}
    </ng-template>
  </ngx-charts-pie-grid>
</div>
