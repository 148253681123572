<div style="padding-left: 5px;" fxLayout="row" fxLayoutAlign="Left center">

  <span class="columnfilterButton"
    *ngIf="filteredDataSource.length>0&& !smartFiltersActivated"
    (click)="HeaderSelected()">🔽</span>
  <span class="columnfilterButton"
    *ngIf="filteredDataSource.length>0&& smartFiltersActivated"
    (click)="removeColumn()">❌ </span>
  <span class="columnfilterButton" (click)="filteredDataSource.length>0?smartFiltersActivated?removeColumn():HeaderSelected():''">
    {{friendlyName}}
  </span>
</div>

<mat-form-field class="FilterField" *ngIf="smartFiltersActivated" appearance="fill">

  <mat-select class="cust-mat-select" [(value)]="selectedOptions" (openedChange)="columnHeaderClosed($event)" #filterSelection mat-select multiple
    (selectionChange)="filter($event)">
    <div fxLayout="row" class="search-container">
      <mat-icon matTooltip="Select all" class="select-all" (click)="toggleSelectAll()">
        select_all
      </mat-icon>
      <input class="search-input" matInput
        [formControl]="inputFilter_txt"
        [textContent]="'Search'">
    </div>

    <mat-option class="select-option"
      *ngFor="let columnSearch of filteredDate | async"
      [value]="columnSearch.key?columnSearch.key + '/' +columnSearch.value :columnSearch">
      {{ columnSearch.value ? columnSearch.value : columnSearch === '' ? 'Blank' : columnSearch }}
    </mat-option>
  </mat-select>
</mat-form-field>
