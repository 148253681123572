import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-custom-action-pop-up-selector',
  templateUrl: './customActionPopUp.component.html',
  styleUrls: ['./customActionPopUp.component.scss']
})
export class customActionPopUpComponent {
  @Output() changed = new EventEmitter<string[]>();
  title: string = 'TITLE';
  description: string = 'DESCRIPTION';
  actions: { optionLabel: string; optionValue: boolean }[] = [
    { optionLabel: 'No', optionValue: false },
    { optionLabel: 'Yes', optionValue: true }
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<any>) {
    this.title = data.title;
    this.description = data.description;
    this.actions = data.actions;
  }

  SaveSelection() {
    this.dialogRef.close();
  }
}
