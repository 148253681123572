import { Injectable } from '@angular/core';
import { AuthService } from './auth-service';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class ReportService {
  constructor(private authService: AuthService) {}

  ExecuteReport(leaseid: string) {
    window.open(`${environment.API_URL}report/executereport/${leaseid}`, 'blank');
  }
}
