
<div *ngIf="title!==''">
  <h2 class="title">
    {{title}}
  </h2>
  <hr class="horizontal-line ">
</div>

<div style="display: flex; justify-content: center;">
  <ngx-charts-advanced-pie-chart
    [view]="view"
    [scheme]="colorScheme"
    [results]="dataSource"
    [gradient]="gradient"
    [label]="label"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)">
  </ngx-charts-advanced-pie-chart>
</div>
