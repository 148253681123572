import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

/**
 * Prefixes all requests with `environment.API_URL`.
 */
@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
  forcelink: any = 'dev6896-core.forcelink.net/forcelink/rest/smartlinkmanager/createUpdateProperty';

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var url: string = request.url == this.forcelink ? 'https://' + request.url : environment.API_URL + request.url;
    if (!/^(http|https):/i.test(request.url)) {
      request = request.clone({ url: url });
    }
    return next.handle(request);
  }
}
