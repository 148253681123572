// iframe.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { DataService } from './data-service';

@Injectable({
  providedIn: 'root'
})
export class IframeService {
  websiteUrl: any;
  constructor(private dataService: DataService) {}

  openIframe(): any {
    
  }
}
