import { Component, ViewChild, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { PendingRequestsInterceptor } from '@app/core/interceptors/pending-requests-interceptor';
import { AuthService } from '@app/core/services/auth-service';
import { Observable, Subject, catchError, debounceTime, map, merge, of, startWith, switchMap, takeUntil } from 'rxjs';
import { AppShellDialogComponent } from './app-shell-dialog/app-shell-dialog.component';
import { IPermissions, IUserContext, IUserFilter, User } from '@app/core/contracts/users';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataService } from '@app/core/services/data-service';
import { IframeService } from '@app/core/services/IframeService';
import { IframeComponent } from '@app/shared/components/iframe/iframe.component';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { SwitchUserComponent } from '@app/shared/components/switchUser/switch-user.component';
import { MaintainUserDialogComponent } from '@app/shared/components/maintainUser/maintain-user.component';

@Component({
  selector: 'app-shell',
  templateUrl: './app-shell.component.html',
  styleUrls: ['./app-shell.component.scss']
})
export class AppShellComponent implements OnInit {
  @ViewChild('sidenav', { static: false }) sidenav: MatSidenav | undefined;
  // @ViewChild(IframeComponent) Iframe!: IframeComponent;
  isLoading$: Observable<boolean>;
  logoutDialog: MatDialogRef<AppShellDialogComponent> | undefined;
  groups: { name: string; items: any[] }[] = [];
  usersDataSource: User[] = [];
  filterUsersDataSource!: Observable<User[]>;
  selectedUser = new UntypedFormControl('');
  componentDestroyed$: Subject<boolean> = new Subject();
  userFilterCtrl = new FormControl();
  search: IUserFilter;
  resultsLength = 0;
  userContext!: IUserContext;
  isSuperuser: boolean = false;

  isProfileOpen = false;
  isInfoOpen = false;

  constructor(public authService: AuthService, private pendingRequestsInterceptor: PendingRequestsInterceptor, private dialog: MatDialog, private router: Router, public iframeService: IframeService) {
    this.isLoading$ = this.pendingRequestsInterceptor.pendingRequestsStatus$;
    this.getItems();
    this.search = {
      filter: ''
    };
  }

  ngOnInit() {
    this.userContext = JSON.parse(localStorage.getItem('user_context') || '{}');
    this.isSuperuser = this.userContext.roles.some((role) => role.roleBW === 1);
    this.authService.downloadProfileFile();
  }
  openDialog(): void {
    this.closeCards();

    this.logoutDialog = this.dialog.open(AppShellDialogComponent, {
      disableClose: false
    });

    this.logoutDialog.afterClosed().subscribe((data) => {
      if (data === true) {
        this.authService.logout();
        this.router.navigate(['']);
      }
    });
  }

  closeCards() {
    this.isProfileOpen = false;
    this.isInfoOpen = false;
  }

  getItems() {
    const maxGroupIndex = Math.max(...this.authService.menuPermissions.map((item) => item.groupIndex));
    this.groups = [];
    for (let i = 0; i <= maxGroupIndex; i++) {
      const group = {
        name: `Group ${i + 1}`,
        items: this.authService.menuPermissions.filter((item) => item.groupIndex === i)
      };
      if (group.items.length > 0) this.groups.push(group);
    }
  }

  private _filter(value: string): User[] {
    const filterValue = value.toLowerCase();
    return this.usersDataSource.filter((user) => user.firstname.toLowerCase().includes(filterValue));
  }

  userSelected(userId?: string) {
    this.authService.userSwitch(userId ?? '').subscribe(() => {});
  }

  close() {
    if (this.sidenav) {
      this.sidenav.close();
    }
  }

  openMaintenance() {
    this.router.navigate(['/secure/spacelink']);
  }

  switchUserDialog() {
    this.closeCards();
    this.logoutDialog = this.dialog.open(SwitchUserComponent, {
      width: '800px',
      height: '600px',
      disableClose: false
    });

    this.logoutDialog.afterClosed().subscribe((data) => {
      if (data === true) {
        this.authService.logout();
        this.router.navigate(['']);
      }
    });
  }

  maintainUserDialog() {
    this.closeCards();
    var editDialog = this.dialog.open(MaintainUserDialogComponent, {
      width: '80%',
      height: '80%',
      disableClose: true,
      data: {
        userId: this.authService.userContext.id,
        editMode: true,
        imageUrl: this.authService.imageUrl,
        profileView: true
      }
    });

    editDialog.afterClosed().subscribe((data) => {
      if (data === true) {
      }
    });
  }

  openWebsite() {
    this.closeCards();
    window.open('https://cbos.co.za', '_blank');
  }

  reportABug() {
    this.closeCards();
    const email = 'support@spaceup.co.za';
    const subject = 'Bug Report';
    const body = 'Please describe the bug you encountered:';
    window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  }
}
