<form [formGroup]="searchForm">
  <mat-form-field>
    <mat-label>{{label}}</mat-label>
    <input type="text"
      [(ngModel)]="autocomplete.input"
      placeholder="Search for an address"
      #autoInput
      matInput
      formControlName="searchInput"
      [matAutocomplete]="auto">
    <mat-autocomplete (optionSelected)="selectSearchResult($event)"
      [displayWith]="displayFn"
      [panelWidth]="'auto'"
      #auto="matAutocomplete">
      <mat-option *ngFor="let option of autocompleteItems"
        [value]="option">
        {{option.description}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
<div #postalCode></div>