import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { DataService } from '@app/core/services/data-service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { IBuilding, IBuildingFilter } from '@app/core/contracts/building';
import { IUnitUsage } from '@app/core/contracts/unitUsage';
import { ISAPOAType } from '@app/core/contracts/sapoaType';
import { IFloor } from '@app/core/contracts/floor';
import { IPocket } from '@app/core/contracts/pocket';
import Swal from 'sweetalert2';
import { IPocketSelection } from '@app/core/contracts/pocket';
import { ESLint } from 'eslint';
import { IProperty } from '@app/core/contracts/property';
import { IDirPocket } from '@app/core/contracts/dir';
import { ISettingsDocNode } from '@app/core/contracts/TreeNode';

interface ISelectedPocket {
    buildingId: string,
    buildingname: string,
    floorId: string,
    floorname: string,
    pocketId: string,
    pocketname: string,
    usableArea: string,
    rentableArea: string,
    supplementaryArea: string,
    sapoaName: string,
    tenant?: string,
    unitUsage?: string,
    currentInstruction?: string,
    pendingInstruction?: string,
    leaseTerm?: string,
    boExpiryDate?: string
}

const emptyGuid = "00000000-0000-0000-0000-000000000000";

function isObjectNotInArray(object: any, array: any[]){
  return array.every(x => {
    return x != object;
  })
};

@Component({
  selector: 'app-building-selector',
  templateUrl: './buildingSelectorComponent.component.html',
  styleUrls: ['./buildingSelectorComponent.component.scss']
})
export class BuildingSelectorComponent implements OnInit {
  @Output() changed = new EventEmitter<any>();
  propertyId!: string;
  propertyName!: string;
  viewState!: string;
  leaseTypeCode?: number;
  unitUsages!: IUnitUsage[];
  sapoaTypes!: ISAPOAType[];
  panelOpenState = false;
  componentDestroyed$: Subject<boolean> = new Subject();
  search!: IBuildingFilter;
  buildingData!: IBuilding[];
  buildingDataHolder!: any[];
  resultsLength = 0;
  multipleSelectionMode = true;
  selectedBuildings: string[] = [];
  selectedFloors: string[] = [];
  selectedPockets: string[] = [];
  linkedPocketsMap: {from: ISelectedPocket, to: ISelectedPocket}[] = [];
  responseObj: any[] = [];
  projectScopeRule: number = 0;
  filterType = '';
  identifier1 = '';
  identifier2 = '';
  identifier3 = '';
  // Rules vailiables
  SelectedTenant = '';
  defaultEmptyString = 'Not Set';
  selectedLevel = '';
  levelSelected = [];
  property!: IProperty;

  constructor(private dataService: DataService, @Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<BuildingSelectorComponent>) {
    this.propertyId = data.propertyId;
    this.propertyName = data.propertyName;
    this.viewState = data.viewState;
    this.leaseTypeCode = data.leaseTypeCode;
    this.filterType = data.filterType;
    this.selectedPockets = data.SelectedPocketIds;
    this.projectScopeRule = data.ProjectScopeRule;
    this.identifier1 = data.identifier1;
    this.identifier2 = data.identifier2;
    this.identifier3 = data.identifier3;
    if (data.singleSelectionMode) {
      this.multipleSelectionMode = false;
    }
    data.SelectedPocketList.forEach((pocket: any) => {
      let selectedPocket: ISelectedPocket = {
        buildingId: pocket.buildingId,
        buildingname: pocket.buildingname,
        floorId: pocket.floorId,
        floorname: pocket.floorname,
        pocketId: pocket.pocketId,
        pocketname: pocket.premises,
        usableArea: pocket.usableArea,
        rentableArea: pocket.rentableArea,
        supplementaryArea: pocket.supplementaryArea,
        sapoaName: '',
        tenant: pocket.tenant,
        unitUsage: pocket.unitUsage,
        currentInstruction: pocket.currentInstruction,
        pendingInstruction: pocket.pendingInstruction,
        leaseTerm: pocket.leaseTerm,
        boExpiryDate: pocket.bO_ExpiryDate
      }
      this.addSelectedPocket(
        true,
        selectedPocket
      );
    });
  }

  ngOnInit() {
    this.getUnitUsages();
    this.getSapoaTypes();
    this.loadBuildings();
    this.getPropertyData();
  }

  getPropertyData() {
    this.dataService.getProperty(this.propertyId).subscribe((val) => {
      this.property = val;
    });
  }
  getSapoaTypes() {
    this.dataService.getSapoaTypes().subscribe((val) => {
      this.sapoaTypes = val.data;
    });
  }

  getUnitUsages() {
    this.dataService.getUnitUsages().subscribe((val) => {
      this.unitUsages = val.data;
    });
  }

  loadBuildings() {
    this.dataService.simpleBuildings(this.propertyId).subscribe((data) => {
      this.buildingData = data;
      this.buildingDataHolder = data;
      for (let index = 0; index < this.buildingDataHolder.length; index++) {
        const building = this.buildingDataHolder[index];
        this.dataService.GetBuildingSelections(building.id, this.filterType).subscribe((val) => {
          building.BuildingFloors = val;
          let floorcount = 0;
          for (let index = 0; index < building.BuildingFloors.floors.length; index++) {
            const floor = building.BuildingFloors.floors[index];
            let pocketcount = 0;
            for (let index = 0; index < floor.pockets.length; index++) {
              const pocket = floor.pockets[index];
              if (this.filterType != 'renewal') {
                this.selectedPockets.forEach((element) => {
                  if (pocket.id == element) {
                    pocketcount = pocketcount + 1;
                  }
                  if (pocketcount == floor.pockets.length) {
                    this.selectedFloors.push(floor.id);
                  }
                });
              } else {
                if (this.isRenewableValidPocket(pocket)) {
                  this.selectedPockets.forEach((element) => {
                    if (pocket.id == element) {
                      pocketcount = pocketcount + 1;
                    }
                    if (pocketcount == floor.pockets.length) {
                      this.selectedFloors.push(floor.id);
                    }
                  });
                } else {
                  floor.pockets[index].selectable = false;
                }
              }
            }
            if (this.selectedFloors.includes(floor.id)) {
              floorcount = floorcount + 1;
            }
          }
          if (building.BuildingFloors.floors.length == floorcount) {
            this.selectedBuildings.push(building.id);
          }
          //create linked pockets map
          this.buildingData.forEach((b) => {
            if(b?.BuildingFloors?.floors){
              b.BuildingFloors.floors.forEach((f) => {
                f.pockets.forEach((p) => {
                  if(p.linkedPocket && p.linkedPocket != emptyGuid){
                    let toLinkedPocket = f.pockets.find(x => x.id == p.linkedPocket);
                    if(toLinkedPocket){
                      this.linkedPocketsMap.push({
                        from: {
                          buildingId: b.id,
                          buildingname: b.name,
                          floorId: f.id ?? "",
                          floorname: f.name ?? "",
                          pocketId: p.id,
                          pocketname: p.name,
                          usableArea: p.usableArea ? p.usableArea + '' : '',
                          rentableArea: p.rentableArea ? p.rentableArea + '' : '',
                          supplementaryArea: p.supplementaryArea ? p.supplementaryArea + '' : '',
                          sapoaName: p.sapoaName ?? "",
                          tenant: p.tenant,
                          currentInstruction: p.currentInstruction ?? "No Instruction",
                          pendingInstruction: p.pendingInstruction ?? "No Instruction",
                          boExpiryDate: p.pendingBO_ExpiryDate
                        },
                        to: {
                          buildingId: b.id,
                          buildingname: b.name,
                          floorId: f.id ?? "",
                          floorname: f.name ?? "",
                          pocketId: toLinkedPocket.id,
                          pocketname: toLinkedPocket.name,
                          usableArea: toLinkedPocket.usableArea ? toLinkedPocket.usableArea + '' : '',
                          rentableArea: toLinkedPocket.rentableArea ? toLinkedPocket.rentableArea + '' : '',
                          supplementaryArea: toLinkedPocket.supplementaryArea ? toLinkedPocket.supplementaryArea + '' : '',
                          sapoaName: toLinkedPocket.sapoaName ?? "",
                          tenant: toLinkedPocket.tenant,
                          currentInstruction: toLinkedPocket.currentInstruction ?? "No Instruction",
                          pendingInstruction: toLinkedPocket.pendingInstruction ?? "No Instruction",
                          boExpiryDate: toLinkedPocket.pendingBO_ExpiryDate
                        }
                      })
                    }
                  }
                })
              })
            }
          })
        });
      }
    });
  }

  isRenewableValidPocket(pocket: IPocketSelection): boolean {
    let isValid = false;

    if (this.leaseTypeCode == 1) {
      isValid = this.setRenewalRestrictions(pocket);
    } else if (this.leaseTypeCode == 2) {
      isValid = this.setEarlyRenewalRestrictions(pocket);
    }

    return isValid;
  }

  setEarlyRenewalRestrictions(pocket: IPocketSelection): boolean {
    let isValid = false;

    var middleCharacter = pocket.leaseTerm == undefined ? -1 : pocket.leaseTerm.indexOf('-');
    if (pocket.leaseTerm && middleCharacter != -1 && this.identifier2 != undefined) {
      var boDate = new Date(pocket.leaseTerm?.substring(0, middleCharacter - 1));
      var expiryDate = new Date(pocket.leaseTerm?.substring(middleCharacter + 1, pocket.leaseTerm.length));
      if (new Date(this.identifier2) > boDate && new Date(this.identifier2) < expiryDate) {
        if (pocket.currentInstruction) {
          if (pocket.currentInstruction.split('_')[0].trim() == 'LCS') {
            if (pocket.pendingInstruction) {
              if (pocket.pendingInstruction.split('_')[0].trim() == 'TCN') {
                isValid = true;
              } else {
                isValid = false;
              }
            } else {
              isValid = false;
            }
          }
        } else {
          if (pocket.pendingInstruction) {
            if (pocket.pendingInstruction.split('_')[0].trim() == 'TCN') {
              isValid = true;
            } else {
              isValid = false;
            }
          } else {
            isValid = true;
          }
        }
      } else {
        isValid = false;
      }
    } else if (pocket.currentInstruction == '' && pocket.pendingInstruction == '') {
      isValid = true;
    }
    return isValid;
  }

  setRenewalRestrictions(pocket: IPocketSelection): boolean {
    let isValid = false;
    if (pocket.leaseTerm) {
      var boDate = new Date(pocket.leaseTerm.split('-')[0].trim());
      var expiryDate = new Date(pocket.leaseTerm.split('-')[1].trim());
      if (new Date(this.identifier2) > expiryDate) {
        if (pocket.currentInstruction) {
          if (pocket.currentInstruction.split('_')[0].trim() == 'LCS') {
            if (pocket.pendingInstruction) {
              if (pocket.pendingInstruction.split('_')[0].trim() == 'TCN') {
                isValid = true;
              } else {
                isValid = false;
              }
            } else {
              isValid = false;
            }
          }
        } else {
          if (pocket.pendingInstruction) {
            if (pocket.pendingInstruction.split('_')[0].trim() == 'TCN') {
              isValid = true;
            } else {
              isValid = false;
            }
          } else {
            isValid = true;
          }
        }
      } else {
        isValid = false;
      }
    } else if (
      pocket.currentInstruction &&
      this.identifier2 != undefined &&
      pocket.currentInstruction.indexOf('TCN') !== -1 &&
      pocket.bO_ExpiryDate &&
      new Date(pocket.bO_ExpiryDate) < new Date(this.identifier2)
    ) {
      isValid = true;
    } else if (pocket.currentInstruction == '' && pocket.pendingInstruction == '') {
      isValid = true;
    }

    return isValid;
  }

  removeSelectedPocket(id: string) {
    let linkedPocketIds = this.getLinkedPocketIds(id);
    linkedPocketIds.forEach((x) => {
      for (var i = 0; i < this.selectedPockets.length; i++) {
        if (this.selectedPockets[i] === x) {
          this.selectedPockets.splice(i, 1);
          this.responseObj.splice(i, 1);
          i = i - 1;
        }
      }
    });
    if (this.selectedPockets.length == 0) {
      this.SelectedTenant = '';
    }
  }

  validateSelection(tenant?: string) {
    var response = false;
    if (!tenant) {
      tenant = 'Vacant';
    }
    if (this.projectScopeRule == 3) {
      //#Rule 3 - TOP & CP
      if (this.SelectedTenant == '' && tenant != 'Vacant') {
        this.SelectedTenant = tenant;
        response = true;
      } else if (this.SelectedTenant == tenant && tenant != 'Vacant') {
        response = true;
      } else {
        response = false;
      }
    }
    //#Rule 4 - LOP
    if (this.projectScopeRule == 4) {
      response = true;
    }

    //#Rule 0 - DIR
    if (this.projectScopeRule == 0) {
      response = true;
    }

    //#Rule 1 - TI
    if (this.projectScopeRule == 1) {
      response = false;
    }

    //#Rule 1 - TO
    if (this.projectScopeRule == 2) {
      response = false;
    }

    return response;
  }

  //recursive function
  getLinkedPockets(rootPocket: ISelectedPocket, linkedPocketIds: string[] = [], result: ISelectedPocket[] = []){
    result.push(rootPocket);
    linkedPocketIds.push(rootPocket.pocketId);
    if(this.linkedPocketsMap){
      this.linkedPocketsMap.forEach((x) => {
        if(x.from.pocketId == rootPocket.pocketId){
          if(!linkedPocketIds.includes(x.to.pocketId)){
            result = [...this.getLinkedPockets(x.to, linkedPocketIds, result)];
          }
        }
        if(x.to.pocketId == rootPocket.pocketId){
          if(!linkedPocketIds.includes(x.from.pocketId)){
            result = [...this.getLinkedPockets(x.from, linkedPocketIds, result)];
          }
        }
      })
    }
    return result;
  }

  getLinkedPocketIds(rootPocketId: string, result: string[] = []){
    result.push(rootPocketId);
    if(this.linkedPocketsMap){
      this.linkedPocketsMap.forEach((x) => {
        if(x.from.pocketId == rootPocketId){
          if(!result.includes(x.to.pocketId)){
            result = [...this.getLinkedPocketIds(x.to.pocketId, result)];
          }
        }
        if(x.to.pocketId == rootPocketId){
          if(!result.includes(x.from.pocketId)){
            result = [...this.getLinkedPocketIds(x.from.pocketId, result)];
          }
        }
      })
    }
    return result;
  }

  addSelectedPocket(fromInit: boolean, pocket: ISelectedPocket) {
    let linkedPockets = this.getLinkedPockets(pocket)
    linkedPockets.forEach((x) => {
      this.selectedPockets.push(x.pocketId);
      this.responseObj.push({
        ...x,
        tenant: x.tenant ?? "",
        currentInstruction: x.currentInstruction ?? 'No Instruction',
        pendingInstruction: x.pendingInstruction ?? 'No Instruction',
      });
    });
    if (this.multipleSelectionMode) {
      this.selectedPockets.push(pocket.pocketId);
      if (fromInit === true) {
        this.responseObj.push({
          ...pocket,
          tenant: pocket.tenant ?? "",
          currentInstruction: pocket.currentInstruction ?? 'No Instruction',
          pendingInstruction: pocket.pendingInstruction ?? 'No Instruction',
        });
      } else if (this.validateSelection(pocket.tenant)) {
        this.responseObj.push({
          ...pocket,
          tenant: pocket.tenant ?? "",
          currentInstruction: pocket.currentInstruction ?? 'No Instruction',
          pendingInstruction: pocket.pendingInstruction ?? 'No Instruction',
        });
      } else {
        this.removeSelectedPocket(pocket.pocketId);
        this.alertPocketSelection();
      }
    } else {
      this.selectedPockets = [pocket.pocketId];
      if (this.validateSelection(pocket.tenant)) {
        this.responseObj = [{
          ...pocket,
          tenant: pocket.tenant ?? "",
          currentInstruction: pocket.currentInstruction ?? 'No Instruction',
          pendingInstruction: pocket.pendingInstruction ?? 'No Instruction',
        }];
      } else {
        this.removeSelectedPocket(pocket.pocketId);
        this.alertPocketSelection();
      }
    }
    this.responseObj.sort((a, b) => a.pocketname.localeCompare(b.pocketname));
    this.responseObj.sort((a, b) => a.floorname.localeCompare(b.floorname));
  }

  alertPocketSelection() {
    Swal.fire({
      title: 'Pocket Rule Violated',
      text: 'You are limited to one lease per scope selection.'
    });
  }

  SelectFloor(building: IBuilding, floor: IFloor) {
    if (this.projectScopeRule == 0 || this.projectScopeRule == 4) {
      Swal.fire({
        title: 'Pocket Rule Violated',
        text: 'Please select indivual pockets as per scope selection Rule.'
      });
      return;
    }
    this.selectedFloors.push(floor.id);
    floor.pockets.forEach((pocket) => {
      var usableArea = pocket.usableArea ? pocket.usableArea + '' : '';
      var rentableArea = floor.rentableArea ? floor.rentableArea + '' : '';
      let selectedPocket: ISelectedPocket = {
        buildingId: building.id,
        buildingname: building.name,
        floorId: floor.id,
        floorname: floor.name,
        pocketId: pocket.id,
        pocketname: pocket.name,
        usableArea: usableArea,
        rentableArea: rentableArea,
        supplementaryArea: '',
        sapoaName: pocket.sapoaName ?? '',
        tenant: pocket.tenant,
        unitUsage: pocket.unitUsage?.name,
        currentInstruction: pocket?.currentInstruction,
        pendingInstruction: pocket?.pendingInstruction
      }
      this.addSelectedPocket(
        false,
        selectedPocket
      );
    });
  }

  SelectBuilding(building: IBuilding) {
    if (this.projectScopeRule == 0 || this.projectScopeRule == 4) {
      Swal.fire({
        title: 'Pocket Rule Violated',
        text: 'Please select indivual pockets as per scope selection Rule.'
      });
      return;
    }
    this.selectedBuildings.push(building.id);
    building.BuildingFloors.floors.forEach((floor) => {
      this.SelectFloor(building, floor);
    });
  }

  deSelectFloor(floor: IFloor) {
    for (var i = 0; i < this.selectedFloors.length; i++) {
      if (this.selectedFloors[i] === floor.id) {
        this.selectedFloors.splice(i, 1);
        i = i - 1;
      }
    }
    for (let index = 0; index < floor.pockets.length; index++) {
      const element = floor.pockets[index];
      this.removeSelectedPocket(element.id);
    }
  }

  deSelectBuilding(building: IBuilding) {
    for (var i = 0; i < building.BuildingFloors.floors.length; i++) {
      if (this.selectedBuildings[i] === building.id) {
        this.selectedBuildings.splice(i, 1);
        i = i - 1;
      }
    }
    for (let index = 0; index < building.BuildingFloors.floors.length; index++) {
      const element = building.BuildingFloors.floors[index];
      this.deSelectFloor(element);
    }
  }

  getArea(MeasermentArray: any) {
    for (let index = 0; index < MeasermentArray.length; index++) {
      const element = MeasermentArray[index];
      if (element.sapoaType?.code != 7) {
        return element.area;
      }
    }
  }

  getSapoaType(MeasermentArray: any) {
    for (let index = 0; index < MeasermentArray.length; index++) {
      const element = MeasermentArray[index];
      if (element.sapoaType?.code != 7) {
        return element.sapoaType ? element.sapoaType.name : '';
      }
    }
  }
  getRentableArea(MeasermentArray: any) {
    for (let index = 0; index < MeasermentArray.length; index++) {
      const element = MeasermentArray[index];
      if (element.sapoaType?.code == 7) {
        return element.area;
      }
    }
  }

  SaveSelection() {
    if (this.filterType.includes('dir_1')) {
      if (this.selectedLevel == 'Pockets') {
        this.dialogRef.close(this.responseObj);
      } else if (this.selectedLevel == 'Buildings') {
        this.dialogRef.close({ level: this.selectedLevel, selected: this.selectedBuildings });
      } else if (this.selectedLevel == 'Floors') {
        this.dialogRef.close({ level: this.selectedLevel, selected: this.selectedFloors });
      } else if (this.selectedLevel == 'Property') {
        this.dialogRef.close({ level: this.selectedLevel, selected: this.propertyId });
      }
    } else {
      this.dialogRef.close(this.responseObj);
    }
  }

  selectPremises(pocket: any, building: any, floor: any) {
    if (this.validatePremisesSelection(pocket)) {
      if (this.selectedPockets.includes(pocket.id)) {
        this.removeSelectedPocket(pocket.id);
      } else {
        let selectedPocket: ISelectedPocket = {
          buildingId: building.id,
          buildingname: building.name,
          floorId: floor.id,
          floorname: floor.name,
          pocketId: pocket.id,
          pocketname: pocket.name,
          usableArea: pocket.usableArea,
          rentableArea: pocket.rentableArea,
          supplementaryArea: pocket.supplementaryArea,
          sapoaName: pocket.sapoaName,
          tenant: pocket.tenant,
          unitUsage: pocket.unitUsage,
          currentInstruction: pocket.currentInstruction ?? 'No Instruction',
          pendingInstruction: pocket.pendingInstruction ?? 'No Instruction',
          leaseTerm: pocket.leaseTerm,
          boExpiryDate: pocket.bO_ExpiryDate
        } 
        this.addSelectedPocket(
          false,
          selectedPocket
        );
      }
      if (this.filterType.includes('dir_1')) {
        if (this.selectedPockets.length > 0) {
          this.selectedLevel = 'Pockets';
        } else {
          this.selectedLevel = '';
        }
        this.identifier2 = this.selectedLevel;
      }
    }
  }

  validatePremisesSelection(pocket: any) {
    if (this.filterType == 'tcn' && this.identifier3 != '1') {
      var LeaseExpiryDate = new Date(pocket.leaseTerm.split('-')[1]);
      var TcnChangedDate = new Date(this.identifier2);
      if (LeaseExpiryDate >= TcnChangedDate) {
        // this.buildingDataHolder.forEach((building) => {
        //   if (building.BuildingFloors.length > 0) {
        //     building.BuildingFloors.forEach((floor: any) => {
        //       if (floor.pockets.length > 0) {
        //         floor.pockets.forEach((pocketFilter: any) => {
        //           if (pocketFilter.currentInstruction == pocket.currentInstruction && pocketFilter != pocket) {
        //             this.selectPremises(pocketFilter, building, floor);
        //           }
        //         });
        //       }
        //     });
        //   }
        // });
        return true;
      } else {
        Swal.fire({
          title: 'Pocket Rule Violated',
          text: `Please select a Lease with its Expiry date greater or on ${this.identifier2}.`
        });
        return false;
      }
    } else if (this.filterType == 'tcn' && this.identifier3 == '1') {
      var LeaseExpiryDate = new Date(pocket.leaseTerm.split('-')[1]);
      var TcnChangedDate = new Date(this.identifier2);
      if (LeaseExpiryDate == TcnChangedDate) {
        // this.buildingDataHolder.forEach((building) => {
        //   if (building.BuildingFloors.length > 0) {
        //     building.BuildingFloors.forEach((floor: any) => {
        //       if (floor.pockets.length > 0) {
        //         floor.pockets.forEach((pocketFilter: any) => {
        //           if (pocketFilter.currentInstruction == pocket.currentInstruction && pocketFilter != pocket) {
        //             this.selectPremises(pocketFilter, building, floor);
        //           }
        //         });
        //       }
        //     });
        //   }
        // });
        return true;
      } else {
        Swal.fire({
          title: 'Pocket Rule Violated',
          text: `Please select a Lease with its Expiry date equal to ${this.identifier2}.`
        });
        return false;
      }
    } else {
      return true;
    }
  }

  selectProperty() {
    if (this.filterType.includes('dir_1')) {
      if (this.selectedLevel == 'Property') {
        this.selectedLevel = '';
      } else {
        this.selectedLevel = 'Property';
      }
      this.identifier2 = this.selectedLevel;
    }
  }

  selectBuilding(building: any) {
    if (this.filterType.includes('dir_1')) {
      if (this.selectedBuildings.includes(building.id)) {
        for (var i = 0; i < building.BuildingFloors.floors.length; i++) {
          if (this.selectedBuildings[i] === building.id) {
            this.selectedBuildings.splice(i, 1);
            i = i - 1;
          }
        }
      } else {
        this.selectedBuildings.push(building.id);
      }

      if (this.selectedBuildings.length > 0) {
        this.selectedLevel = 'Buildings';
      } else {
        this.selectedLevel = '';
      }
      this.identifier2 = this.selectedLevel;
    } else {
      if (this.selectedBuildings.includes(building.id)) this.deSelectBuilding(building);
      else this.SelectBuilding(building);
    }
  }

  selectFloor(building: any, floor: any) {
    if (this.filterType.includes('dir_1')) {
      if (this.selectedFloors.includes(floor.id)) {
        for (var i = 0; i < this.selectedFloors.length; i++) {
          if (this.selectedFloors[i] === floor.id) {
            this.selectedFloors.splice(i, 1);
            i = i - 1;
          }
        }
      } else {
        this.selectedFloors.push(floor.id);
      }

      if (this.selectedFloors.length > 0) {
        this.selectedLevel = 'Floors';
      } else {
        this.selectedLevel = '';
      }
      this.identifier2 = this.selectedLevel;
    } else {
      if (this.selectedFloors.includes(floor.id)) this.deSelectFloor(floor);
      else this.SelectFloor(building, floor);
    }
  }

  canSelectProperty() {
    if (this.filterType === 'dir_1' && (this.selectedLevel === 'Property' || this.selectedLevel === '')) {
      return true;
    } else {
      return false;
    }
  }

  canSelectBuildings() {
    if (this.multipleSelectionMode && this.projectScopeRule !== 3 && this.filterType !== 'lcs' && this.filterType === 'dir_1' && (this.selectedLevel === 'Buildings' || this.selectedLevel === '')) {
      return true;
    } else {
      return false;
    }
  }

  canSelectFloors() {
    if (this.multipleSelectionMode && this.projectScopeRule !== 3 && this.filterType !== 'lcs' && this.filterType === 'dir_1' && (this.selectedLevel === 'Floors' || this.selectedLevel === '')) {
      return true;
    } else {
      return false;
    }
  }
}
