import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { DataService } from '@app/core/services/data-service';

export interface IClientNode {
  id: string;
  expandable: boolean;
  name: string;
  isProperty: boolean;
  level: number;
}

export interface ClientNode {
  id: string;
  name: string;
  isProperty: boolean;
  children?: ClientNode[];
}

@Component({
  selector: 'app-client-profile-link',
  templateUrl: './client-profile-link.component.html',
  styleUrls: ['./client-profile-link.component.scss']
})
export class ClientProfileLinkdialogComponent implements OnInit {
  @ViewChild('tree') tree: any;
  @Output() changed = new EventEmitter<string>();
  UserId: string;
  UserName: string;
  SelectedTree: [string] = [''];
  SelectedProperties: [string] = [''];
  RemoveTreeHolder: [string] = [''];
  selectedNodeHolder!: IClientNode;
  activefilterParentId = '';

  TREE_DATA: ClientNode[] = [
    {
      id: 'NOTSELECTEABLE',
      name: '',
      isProperty: false,
      children: []
    }
  ];

  treecollapsed = false;

  private _transformer = (node: ClientNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      id: node.id,
      isProperty: node.isProperty,
      level: level
    };
  };

  treeControl = new FlatTreeControl<IClientNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dataService: DataService, private _snackBar: MatSnackBar) {
    this.UserId = data.userId;
    this.UserName = data.userName;
  }

  hasChild = (_: number, node: IClientNode) => node.expandable;

  ngOnInit() {
    this.getClientTreeView();
  }

  getClientProfileHierarchys() {
    this.dataService.getClientProfileHierarchys(this.UserId).subscribe((val) => {
      this.SelectedTree = val;
      this.refreshPropertyList();
    });
  }

  getClientTreeView() {
    this.TREE_DATA.splice(0, 1);
    this.dataService.ClientProfileTreeView().subscribe((val) => {
      this.TREE_DATA = val;
      this.dataSource.data = this.TREE_DATA;

      this.tree.treeControl.expandAll();
      this.getClientProfileHierarchys();
    });
  }

  expandTree() {
    this.tree.treeControl.expandAll();
    this.treecollapsed = false;
  }

  closeTree() {
    this.tree.treeControl.collapseAll();
    this.treecollapsed = true;
  }

  SelectTreeChanged(node: any) {
    if (this.SelectedTree.includes(node.id)) {
      this.RemoveTreeHolder = [''];
      this.activefilterParentId = '';
      this.RemoveChildIds(0, node.level, node.id, this.TREE_DATA);
    } else {
      this.activefilterParentId = '';
      this.linkChildIds(0, node.level, node.id, this.TREE_DATA);
    }
  }

  linkChildIds(startlevel: number, Selectedlevel: number, id: string, array: ClientNode[]) {
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      if (Selectedlevel == startlevel && id == element.id) {
        //this will be the first entry in array
        //add id and if child exists go into next level
        this.activefilterParentId = element.id;
        this.SelectedTree.push(element.id);
        this.refreshPropertyList();

        if (element.children) {
          if (element.children?.length > 0) {
            this.linkChildIds(startlevel + 1, Selectedlevel, id, element.children);
            break;
          }
        }
      } else if (Selectedlevel < startlevel && this.SelectedTree.includes(this.activefilterParentId) && this.activefilterParentId != '') {
        //add id and if child exists go into next level
        this.activefilterParentId = element.id;
        this.SelectedTree.push(element.id);
        this.refreshPropertyList();

        if (element.children) {
          if (element.children?.length > 0) {
            this.linkChildIds(startlevel + 1, Selectedlevel, id, element.children);
          }
        }
      } else if (!this.SelectedTree.includes(this.activefilterParentId) || this.activefilterParentId == '') {
        // Check next level
        if (element.children) {
          if (element.children?.length > 0) {
            this.linkChildIds(startlevel + 1, Selectedlevel, id, element.children);
          }
        }
      }
    }
  }

  RemoveChildIds(startlevel: number, Selectedlevel: number, id: string, array: ClientNode[]) {
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      if (Selectedlevel == startlevel && id == element.id) {
        //this will be the first entry in array
        //remove id and if child exists go into next level
        this.activefilterParentId = element.id;
        this.RemoveTreeHolder.push(element.id);
        this.removeSelectedNode(element.id);
        this.refreshPropertyList();

        if (element.children) {
          if (element.children?.length > 0) {
            this.RemoveChildIds(startlevel + 1, Selectedlevel, id, element.children);
          }
        }
      } else if (Selectedlevel < startlevel && this.RemoveTreeHolder.includes(this.activefilterParentId) && this.activefilterParentId != '') {
        //remove id and if child exists go into next level
        this.activefilterParentId = element.id;
        this.RemoveTreeHolder.push(element.id);

        this.removeSelectedNode(element.id);
        this.refreshPropertyList();

        if (element.children) {
          if (element.children?.length > 0) {
            this.RemoveChildIds(startlevel + 1, Selectedlevel, id, element.children);
          }
        }
      } else if (!this.RemoveTreeHolder.includes(this.activefilterParentId) || this.activefilterParentId == '') {
        // Check next level
        if (element.children) {
          if (element.children?.length > 0) {
            this.RemoveChildIds(startlevel + 1, Selectedlevel, id, element.children);
          }
        }
      }
    }
  }

  refreshPropertyList() {
    this.SelectedProperties = [''];
    this.SelectedProperties.splice(0, 1);
    this.addPropertyList(0, this.TREE_DATA);
  }

  addPropertyList(startlevel: number, array: ClientNode[]) {
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      if (this.SelectedTree.includes(element.id)) {
        if (element.isProperty) {
          this.SelectedProperties.push(element.name);
        }
        if (element.children) {
          if (element.children?.length > 0) {
            this.addPropertyList(startlevel + 1, element.children);
          }
        }
      } else {
        if (element.children) {
          if (element.children?.length > 0) {
            this.addPropertyList(startlevel + 1, element.children);
          }
        }
      }
    }
  }

  removeSelectedNode(id: string) {
    for (var i = 0; i < this.SelectedTree.length; i++) {
      if (this.SelectedTree[i] === id) {
        this.SelectedTree.splice(i, 1);
        i = i - 1;
      }
    }
    this.refreshPropertyList();
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, { duration: 2 * 1000 });
  }

  saveClientProfile() {
    if (this.SelectedTree[0] == '') {
      this.SelectedTree.splice(0, 1);
    }
    this.dataService.editClientProfile(this.UserId, this.SelectedTree).subscribe((val) => {
      if (val != null) {
        this.openSnackBar('Succesfully Saved.', 'close');
        this.getClientTreeView();
      } else {
        this.openSnackBar('Failed to Save.', 'close');
      }
    });
  }
}
