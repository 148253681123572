import { JwtHelperService } from '@auth0/angular-jwt';
import { ErrorSnackBarComponent } from './components/error-snack-bar/error-snack-bar';
import { AuthGuard } from './services/auth-guard-service';
import { AuthService } from './services/auth-service';
import { DataService } from './services/data-service';
import { ErrorHandlerService } from './services/errorhandler-service';
import { MonitoringService } from './services/monitoring-service';
import { PagerService } from './services/pager-service';
import { ReportService } from './services/report.service';

export const featureServices = [AuthService, AuthGuard, DataService, PagerService, JwtHelperService, ErrorHandlerService, ErrorSnackBarComponent, MonitoringService, ReportService];

export const featureDialogs: any[] = [];

export const featureComponents: any[] = [ErrorSnackBarComponent];

export const featureDirectives: any[] = [];

export const featurePipes: any[] = [];
