// specifically made for use on input fields

import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumberFormatter]'
})
export class NumberFormatterDirective {
  constructor(private el: ElementRef) {}

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string) {
    this.el.nativeElement.value = this.formatNumber(value);
  }

  private formatNumber(value: string): string {
    const numericValue = parseFloat(value);
    return isNaN(numericValue) ? '0' : parseFloat(numericValue.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2 });
  }
}
