import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'customDecimal' })
export class CustomDecimalPipe implements PipeTransform {
  transform(value: number): number {
    if (value) {
      if (value % 1 >= 0.5) {
        return Math.ceil(value);
      } else {
        return Math.floor(value);
      }
    } else return 0;
  }
}
