

<mat-icon
  *ngIf="selector.level?.includes(currentLevel)&& selected === false"
  (click)="toggleClicked()"
  class="pointer"
  [ngStyle]="{color: selector.colorDeSelected}">{{selector.iconDeSelected}}
</mat-icon>

<mat-icon
  *ngIf="selector.level?.includes(currentLevel) && selected === true"
  (click)="toggleClicked()"
  class="pointer"
  [ngStyle]="{color: selector.colorSelected}">{{selector.iconSelected}}
</mat-icon>
