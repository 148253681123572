

<mat-dialog-content>

  <div fxLayout="row" class="cardHeader">
    <div fxLayout="column" class="cardHeadercol1">
      <mat-card-title>Upload File </mat-card-title>
      &nbsp;
      <mat-card-subtitle>Upload a file for {{page}}: {{name}}</mat-card-subtitle>
    </div>
  </div>
  <mat-card-content>
    <hr class="horizontalline">
    <form [formGroup]="fileForm" class="formStyle">
      <div fxLayout="row" class="formBody">
        <div fxLayout="column" class="halfWidth">
          <mat-form-field class="FieldBoxes" appearance="fill">
            <mat-icon matPrefix>grid_view</mat-icon>
            <mat-label>Document Type</mat-label>
            <mat-select class="title-select"
              placeholder="Select a Document Type"
              formControlName="filetype"
              required
              (ngModelChange)="fileTypeSelectionchanged($event)">

              <mat-option *ngFor="let fileType of fileTypes"
                [value]="fileType.id">
                {{fileType.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="column" class="ResponseBoxes">
          <div *ngIf="UploadStatus!==''" fxLayout="row" fxLayoutAlign="space-between center">
            <button mat-fab
              [class]="UploadStatus === 'successfully'?'successfullNotify disablePointer':
              UploadStatus === 'failed'?'failedNotify disablePointer':
              UploadStatus === 'warning'?'warningNotify disablePointer':
              UploadStatus === 'uploading'?'uploadingNotify disablePointer':''"
              aria-label="Example icon button with a bookmark icon">
              <mat-icon>{{
                UploadStatus === 'successfully'?'done':
                UploadStatus === 'failed'?'close':
                UploadStatus === 'warning'?'warning':
                UploadStatus === 'uploading'?'hourglass_bottom':''
                }}</mat-icon>
            </button>
            <mat-card-subtitle style="padding-top: 20px;">{{UploadMessage}}</mat-card-subtitle>
          </div>
        </div>
      </div>
      <input type="file" class="file-input"
        (change)="onFileSelected($event)" #fileUpload>

      <div *ngIf="!file" class="file-upload">
        <button mat-menu-item (click)="fileUpload.click()"> <mat-icon>attach_file</mat-icon>Choose a file</button>
      </div>

      <div *ngIf="file" class="file-upload" fxLayout="row">
        <button class="noAction" mat-menu-item (click)="fileUpload.click()"> <mat-icon>attach_file</mat-icon>{{file.name}}</button>
        <button mat-icon-button color="primary" (click)="removeFiles()"><mat-icon>close</mat-icon></button>
      </div>
    </form>

    <div fxLayout="row wrap" class="filters">
      <div *ngFor="let filter of this.filters; let i= index">
        <mat-form-field *ngIf="filter.type === 'dropdown' && filter.isSystemTag!== true" class="filterLayoutDropdown" appearance="fill">
          <mat-label>{{filter.name}}</mat-label>
          <mat-select
            (selectionChange)="addFilter(filter.tagId, $event,'Dropdown')"
            [placeholder]="'Select '+ filter.name">
            <mat-option *ngFor="let data of filter.data"
              [value]="data.key">
              {{data.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="filter.type === 'textbox'" class="filterLayoutText" appearance="fill">
          <mat-label>{{filter.name}}</mat-label>
          <input [(ngModel)]="filter.changedValue" (change)="addFilter(filter.tagId, filter.changedValue,'Text')" matInput>
        </mat-form-field>

        <mat-form-field *ngIf="filter.type === 'date'" class="filterLayoutDate" appearance="fill" class="example-form-field">
          <mat-label>{{filter.name}}</mat-label>
          <input (dateChange)="addFilter(filter.tagId,$event,'Date')" matInput [matDatepicker]="datepicker">
          <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
          <mat-datepicker #datepicker>
            <mat-datepicker-actions>
              <button mat-button matDatepickerCancel>Cancel</button>
              <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
            </mat-datepicker-actions>
          </mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <hr class="horizontalline">

  </mat-card-content>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button class="CancelButton"
    mat-dialog-close>Cancel
  </button>
  <div class="DisableActionButtonWrapper">
    <button [class]="!file || !fileForm.valid || UploadStatus === 'uploading'?'DisableUploadButton':'EnableUploadButton'" mat-stroked-button
      color="primary" (click)="onFileUpload()">Upload</button>
  </div>

</mat-dialog-actions>
