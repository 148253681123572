import { Component, Inject } from '@angular/core';
import { DataService } from '@app/core/services/data-service';
import { HttpEventType } from '@angular/common/http';
import { catchError, mergeMap } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { IFileTag, IGenericTagFilters } from '@app/core/contracts/FileTag';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDocumentTypes } from '@app/core/contracts/documentTypes';
import { Router } from '@angular/router';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './fileUploader.component.html',
  styleUrls: ['./fileUploader.component.scss']
})
export class fileUploaderComponent {
  search!: number;
  fileForm!: UntypedFormGroup;
  filetype!: UntypedFormControl;
  fileName = '';
  fileTypes!: [IDocumentTypes];
  file: File | any = null;
  UploadMessage = '';
  UploadStatus = '';
  page!: string;
  name!: string;
  revisionNumber = 1;
  filters!: [IGenericTagFilters];
  Tags: [IFileTag] = [
    {
      isUrl: false,
      fileId: '',
      Url: '',
      TagId: '',
      value: '',
      friendlyName: ''
    }
  ];

  url = '';

  constructor(private dataService: DataService, @Inject(MAT_DIALOG_DATA) public data: any, private router: Router) {}

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit() {
    this.search = this.data.page;
    this.page = this.data.page;
    this.name = this.data.name;
    this.url = this.data.url;
    this.page = this.data.page;
    this.loadDocumentTypes();
    this.createFormControls();
    this.createForm();
    this.GetFileUploadFilters();
  }

  createFormControls() {
    this.filetype = new UntypedFormControl();
  }

  createForm() {
    this.fileForm = new UntypedFormGroup({
      filetype: this.filetype
    });
  }

  GetFileUploadFilters() {
    this.dataService.GetFileUploadFilters().subscribe((val) => {
      this.filters = val;
    });
  }

  addFilter(tagTypeId: string, event: any, type: string) {
    var friendlyName;
    var activeFilter = this.Tags.find((x) => x.TagId == tagTypeId);
    if (activeFilter) {
      activeFilter.value = type == 'Text' ? event : event.value;
    } else {
      if (type == 'Dropdown') {
        var dropdownlist = this.filters.find((x) => x.tagId == tagTypeId);
        if (dropdownlist != undefined) {
          friendlyName = dropdownlist.data.find((x) => x.key == event.value)?.value;
        }
      } else if (type == 'Text') {
        friendlyName = event;
      } else {
        friendlyName = this.formatDate(new Date(event.value));
      }
      this.Tags.push({ isUrl: false, fileId: '', TagId: tagTypeId, Url: '', value: type == 'Text' ? event : event.value, friendlyName: friendlyName ? friendlyName : '' });
    }
  }

  formatDate(date: Date): String {
    if (date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${year}/${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}`;
    } else {
      return '';
    }
  }

  loadDocumentTypes() {
    this.dataService.searchDocumentTypes(this.search).subscribe((data) => {
      this.fileTypes = data;
    });
  }

  onFileSelected(event: any) {
    this.file = event.target.files[0];
  }

  removeFiles() {
    this.file = null;
  }
  fileTypeSelectionchanged(type: any) {
    if (type != '') {
      this.dataService.FileExistsForParent(type, this.data.value).subscribe((Exists) => {
        if (Exists) {
          this.UploadStatus = 'warning';
          this.UploadMessage = 'File type already uploaded';
          this.dataService.FileTypeParentCount(this.data.value, type).subscribe((count) => {
            this.revisionNumber = count + 1;
          });
        } else {
          this.UploadStatus = '';
          this.UploadMessage = '';
          this.revisionNumber = 1;
        }
      });
    }
  }

  onFileUpload() {
    this.UploadStatus = 'uploading';
    this.UploadMessage = 'Uploading..';
    let filetypeobj = this.fileTypes.find((x) => x.id == this.filetype.value);
    let Level = this.filters.find((x) => x.name == 'Level');
    if (this.file) {
      const formData = new FormData();
      formData.append('file', this.file);
      this.dataService
        .uploadFile(formData, this.revisionNumber, this.filetype.value)
        .pipe(
          mergeMap((model) => {
            if (model.type === HttpEventType.Response) {
              if (this.Tags[0].TagId == '') {
                this.Tags.splice(0, 1);
              }
              this.Tags.forEach((tag) => {
                tag.fileId = model.body?.id ? model.body?.id : '';
              });

              this.Tags.push({
                isUrl: true,
                fileId: model.body?.id ? model.body?.id : '', // FileId
                Url: this.url, // Property, building or Floor
                TagId: '', // Hard coded Guid to change when URL based tags is developed
                value: this.data.value, // Id
                friendlyName: this.data.friendlyName // Name
              });

              this.Tags.push({
                isUrl: false,
                fileId: model.body?.id ? model.body?.id : '', // FileId
                Url: '', // Property, building or Floor
                TagId: Level ? Level.tagId : '', // Hard coded Guid to change when URL based tags is developed
                value: this.page, // Id
                friendlyName: this.page // Name
              });

              return this.CreateFileTags(this.Tags);
            }
            return of();
          }),
          catchError((error: any) => {
            let errorMessage = '';
            if (error.error instanceof ErrorEvent) {
              // client-side error
              errorMessage = `Error: ${error.error.message}`;
            } else {
              // server-side error
              errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
            }
            this.UploadStatus = 'failed';
            this.UploadMessage = 'Failed to Upload File';
            return 'e';
          })
        )
        .subscribe((x) => {
          if (x != 'e') {
            this.UploadStatus = 'successfully';
            this.UploadMessage = 'Successfully Uploaded';

            this.removeFiles();
            this.Tags = [
              {
                isUrl: false,
                fileId: '',
                Url: '',
                TagId: '',
                value: '',
                friendlyName: ''
              }
            ];
            this.filetype.setValue('');

            // this.fileForm.reset();

            this.filetype.setErrors(null);
          }
        });
    }
  }

  CreateFileTags(Tags: IFileTag[]) {
    return this.dataService.addCustomTagsToFile(Tags);
  }
}
