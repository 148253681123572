import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  templateUrl: './not-found.component.html'
})
export class PageNotFoundComponent {
  constructor(private router: Router, private location: Location) {}

  home() {
    this.router.navigate(['/secure/dashboard']);
  }
  back() {
    this.location.back();
  }
}
