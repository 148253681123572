import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { IGoogleMapsAddress } from '@app/core/contracts/common';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-google-maps-autocomplete',
  templateUrl: './google-maps-autocomplete.component.html',
  styleUrls: ['./google-maps-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleMapsAutcompleteComponent implements OnInit, AfterViewInit {
  @Input() label = 'Address Search';
  @Output() setAddress: EventEmitter<IGoogleMapsAddress> = new EventEmitter();
  @ViewChild('postalCode', { read: ElementRef }) postalCode: any;

  googleAutocomplete!: google.maps.places.AutocompleteService;
  placesService!: google.maps.places.PlacesService;
  geocoder!: google.maps.Geocoder;
  componentDestroyed$: Subject<boolean> = new Subject();
  autocomplete: { input: string } = { input: '' };
  autocompleteItems: any = [];
  searchForm!: UntypedFormGroup;
  searchInput!: UntypedFormControl;

  constructor(public zone: NgZone, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.googleAutocomplete = new google.maps.places.AutocompleteService();
    this.geocoder = new google.maps.Geocoder();

    this.createFormControls();
    this.createForm();

    this.searchInput.valueChanges.pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.componentDestroyed$)).subscribe((value: string) => {
      this.autocompleteItems = [];

      this.updateSearchResults().then((res: unknown) => {
        this.autocompleteItems = res;
        this.cdr.markForCheck();
      });
    });
  }

  createFormControls() {
    this.searchInput = new UntypedFormControl('');
  }

  createForm() {
    this.searchForm = new UntypedFormGroup({
      searchInput: this.searchInput
    });
  }

  ngAfterViewInit() {
    this.placesService = new google.maps.places.PlacesService(this.postalCode.nativeElement);
  }

  updateSearchResults() {
    return new Promise((resolve, reject) => {
      const result: any = [];
      if (this.autocomplete.input === '') {
        return resolve(result);
      }

      this.googleAutocomplete.getPlacePredictions(
        {
          input: this.autocomplete.input,
          componentRestrictions: { country: 'ZA' },
          types: [] // 'establishment' / 'address' / 'geocode'
        },
        (predictions: any, status: any) => {
          this.zone.run(() => {
            if (!predictions) {
              return resolve(result);
            } else {
              predictions.forEach((prediction: any) => {
                if (prediction.types.indexOf('establishment') === -1 && prediction.types.indexOf('point_of_interest') === -1) {
                  result.push(prediction);
                }
              });

              return resolve(result);
            }
          });
        }
      );
    });
  }

  async getAddressByPlaceId(placeId: string) {
    return new Promise((resolve, reject) => {
      const address: IGoogleMapsAddress = {
        addressLine1: '',
        addressLine2: '',
        suburb: '',
        city: '',
        province: '',
        postalCode: '',
        country: ''
      };

      if (!placeId) {
        reject('placeId not provided');
      }

      try {
        this.placesService.getDetails(
          {
            placeId,
            fields: ['address_components', 'geometry']
          },
          (details: any) => {
            details?.address_components?.forEach((entry: any) => {
              if (entry.types?.[0] === 'street_number') {
                address.addressLine1 = entry.long_name;
              } else if (entry.types?.[0] === 'route') {
                address.addressLine1 = address.addressLine1 + ' ' + entry.long_name;
              } else if (entry.types?.[0]?.startsWith('sublocality')) {
                address.suburb = entry.long_name;
              } else if (entry.types?.[0] === 'locality') {
                address.city = entry.long_name;
              } else if (entry.types?.[0] === 'country') {
                address.country = entry.long_name;
              } else if (entry.types?.[0] === 'postal_code') {
                address.postalCode = entry.long_name;
              }
            });

            // postal code not set, get postal code using reverse geocoding
            if (!address.postalCode) {
              const req: google.maps.GeocoderRequest = {
                location: {
                  lat: details.geometry?.location.lat() ?? 0,
                  lng: details.geometry?.location.lng() ?? 0
                }
              };

              this.geocoder.geocode(req, (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
                if (status === 'OK') {
                  if (results[0]) {
                    address.postalCode = results[0].address_components.find((a: any) => a.types[0] === 'postal_code')?.long_name;
                  }
                  return resolve(address);
                } else {
                  return resolve(address);
                }
              });
            } else {
              return resolve(address);
            }
          }
        );
      } catch (e) {
        return reject(e);
      }
    });
  }

  displayFn(item: any) {
    return item ? item.description : '';
  }

  async selectSearchResult(item: any) {
    const placeid = item?.option?.value?.place_id;
    if (!placeid) {
      return;
    }

    await this.getAddressByPlaceId(placeid).then((res: any) => {
      this.autocompleteItems = [];
      this.autocomplete = { input: '' };
      this.setAddress.emit(res);
    });
  }
}
