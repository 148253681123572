<div class="wrapper">
  <mat-dialog-content style="height: 100vh;overflow-y: auto;">
    <div mat-dialog-title>
      <mat-toolbar color="primary">
        <mat-icon class="custoIcon">upload_file</mat-icon>
        <span>{{entityDetail}} Document Uploader</span>
        <span class="spacer"></span>
        <button mat-icon-button class="icon" mat-dialog-close hint="cancel">
          <mat-icon>close</mat-icon>
        </button>
      </mat-toolbar>
      <hr class="horizontalline">
    </div>
    <mat-selection-list *ngIf="step === 1" [(ngModel)]="selectedvalues">
      <div mat-subheader>Select Your {{entity}} you want to uplaod the file against</div>
      <mat-list-option *ngFor="let floor of PropertyFileStructure, let i = index" [value]="floor" [selected]="AutoSelectedValue && i === AutoSelectedIndex">
        <div class="headerTextEnabled" mat-line>{{floor.name}}</div>
      </mat-list-option>
    </mat-selection-list>
    <form [formGroup]="fileForm" *ngIf="step === 2" class="formStyle">
      <mat-form-field class="FieldBoxes" appearance="fill">
        <mat-icon matPrefix>grid_view</mat-icon>
        <mat-label>Document Type</mat-label>
        <mat-select class="title-select"
          placeholder="Select a Document Type"
          formControlName="filetype"
          required
          (ngModelChange)="fileTypeSelectionchanged($event)">

          <mat-option *ngFor="let fileType of fileTypes"
            [value]="fileType.id">
            {{fileType.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <input type="file" class="file-input"
        (change)="onFileSelected($event)" #fileUpload>

      <div *ngIf="!file" class="file-upload">
        <button mat-menu-item (click)="fileUpload.click()"> <mat-icon>attach_file</mat-icon>Choose a file</button>
      </div>

      <div *ngIf="file" class="file-upload" fxLayout="row">
        <button class="noAction" mat-menu-item (click)="fileUpload.click()"> <mat-icon>attach_file</mat-icon>{{file.name}}</button>
        <button mat-icon-button color="primary" (click)="removeFiles()"><mat-icon>close</mat-icon></button>
      </div>

    </form>

    <div fxLayout="row wrap" *ngIf="step === 2" class="filters">
      <div *ngFor="let filter of this.filters; let i= index">
        <mat-form-field *ngIf="filter.type === 'dropdown' && filter.isSystemTag!== true" class="filterLayoutDropdown" appearance="fill">
          <mat-label>{{filter.name}}</mat-label>
          <mat-select
            (selectionChange)="addFilter(filter.tagId, $event,'Dropdown')"
            [placeholder]="'Select '+ filter.name">
            <mat-option *ngFor="let data of filter.data"
              [value]="data.key">
              {{data.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="filter.type === 'textbox'" class="filterLayoutText" appearance="fill">
          <mat-label>{{filter.name}}</mat-label>
          <input [(ngModel)]="filter.changedValue" (change)="addFilter(filter.tagId, filter.changedValue,'Text')" matInput>
        </mat-form-field>

        <mat-form-field *ngIf="filter.type === 'date'" class="filterLayoutDate" appearance="fill" class="example-form-field">
          <mat-label>{{filter.name}}</mat-label>
          <input (dateChange)="addFilter(filter.tagId,$event,'Date')" matInput [matDatepicker]="datepicker">
          <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
          <mat-datepicker #datepicker>
            <mat-datepicker-actions>
              <button mat-button matDatepickerCancel>Cancel</button>
              <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
            </mat-datepicker-actions>
          </mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <hr class="horizontalline">

  </mat-dialog-content>

  <mat-dialog-actions *ngIf="selectedvalues" align="end">
    <button *ngIf="step === 1 && selectedvalues.length>0" mat-button (click)="step= step+1" cdkFocusInitial>Next</button>
    <div class="DisableActionButtonWrapper">
      <button *ngIf="step === 2" mat-stroked-button
        color="primary" (click)="onFileUpload()">Upload</button>
    </div>
  </mat-dialog-actions>
</div>
