<mat-toolbar class="toolbar">
  <mat-icon>manage_accounts</mat-icon> <span style="padding-left: 10px;" fxFlex> Manage Account</span>

  <div fxLayout="row" fxLayoutAlign="end">
    <!-- <mat-icon class="icons" matTooltip="Save Changes">save</mat-icon> -->
    <mat-icon class="icons" mat-dialog-close matTooltip="Close">close</mat-icon>

  </div>

</mat-toolbar>
<div class="page" *ngIf="this.userDetail">

  <div class="left-section">
    <div class="profile-section">
      <img [src]="imageUrl" alt="Profile Picture" class="image-container">

      <button class="full_width button" (click)="fileInput.click()" mat-flat-button>
        <mat-icon color="white">upload</mat-icon> Change Profile Picture
      </button>
      <input
        type="file"
        #fileInput
        accept="image/png"
        style="display: none;"
        (change)="onFileSelected($event)">
    </div>
    <hr class="horizontalline-white">
    <div class="form-section">

      <!-- Switch User -->
      <div *ngIf="isSuperuser" class="wrap-button">
        <button class="full_width button-primary" (click)="resetPassword()" mat-flat-button>
          <mat-icon>admin_panel_settings</mat-icon> Reset Password
        </button>
      </div>

      <!-- Switch User -->
      <div *ngIf="isSuperuser && profileView" class="wrap-button">
        <button class="full_width button-primary" (click)="switchUserDialog()" mat-flat-button>
          <mat-icon>groups</mat-icon> Switch User
        </button>
      </div>

      <!-- Logout Button -->
      <div *ngIf="profileView" class="wrap-button" mat-dialog-close (click)="ExitDialog()">
        <button class="full_width button-primary" mat-flat-button>
          <mat-icon>exit_to_app</mat-icon> Logout
        </button>
      </div>

      <!-- Assign Properties Button -->
      <div *ngIf="!profileView" class="wrap-button" (click)="clientProfileLink()">
        <button class="full_width button-primary" mat-flat-button>
          <mat-icon>recent_actors</mat-icon> Assign Properties
        </button>
      </div>

      <!-- Manage User Roles Button -->
      <div *ngIf="!profileView" class="wrap-button" (click)="rolesUserLink()">
        <button class="full_width button-primary" mat-flat-button>
          <mat-icon>group</mat-icon> Manage User Roles
        </button>
      </div>

      <!-- Logout Button -->
      <div *ngIf="!profileView" class="wrap-button" (click)="ResourcesLink()">
        <button class="full_width button-primary" mat-flat-button>
          <mat-icon>perm_contact_calendar</mat-icon> Manage User Resources
        </button>
      </div>

    </div>

    <hr class="horizontalline-white">

  </div>

  <div class="right-section">

    <mat-tab-group>

      <mat-tab>

        <ng-template mat-mdc-tab mat-tab-label>
          <mat-icon class="example-tab-icon">account_circle</mat-icon>
          Profile
        </ng-template>
        <mat-card appearance="outlined">
          <mat-card-content style="border-style: none">
            <div fxLayout="column" fxLayoutAlign="space-between stretch">
              <div [formGroup]="UserHolder">
                <mat-form-field fxLayout="column">
                  <mat-label>Username</mat-label>
                  <input apperance="fill" formControlName="userName" matInput type="text" [maxlength]="149" />
                  <mat-label *ngIf="userNameFound" style="color: red"> already in use !</mat-label>
                </mat-form-field>
                <div fxLayout="row" fxLayoutGap="10px">
                  <mat-form-field fxFlex="14%" fxLayout="column">
                    <mat-label>Title</mat-label>
                    <input apperance="fill" formControlName="title" matInput type="text" />
                  </mat-form-field>
                  <mat-form-field fxFlex="40%" fxLayout="column">
                    <mat-label>First Name</mat-label>
                    <input apperance="fill" formControlName="firstName" matInput type="text" [maxlength]="149" />
                    <mat-error *ngIf="firstName.invalid">Only Text.</mat-error>
                  </mat-form-field>
                  <mat-form-field fxFlex="40%" fxLayout="column">
                    <mat-label>Last Name</mat-label>
                    <input apperance="fill" formControlName="lastName" matInput type="text" [maxlength]="149" />
                    <mat-error *ngIf="lastName.invalid">Only Text.</mat-error>
                  </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutGap="10px">
                  <mat-form-field fxFlex="33%" fxLayout="column" fxLayout="column">
                    <mat-label>Email</mat-label>
                    <input apperance="fill" formControlName="email" matInput type="text" />
                  </mat-form-field>

                  <mat-form-field class="error-style" fxFlex="33%" fxLayout="column">
                    <mat-label>Mobile</mat-label>
                    <input apperance="fill" formControlName="mobileNo" matInput type="text" [maxlength]="49" />
                    <mat-error *ngIf="UserHolder.get('mobileNo')?.hasError('notNumber')"> Only numbers are allowed. </mat-error>
                  </mat-form-field>
                  <mat-form-field class="error-style" fxFlex="33%" fxLayout="column">
                    <mat-label>Telephone</mat-label>
                    <input appearance="fill" formControlName="telephoneNo" matInput type="text" [maxlength]="49" />
                    <mat-error *ngIf="UserHolder.get('telephoneNo')?.hasError('notNumber')"> Only numbers are allowed. </mat-error>
                  </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="10px">
                  <mat-form-field class="error-style" fxFlex="50%" fxLayout="column">
                    <mat-label>Company</mat-label>
                    <input apperance="fill" formControlName="companyName" matInput type="text" [maxlength]="49" />
                  </mat-form-field>
                  <mat-form-field class="error-style" fxFlex="50%" fxLayout="column">
                    <mat-label>User Type</mat-label>
                    <input appearance="fill" formControlName="isExternal" matInput type="text" [maxlength]="49" />
                  </mat-form-field>
                </div>

              </div>
            </div>
          </mat-card-content>

          <mat-card-actions fxLayoutAlign="end end">
            <button (click)="editDetails()" mat-raised-button class="button-primary-dark">{{this.editMode ?'Save':'Create'}}</button>
          </mat-card-actions>

        </mat-card>
        <app-table *ngIf="dataSource_resources"
          [dataSourceIn]="dataSource_resources.filteredData"
          [tableSettingsIn]="tableSettings"
          [displayedColumnsIn]="columnDefinition_resources"
          [showPaginator]="true"
          [expandAll]="false"
          [treeTable]="true"
          (iconClicked)="viewDetails($event)">
        </app-table>
      </mat-tab>

      <mat-tab>

        <ng-template mat-mdc-tab mat-tab-label>
          <mat-icon class="example-tab-icon">mail</mat-icon>
          Notifications
        </ng-template>
        <app-table *ngIf="dataSource_notifications"
          [dataSourceIn]="dataSource_notifications.filteredData"
          [tableSettingsIn]="tableSettings"
          [displayedColumnsIn]="columnDefinition_notifications"
          [showPaginator]="true"
          [expandAll]="false"
          [treeTable]="true"
          (iconClicked)="viewDetails($event)">
        </app-table>
      </mat-tab>
      <mat-tab>

        <ng-template mat-mdc-tab mat-tab-label>
          <mat-icon class="example-tab-icon">newspaper</mat-icon>
          Logs
        </ng-template>
        <app-table *ngIf="dataSource_logs"
          [dataSourceIn]="dataSource_logs.filteredData"
          [tableSettingsIn]="tableSettings"
          [displayedColumnsIn]="columnDefinition_logs"
          [showPaginator]="true"
          [expandAll]="false"
          [treeTable]="true"
          (iconClicked)="viewDetails($event)">
        </app-table>
      </mat-tab>
    </mat-tab-group>

  </div>

</div>
