<div class="login" fxLayout="column" fxLayoutAlign="start end">
  <div class="resetBody" fxLayoutAlign="center center">
    <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
      <div fxLayout="column" fxLayoutAlign="center stretch">
        <div class="logo-container">
          <img class="logo" src="/assets/img/App-Logo.png" />
        </div>
        <mat-form-field>
          <input matInput formControlName="oldPassword" type="text" autocomplete="new-password" placeholder="Old password" required />
          <mat-error *ngIf="oldPassword.hasError('required')"> Old password is <strong>required</strong> </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput formControlName="newPassword" type="text" autocomplete="new-password" placeholder="New Password" required />
          <mat-error *ngIf="newPassword.hasError('required')"> New password is <strong>required</strong> </mat-error>
        </mat-form-field>
        <!-- <password-strength-meter [enableFeedback]="!resetForm?.valid" [minPasswordLength]="6" (strengthChange)="validatePassword($event)" [password]="newPassword.value"></password-strength-meter> -->
        <button [disabled]="!resetForm.valid" type="submit" mat-raised-button color="primary">Reset Password</button>
        <div class="errorMessage" *ngIf="error.length > 0">{{ error }}</div>
      </div>
    </form>
  </div>
</div>
