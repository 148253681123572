<button *ngIf="icon.displayOnLevels?.includes(currentLevel)" mat-icon-button [matMenuTriggerFor]="menu">
  <mat-icon class="pointer" [ngStyle]="{color: icon.color}">{{icon.materialIcon}}</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <ng-container *ngFor="let option of matOptions; let i= index ">
    <button *ngIf="option.level?.includes(this.currentLevel)" mat-menu-item (click)="toggleClicked(option)">
      <mat-icon *ngIf="option.icon !==''" [style]="{color:option.color}">{{option.icon}}</mat-icon>
      <span [style]="{color:option.color}">{{option.name}}</span>
    </button>
  </ng-container>

</mat-menu>
