import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';

export interface ColumnItem {
  value: string;
}

export interface dropdownObj {
  dropdownOpen: boolean;
  column: string;
}

@Component({
  selector: 'app-basic-column-filter',
  templateUrl: './basic-column-filter.component.html',
  styleUrls: ['./basic-column-filter.component.scss']
})
export class BasicColumnFilterComponent implements OnInit, OnChanges {
  selectedOptions: any[] = [];
  allSelected = false;
  filteredDate!: Observable<any[]>;
  @Input() column: string = '';
  @Input() friendlyName!: string;
  @Input() filteredDataSource: any = [];
  filteredDataSourceHolder: any = [];
  smartFiltersActivated: boolean = false;
  columnSearchDatasource: any[] = [];
  @Output() columnHeaderSelected = new EventEmitter<ColumnItem>();
  @Output() removeColumnFilter = new EventEmitter<ColumnItem>();
  @Output() dropdownClosed = new EventEmitter<dropdownObj>();
  @Output() filterChange = new EventEmitter<any>();

  inputFilter_txt = new UntypedFormControl('');

  private _filterdata(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.columnSearchDatasource.filter((value: string) => value.toLowerCase().includes(filterValue)).sort();
  }

  constructor() {}

  ngOnInit() {
    if (!this.friendlyName) {
      this.friendlyName = this.formatColumnName(this.column);
    }

    this.populateDropdown();

    this.filteredDate = this.inputFilter_txt.valueChanges.pipe(
      startWith(''),
      map((state: any) => (state ? this._filterdata(state) : this.columnSearchDatasource.slice()).sort())
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['filteredDataSource']) {
      this.populateDropdown();
    }
  }

  populateDropdown() {
    this.columnSearchDatasource = this.filteredDataSource.map((data: any) => {
      return data[this.column];
    });
    this.columnSearchDatasource = this.GetDistinctValues(this.columnSearchDatasource);
  }

  GetDistinctValues(Source: Array<any>): Array<any> {
    let DistinctArray: any = [];
    try {
      Source.forEach((e) => {
        if (DistinctArray.indexOf(e) === -1) DistinctArray.push(e);
      });
    } catch (error) {
      DistinctArray = [];
    }
    return DistinctArray;
  }

  HeaderSelected() {
    this.smartFiltersActivated = true;
  }

  removeColumn() {
    this.smartFiltersActivated = false;
    this.selectedOptions = [];
    this.filterChange.emit([]);
  }

  columnHeaderClosed(event: any) {
    this.dropdownClosed.emit({ dropdownOpen: event, column: this.column });
  }

  filter(event: any) {
    this.selectedOptions = event.value;
    this.filterChange.emit(event.value);
  }

  private formatColumnName(column: string) {
    return (
      column.charAt(0).toUpperCase() +
      column
        .slice(1)
        .replace(/([A-Z])/g, ' $1')
        .trim()
    );
  }

  toggleSelectAll() {
    if (!this.allSelected) {
      this.selectedOptions = [...this.columnSearchDatasource.map((item) => (item.key ? item.key + '/' + item.value : item)), 'all'];
      this.filterChange.emit(
        this.columnSearchDatasource.map((x) => {
          return x;
        })
      );
    } else {
      this.selectedOptions = [];
      this.filterChange.emit([]);
    }
    this.allSelected = !this.allSelected;
  }
}
