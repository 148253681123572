<div class="login" fxLayout="column" fxLayoutAlign="center center">
  <div class="loginBody" fxLayoutAlign="center center" [class.mat-elevation-z2]="!this.authForm.touched" [class.mat-elevation-z8]="this.authForm.touched">
    <form [formGroup]="authForm" (ngSubmit)="onSubmit()">
      <div fxLayout="column" fxLayoutAlign="center stretch">
        <div class="logo-container">
          <img class="logo" src="/assets/img/App-Logo.png" />
        </div>
        <mat-form-field>
          <input matInput formControlName="username" type="text" autocomplete="username" placeholder="Username" required />
          <mat-error *ngIf="username.hasError('required')"> Username is <strong>required</strong> </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput autocomplete="current-password" formControlName="password" type="password" placeholder="Password" required />
          <mat-error *ngIf="password.hasError('required')"> Password is <strong>required</strong> </mat-error>
        </mat-form-field>

        <button [disabled]="!authForm.valid" type="submit" mat-raised-button color="primary">Sign in</button>

        <button mat-button (click)="forgotPassword()" color="primary">Forgot password ?</button>

        <div class="errorMessage" *ngIf="error.length > 0">
          {{ error }}
        </div>
        <div class="footer">
          <a href="https://cbos.co.za/" target="_blank" class="footer-link">
            <img src="assets/img/cbospfp.png" alt="Logo" class="footer-logo">
            <span>Powered by CBOS</span>
          </a>

        </div>
      </div>
    </form>
  </div>
</div>
