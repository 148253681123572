<div class="login" fxLayout="column" fxLayoutAlign="start end">
  <div class="forgotBody" fxLayoutAlign="center center">
    <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
      <div fxLayout="column" fxLayoutAlign="center stretch">
        <div class="logo-container">
          <img class="logo" src="/assets/img/App-Logo.png" />
        </div>
        <p>Please enter your email address to reset your password.</p>
        <mat-form-field>
          <input matInput formControlName="email" type="email" autocomplete="email" placeholder="Email" required />
          <mat-error *ngIf="email.hasError('required')"> Email is <strong>required</strong> </mat-error>
        </mat-form-field>

        <button [disabled]="!resetForm.valid" type="submit" mat-raised-button color="primary">Forgot Password</button>

        <button mat-button (click)="cancel()" color="primary">Cancel</button>

        <div class="errorMessage" *ngIf="error.length > 0">
          {{ error }}
        </div>
      </div>
    </form>
  </div>
</div>
