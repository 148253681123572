import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PendingRequestsInterceptor } from '@app/core/interceptors/pending-requests-interceptor';
import { DataService } from '@app/core/services/data-service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
  resetForm!: UntypedFormGroup;
  email!: UntypedFormControl;
  error = '';
  isLoading$: Observable<boolean>;

  constructor(private dataService: DataService, private router: Router, private pendingRequestsInterceptor: PendingRequestsInterceptor) {
    this.isLoading$ = this.pendingRequestsInterceptor.pendingRequestsStatus$;
  }

  ngOnInit() {
    this.createFormControls();
    this.createForm();
  }

  createFormControls() {
    this.email = new UntypedFormControl('', [Validators.required, Validators.email]);
  }

  createForm() {
    this.resetForm = new UntypedFormGroup({
      email: this.email
    });
  }

  onSubmit(): void {
    if (this.resetForm.valid) {
      this.dataService.forgotPassword(this.resetForm.value).subscribe(() => {
        this.router.navigate(['']);
      });
    }
  }
  cancel() {
    this.router.navigate(['']);
  }
}
