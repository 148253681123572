export enum columnTypes {
  button,
  date,
  hover,
  hyperlink,
  icon,
  matOption,
  matDropdown,
  number,
  text,
  expand,
  iconEnum,
  selector,
  wog_dropdown,
  wog_textField,
  wog_numericField
}

// WOG - WORK ON GRID

export enum numberTypes {
  currency,
  decimal,
  int
}

export enum textAlign {
  Left = 'text-align: left;align-content: left;',
  Center = 'text-align: center;align-content: center;',
  Right = 'text-align: right;align-content: right;'
}
