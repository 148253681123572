import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PendingRequestsInterceptor } from '@app/core/interceptors/pending-requests-interceptor';
import { AuthService } from '@app/core/services/auth-service';
import { DataService } from '@app/core/services/data-service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {
  resetForm!: UntypedFormGroup;
  oldPassword!: UntypedFormControl;
  newPassword!: UntypedFormControl;
  error = '';
  isLoading$: Observable<boolean>;

  constructor(private dataService: DataService, private router: Router, private authService: AuthService, private pendingRequestsInterceptor: PendingRequestsInterceptor) {
    this.isLoading$ = this.pendingRequestsInterceptor.pendingRequestsStatus$;
  }

  ngOnInit() {
    this.createFormControls();
    this.createForm();
  }

  createFormControls() {
    this.oldPassword = new UntypedFormControl(this.authService.resetPasswordCode, [Validators.required, Validators.minLength(6)]);
    this.newPassword = new UntypedFormControl('', [Validators.required, Validators.minLength(6)]);
    if (this.authService.resetPasswordCode && this.authService.resetPasswordCode.length > 0) {
      this.oldPassword.disable();
    }
  }

  createForm() {
    this.resetForm = new UntypedFormGroup({
      oldPassword: this.oldPassword,
      newPassword: this.newPassword
    });
  }

  validatePassword(val: number) {
    if (val < 3) {
      this.newPassword.setErrors({ incorrect: true });
    }
  }

  onSubmit(): void {
    this.oldPassword.enable();
    if (this.resetForm.valid) {
      this.dataService.resetPassword(this.resetForm.value).subscribe(() => {
        this.authService.refreshToken().subscribe(() => {
          this.router.navigate(['/secure/dashboard']);
        });
      });
    }
  }
}
