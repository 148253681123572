import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'tooltipFormatter' })
export class TooltipFormatterPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(items: Array<{ icon: string; hoverText: string }>): SafeHtml {
    const html = items.map((item) => `<mat-icon class="tooltip-icon">${item.icon}</mat-icon> ${item.hoverText}`).join('<br>');

    // Return the sanitized HTML string
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
