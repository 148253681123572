import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Color, ScaleType } from '@swimlane/ngx-charts';
import { numberTypes, textAlign } from '../../../enums/columnSettings';
import { NumberSettings } from '../../../classes/numberSettings';
import { wog_settings } from '../../../classes/wog';

@Component({
  selector: 'app-wog-numeric-field-column',
  templateUrl: './wog_numeric-field-column.component.html',
  styleUrls: ['./wog_numeric-field-column.component.scss']
})
export class WogNumericFieldColumnComponent implements OnInit, OnChanges {
  @Input() settingsIn: NumberSettings = new NumberSettings();
  @Input() columnIn: any;
  @Input() elementIn: any;
  @Input() currentLevel: number = 1;
  @Input() wog_settingsIn: wog_settings = new wog_settings();
  @Input() refreshBit: number = 0;
  @Output() wog_numericFieldChanged = new EventEmitter<any>();

  value!: number;
  settings!: NumberSettings;
  numberTypes = numberTypes;
  changesMade = false;
  textAlign = textAlign;
  readOnly = false;

  constructor() {}

  ngOnInit() {
    this.value = this.value = this.elementIn[this.columnIn.columnName];
    this.settings = this.settingsIn;
    this.readOnly = this.isDisabled(this.elementIn, this.wog_settingsIn);
  }

  ngOnChanges() {
    this.value = this.value = this.elementIn[this.columnIn.columnName];
    this.settings = this.settingsIn;
    this.readOnly = this.isDisabled(this.elementIn, this.wog_settingsIn);
  }

  isDisabled(element: any, wog_settingsIn: wog_settings) {
    return wog_settingsIn.readonly ? wog_settingsIn.readonly(element) : false;
  }

  changed() {
    this.wog_numericFieldChanged.emit({ value: this.elementIn[this.columnIn.columnName], element: this.elementIn, refresh: this.wog_settingsIn.onChangeRefresh });
  }
}
