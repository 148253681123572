
<section *ngIf="numberTypes.currency === settings.numberType">
  {{ settings.prefix }} {{ value | customCurrency }}{{ settings.suffix }}
</section>
<section *ngIf="numberTypes.decimal === settings.numberType">
  {{settings.prefix}} {{value| customDecimal | number : '1.0-0'}} {{settings.suffix}}
</section>
<section *ngIf="numberTypes.int === settings.numberType">
  {{settings.prefix}} {{value}} {{settings.suffix}}
</section>
