import { Component, Input, OnInit } from '@angular/core';
import { Color, ScaleType } from '@swimlane/ngx-charts';
import { ICardGraph } from '../graph-Interfaces';

@Component({
  selector: 'app-card-graph',
  templateUrl: './card-graph.component.html',
  styleUrls: ['./card-graph.component.scss']
})
export class CardGraphComponent implements OnInit {
  @Input() graphSettings: ICardGraph = { data: [] };

  // Customize pie chart options
  view!: [number, number];

  cardColor!: string;

  colorScheme: Color = {
    name: 'cool', // or any other valid name
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#04172e', '#0059e6', '#335672', '#b7c3cd', '#37a8cb', '#57748b']
  };

  // Graph Properties
  height: number = 700;
  width: number = 400;
  colors: string[] = ['#04172e', '#0059e6', '#335672', '#b7c3cd', '#37a8cb', '#57748b'];
  fillColor: string = '';
  fillBackground: boolean = false;
  header?: string;
  footer?: string;
  textColor: string = 'white';
  autoWidth: boolean = false;
  dataSource: any;
  title: string = '';

  defaultBackgroundColor = '#fafafa';
  primaryBackgroundColor = '#0f3859';

  constructor() {}

  ngOnInit() {
    this.setProperties();
  }

  setProperties() {
    this.height = this.graphSettings.height ?? this.height;
    this.width = this.graphSettings.width ?? this.width;
    this.colors = this.graphSettings.colors ?? this.colors;
    this.fillColor = this.graphSettings.fillColor ?? this.fillColor;
    this.fillBackground = this.graphSettings.fillBackground ?? this.fillBackground;
    this.header = this.graphSettings.header ?? this.header;
    this.footer = this.graphSettings.footer ?? this.footer;
    this.textColor = this.graphSettings.textColor ?? this.textColor;
    this.autoWidth = this.graphSettings.autoWidth ?? this.autoWidth;
    this.dataSource = this.graphSettings.data ?? this.dataSource;
    this.title = this.graphSettings.title ?? this.title;

    if (this.title != '') {
      this.height = this.height - 30;
    }
    // Set the view dynamically based on height and width inputs
    if (this.autoWidth) {
      this.setWidthViewSize();
    } else {
      this.view = [this.width, this.height];
    }
    this.colorScheme.domain = this.colors;
    if (this.fillBackground) {
      this.fillColor = this.fillColor != '' ? this.fillColor : this.primaryBackgroundColor;
      this.cardColor = this.fillColor;
    } else if (this.header || this.footer) {
      this.fillColor = this.defaultBackgroundColor;
    }
  }

  // Function to format label
  labelFormatting(value: any): string {
    return `${value.label}: ${value.value}`;
  }

  // Function to handle click events
  onSelect(event: any): void {}

  setWidthViewSize() {
    // Get the length of the value
    var valueLength = this.dataSource[0].value.toString().length;
    // Calculate the width of the container based on the length of the value
    const containerWidth = valueLength * 50; // Adjust the factor as needed
    this.width = containerWidth;

    this.view = [containerWidth, this.height];
  }
}
