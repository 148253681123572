import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { map, mergeMap, share } from 'rxjs/operators';
import { ILoginIn } from '../contracts/accounts';
import { IPermissions, IPreference, IUserContext, IUserPreference } from '../contracts/users';
import { DataService } from './data-service';

@Injectable()
export class AuthService {
  error = '';
  userContext: IUserContext;
  menuGroups: IPermissions[];
  menuPermissions: IPermissions[];
  permissions: IPermissions[];
  imageUrl: any = 'assets/img/defaultImage.png';

  preference: IPreference[];

  authenticated$: BehaviorSubject<any> = new BehaviorSubject(false);
  resetPasswordCode = '';
  constructor(private dataService: DataService, private jwtHelper: JwtHelperService) {
    this.userContext = JSON.parse(localStorage.getItem('user_context') as string);
    this.menuGroups = [];
    this.menuPermissions = [];
    this.permissions = [];
    this.preference = [];
  }
  public async authenticated(): Promise<boolean> {
    let token: string | null = await this.jwtHelper.tokenGetter();

    if (typeof token === 'string') {
      let ex = !this.jwtHelper.isTokenExpired(token);
      if (ex) {
        this.authenticated$.next(true);
        return true;
      } else {
        const refreshToken = localStorage.getItem('refresh_token');
        if (refreshToken) {
          this.authenticated$.next(true);
          try {
            const result = await this.dataService.getPingResult().toPromise();
            ex = !this.jwtHelper.isTokenExpired(token);
            return ex;
          } catch (error) {
            return false;
          }
        }
      }
    }

    return false;
  }

  private checkTokenExpiry(token: string | null): Observable<boolean> {
    if (token) {
      let ex = !this.jwtHelper.isTokenExpired(token);
      if (ex) {
        this.authenticated$.next(true);
        return of(true);
      } else {
        const refreshToken = localStorage.getItem('refresh_token');
        if (refreshToken) {
          this.authenticated$.next(true);
          return this.dataService.getPingResult().pipe(
            map(() => {
              ex = !this.jwtHelper.isTokenExpired(token);
              return ex;
            })
          );
        }
      }
    }

    return of(false);
  }
  public authorised(roles: number[]) {
    if (roles) {
      const tokenPromise = this.jwtHelper.tokenGetter();
      return from(tokenPromise).pipe(
        mergeMap((token: string) => {
          const roleBW = roles.reduce((a, b) => a + b, 0);
          return of((this.jwtHelper.decodeToken(token).role & roleBW) > 0);
        })
      );
    } else {
      return of(true);
    }
  }

  login(credentials: ILoginIn) {
    return this.dataService.loginUser(credentials).pipe(
      map((res) => {
        // store the new tokens
        localStorage.setItem('refresh_token', res.refreshToken);
        localStorage.setItem('id_token', res.token);
        return res;
      })
    );
  }

  userSwitch(userId: string) {
    return this.dataService.userSwitch(userId).pipe(
      map((res) => {
        // localStorage.removeItem('id_token');
        // localStorage.removeItem('refresh_token');
        // localStorage.removeItem('user_context');
        // store the new tokensuserId
        // localStorage.setItem('refresh_token', res.refreshToken);
        // localStorage.setItem('id_token', res.token);
        this.setSwitchUserContext(userId);
        return res;
      })
    );
  }

  externalLogin(credentials: ILoginIn) {
    return this.dataService.ExternalLoginUser(credentials).pipe(
      map((res) => {
        // store the new tokens
        localStorage.setItem('refresh_token', res.refreshToken);
        localStorage.setItem('id_token', res.token);
        return res;
      })
    );
  }

  logout() {
    localStorage.removeItem('id_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user_context');
    URL.revokeObjectURL(this.imageUrl);

    this.authenticated$.next(false);
  }

  setUserContext() {
    return this.dataService.getUserContext().pipe(
      map((res) => {
        this.userContext = res;
        localStorage.setItem('user_context', JSON.stringify(this.userContext));
        this.setPermissions();
        this.setPreferences();
      })
    );
  }

  setSwitchUserContext(userId: string) {
    return this.dataService.getSwitchedUserContext(userId).subscribe((res) => {
      this.userContext = res;
      localStorage.setItem('user_context', JSON.stringify(this.userContext));
      this.setPermissions();
      this.setPreferences();
    });
  }

  refreshToken(): Observable<string> {
    // append refresh token if you have one
    const refreshToken = localStorage.getItem('refresh_token');
    return this.dataService.refeshToken(refreshToken as string).pipe(
      share(),
      map((res) => {
        // store the new tokens
        localStorage.setItem('refresh_token', res.refreshToken);
        localStorage.setItem('id_token', res.token);
        this.setUserContext();
        return res.token;
      })
    );
  }

  getToken(): Observable<string> {
    const token = localStorage.getItem('id_token') as string;
    const isTokenExpired = this.jwtHelper.isTokenExpired(token);

    if (!isTokenExpired) {
      return of(token);
    }
    return this.refreshToken();
  }

  setPermissions() {
    this.menuPermissions = this.userContext.permissions.filter((t) => t.parentId === null).sort((a, b) => a.index - b.index);
    this.permissions = this.userContext.permissions.filter((t) => t.parentId != null).sort((a, b) => a.index - b.index);
  }

  setPreferences() {
    this.preference = this.userContext.preferences;
    if (this.userContext.userPreferences.length > 0) {
      this.userContext.userPreferences.forEach((userPreference) => {
        var index = this.preference.findIndex((x) => x.id == userPreference.preferenceId);
        this.preference[index].defaultValue = userPreference.value;
      });
    }
  }

  permissionExist(p: string): boolean {
    return this.userContext.permissions.some((t) => t.uniqueKey === p);
  }

  urlallowed(p: string): boolean {
    return this.userContext.permissions.some((t) => t.authPath === p);
  }

  getPermission(p: string): any {
    return this.permissions.find((t) => t.uniqueKey == p);
  }

  getPreference(p: string): any {
    return this.preference.find((t) => t.code == p)?.defaultValue;
  }

  getMenuPermission(p: string): any {
    return this.menuPermissions.find((t) => t.uniqueKey == p);
  }

  downloadProfileFile() {
    this.dataService.DownloadProfilePictureFile(this.userContext.id).subscribe((blob: Blob) => {
      if (blob) this.displayImage(blob);
      else {
        this.imageUrl = 'assets/img/defaultImage.png';
      }
    });
  }

  displayImage(blob: Blob) {
    // Create a URL for the Blob
    const objectUrl = URL.createObjectURL(blob);
    // Set the image URL
    this.imageUrl = objectUrl;

    // Optionally, you can revoke the object URL later to free up resources
  }
}
