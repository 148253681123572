import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  templateUrl: './unauthorized.component.html'
})
export class UnauthorizedComponent {
  constructor(private router: Router, private location: Location) {}

  login() {
    this.router.navigate(['']);
  }
  back() {
    this.location.back();
  }
}
