import { Component, Input, HostListener, ElementRef, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'; // Adjust the path as needed
import { TooltipDialogComponent } from './tooltip-dialog/tooltip-dialog.component';

@Component({
  selector: 'app-custom-table-tooltip',
  templateUrl: './custom-table-tooltip.component.html',
  styleUrls: ['./custom-table-tooltip.component.scss']
})
export class CustomTableTooltipComponent {
  @Input() tooltipContent: Array<{ icon: string; hoverText: string; color: string }> = [];

  constructor(private dialog: MatDialog, private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('mouseenter') onMouseEnter() {
    this.openDialog();
  }

  @HostListener('mouseleave') onMouseLeave() {
    // Optionally, you can close the dialog if needed
  }

  private openDialog() {
    const element = this.el.nativeElement.getBoundingClientRect();
    const top = element.top + window.scrollY;
    const left = element.left + window.scrollX;

    const dialogRef = this.dialog.open(TooltipDialogComponent, {
      data: { content: this.tooltipContent },
      panelClass: 'custom-dialog-container',
      position: {
        top: `${top}px`,
        left: `${left}px`
      }
    });

    dialogRef.afterOpened().subscribe(() => {
      const dialogElement = document.querySelector('.custom-dialog-container') as HTMLElement;
      if (dialogElement) {
        dialogElement.style.top = `${top}px`;
        dialogElement.style.left = `${left}px`;
      }
    });
  }
}
