export enum tenancyStatus {
  Active = 'Active',
  LeaseExpired = 'LeaseExpired',
  ThirtyDaysNotice = 'ThirtyDaysNotice',
  ThreeMonthNotice = 'ThreeMonthNotice',
  FourMonthNotice = 'FourMonthNotice',
  SixMonthNotice = 'SixMonthNotice',
  PTO = 'PTO',
  NotSet = 'NotSet'
}

export enum projectType {
  TenantIn = 'TenantIn',
  TenantOut = 'TenantOut',
  ComplianceProject = 'ComplianceProject',
  TenantOtherProject = 'TenantOtherProject',
  LandlordOtherProject = 'LandlordOtherProject'
}

export enum workScope {
  Pending = 'Pending',
  Cancel = 'Cancel',
  Construction = 'Construction',
  Compliance = 'Compliance',
  Cosmetic = 'Cosmetic',
  AsIsNoWork = 'AsIsNoWork'
}

export enum dataManagementStatus {
  InProcess = 'In Process',
  Pending = 'Pending',
  PTO = 'PTO',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  ChangedData = 'Changed Data',
  NewData = 'New Data',
  Vacant = 'Vacant',
  SapoaConversion = 'Sapoa Conversion'
}

export enum UserActiveStatus {
  UserActive = 'UserActive',
  UserInActive = 'UserInActive'
}

export enum UserLockStatus {
  UserLocked = 'UserLocked',
  UserNotLocked = 'UserNotLocked'
}

export enum complianceStatus {
  Completed = 'Completed',
  Pending = 'Pending',
  InProgressLate = 'InProgressLate',
  InProgress = 'InProgress',
  ProjectCancelled = 'ProjectCancelled',
  InstructionCancelled = 'InstructionCancelled',
  PTOPropertyTakeon = 'PTOPropertyTakeon'
}

export enum instructionStatus {
  Active = 'Active',
  Archived = 'Archived',
  Accepted = 'Accepted',
  AwaitingApproval = 'Awaiting Approval',
  Draft = 'Draft',
  Cancelled = 'Cancelled'
}

export enum leaseStatus {
  OneMonthNotification = 'OneMonthNotification',
  ThreeMonthNotification = 'ThreeMonthNotification',
  FourMonthNotification = 'FourMonthNotification',
  SixMonthNotification = 'SixMonthNotification',
  LeaseExpired = 'LeaseExpired'
}

export enum propertyStatus {
  Active = 'Active',
  OnHold = 'OnHold',
  InActive = 'InActive',
  PTO = 'PTO'
}

export enum trueFalse {
  true = 'true',
  false = 'false'
}

export enum PCS {
  Draft = 'Draft',
  Pending = 'Pending',
  Accepted = 'Accepted',
  Declined = 'Declined',
  Cancelled = 'Cancelled',
  Active = 'Active',
  Archived = 'Archived'
}

export enum blank {
  _blank = ''
}

export type IconPropertyKey =
  | keyof typeof tenancyStatus
  | keyof typeof instructionStatus
  | keyof typeof complianceStatus
  | keyof typeof dataManagementStatus
  | keyof typeof leaseStatus
  | keyof typeof PCS
  | keyof typeof propertyStatus
  | keyof typeof trueFalse
  | keyof typeof blank
  | keyof typeof UserActiveStatus
  | keyof typeof UserLockStatus;
// | keyof typeof workScope;

export type IconPropertiesType = {
  [key in IconPropertyKey]: {
    icon: string;
    hoverText: string;
    color: string;
  };
};

export const iconProperties: IconPropertiesType = {
  Active: {
    icon: 'task_alt',
    hoverText: 'Active',
    color: 'green'
  },
  Archived: {
    icon: 'archive',
    hoverText: 'Archived',
    color: 'rgb(91, 125, 170)'
  },
  AwaitingApproval: {
    icon: 'help',
    hoverText: 'AwaitingApproval',
    color: 'yellow'
  },
  Draft: {
    icon: 'circle',
    hoverText: 'Draft',
    color: 'grey'
  },
  Cancelled: {
    icon: 'close',
    hoverText: 'Cancelled',
    color: 'red'
  },
  LeaseExpired: {
    icon: 'error',
    hoverText: 'Lease expired',
    color: 'red'
  },
  ThirtyDaysNotice: {
    icon: 'report_problem',
    hoverText: '30 days notice',
    color: 'orange'
  },
  ThreeMonthNotice: {
    icon: 'report_problem',
    hoverText: '3 months notice',
    color: 'orange'
  },
  FourMonthNotice: {
    icon: 'report_problem',
    hoverText: '4 months notice',
    color: 'orange'
  },
  SixMonthNotice: {
    icon: 'report_problem',
    hoverText: '6 months notice',
    color: 'orange'
  },
  PTO: {
    icon: 'task_alt',
    hoverText: 'Property take-on',
    color: 'blue'
  },
  Accepted: {
    icon: 'task_alt',
    hoverText: 'Accepted',
    color: 'green'
  },
  InProgressLate: {
    icon: 'error',
    hoverText: 'Work In Progress - Late',
    color: 'red'
  },
  Pending: {
    icon: 'circle',
    hoverText: 'Pending',
    color: 'grey'
  },
  NotSet: {
    icon: 'radio_button_unchecked',
    hoverText: 'Not Set',
    color: 'blue'
  },
  PTOPropertyTakeon: {
    icon: 'task_alt',
    hoverText: 'Property take-on',
    color: 'blue'
  },
  InProcess: {
    icon: 'change_circle',
    hoverText: 'In Process',
    color: 'orange'
  },
  Completed: {
    icon: 'task_alt',
    hoverText: 'Completed',
    color: 'green'
  },
  ChangedData: {
    icon: 'error',
    hoverText: 'Changed Data',
    color: 'purple'
  },
  NewData: {
    icon: 'error',
    hoverText: 'New Data',
    color: 'blue'
  },
  OneMonthNotification: {
    icon: 'report_problem',
    hoverText: '1 Month Notification',
    color: 'orange'
  },
  ThreeMonthNotification: {
    icon: 'report_problem',
    hoverText: '3 Month Notification',
    color: 'orange'
  },
  FourMonthNotification: {
    icon: 'report_problem',
    hoverText: '4 Month Notification',
    color: 'orange'
  },
  SixMonthNotification: {
    icon: 'report_problem',
    hoverText: '6 Month Notification',
    color: 'orange'
  },
  OnHold: {
    icon: 'pan_tool',
    hoverText: 'On Hold',
    color: 'grey'
  },
  InActive: {
    icon: 'do_disturb_on',
    hoverText: 'In Active',
    color: 'red'
  },
  true: {
    icon: 'check_box',
    hoverText: 'Yes',
    color: 'green'
  },
  false: {
    icon: 'check_box_outline_blank',
    hoverText: 'No',
    color: 'grey'
  },
  _blank: {
    icon: '',
    hoverText: '',
    color: ''
  },
  Declined: {
    icon: 'do_disturb_on',
    hoverText: 'Declined',
    color: 'red'
  },
  Vacant: {
    icon: 'person_off',
    hoverText: 'Vacant',
    color: 'grey'
  },
  UserLocked: {
    icon: 'lock',
    hoverText: 'User Locked',
    color: 'red'
  },
  UserNotLocked: {
    icon: 'lock_open',
    hoverText: 'Unlocked',
    color: 'green'
  },
  UserActive: {
    icon: 'toggle_on',
    hoverText: 'Active',
    color: 'green'
  },
  UserInActive: {
    icon: 'toggle_off',
    hoverText: 'Inactive',
    color: 'red'
  },
  InProgress: {
    icon: 'change_circle',
    hoverText: 'Work In Progress',
    color: 'orange'
  },
  ProjectCancelled: {
    icon: 'do_not_disturb_off',
    hoverText: 'Project Cancelled',
    color: 'purple'
  },
  InstructionCancelled: {
    icon: 'unpublished',
    hoverText: 'Instruction Cancelled',
    color: 'blue'
  },
  // Cancel: {
  //   icon: '',
  //   hoverText: 'Cancel',
  //   color: ''
  // },
  // Construction: {
  //   icon: '',
  //   hoverText: 'Construction',
  //   color: ''
  // },
  // Compliance: {
  //   icon: '',
  //   hoverText: 'Compliance',
  //   color: ''
  // },
  // Cosmetic: {
  //   icon: '',
  //   hoverText: 'Cosmetic',
  //   color: ''
  // },
  // AsIsNoWork: {
  //   icon: '',
  //   hoverText: 'AsIsNoWork',
  //   color: ''
  // }
  SapoaConversion: {
    icon: 'move_up',
    hoverText: 'SAPOA Conversion',
    color: 'blue'
  }
};
