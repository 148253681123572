import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { wog_dropdownOptions, wog_settings } from '../../../classes/wog';

@Component({
  selector: 'app-wog-dropdown-column',
  templateUrl: './wog_dropdown-column.component.html',
  styleUrls: ['./wog_dropdown-column.component.scss']
})
export class WogDropdownColumnComponent implements OnInit, OnChanges {
  @Input() parentData: any;
  @Input() columnIn: any;
  @Input() elementIn: any;
  @Input() currentLevel: number = 1;
  @Input() wog_settingsIn: wog_settings = new wog_settings();
  @Input() refreshBit: number = 0;
  @Output() wog_dropDownSelectionChanged = new EventEmitter<any>();

  value!: any;
  options!: wog_dropdownOptions[];
  readOnly = false;

  constructor() {}

  ngOnInit() {
    this.value = this.value = this.elementIn[this.columnIn.columnName];
    this.readOnly = this.isDisabled(this.elementIn, this.wog_settingsIn);
    if (!this.readOnly) this.getOptions();
  }

  ngOnChanges() {
    this.readOnly = this.isDisabled(this.elementIn, this.wog_settingsIn);
    if (!this.readOnly) {
      this.getOptions();
    } else {
      this.value = this.value = this.elementIn[this.columnIn.columnName];
    }
  }

  isDisabled(element: any, wog_settingsIn: wog_settings) {
    return wog_settingsIn.readonly ? wog_settingsIn.readonly(element) : false;
  }

  getOptions() {
    if (this.wog_settingsIn.getOptionsFromCurrentLevel == true) {
      this.options = this.wog_settingsIn.optionsFromGrid ? this.wog_settingsIn.optionsFromGrid(this.parentData, this.elementIn) : [];
    } else {
      this.options = this.wog_settingsIn.dropdownOptions ?? [];
    }

    this.value = this.value = this.elementIn[this.columnIn.columnName];

    if (this.options.length == 0) {
      this.readOnly = true;
    }
  }

  changed(value: any) {
    if (value === '') {
      this.elementIn[this.columnIn.columnName] = null;
    } else {
      this.elementIn[this.columnIn.columnName] = value;
    }
    this.wog_dropDownSelectionChanged.emit({ value: this.elementIn[this.columnIn.columnName], element: this.elementIn, refresh: this.wog_settingsIn.onChangeRefresh });
  }
}
