export class wog_settings {
  level?: number[];
  readonly?: (rowData: any) => boolean = (rowData: any) => false;
  dropdownOptions?: wog_dropdownOptions[];
  getOptionsFromCurrentLevel?: boolean = false;
  optionsFromGrid?: (parentData: any, rowData: any) => wog_dropdownOptions[] = (parentData: any, rowData: any) => [];
  changedResponse?: string = '';
  onChangeRefresh?: boolean = false;
}

export interface wog_dropdownOptions {
  key: string | boolean;
  value: string;
}
