import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-progress-dialog',
  templateUrl: './progress-dialog.component.html',
  styleUrls: ['./progress-dialog.component.scss']
})
export class ProgressDialogComponent {
  property = '';
  message = '';
  icon = '';
  constructor(private dialogRef: MatDialogRef<ProgressDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.property = data.property;
    this.message = data.message;
    this.icon = data.icon;
  }

  onClose() {
    this.dialogRef.close();
  }
}
