import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-custom-pop-up-selector',
  templateUrl: './pendingInstructionsPopUp.component.html',
  styleUrls: ['./pendingInstructionsPopUp.component.scss']
})
export class pendingInstructionsPopUpComponent {
  @Output() changed = new EventEmitter<string[]>();
  pendingInstructions: string[] = ['TITLE', 'DESCRIPTION'];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<any>) {
    this.pendingInstructions = data.pendingInstructions;
  }

  SaveSelection() {
    this.dialogRef.close();
  }
}
