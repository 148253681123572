 <mat-form-field appearance="fill" style="width:300px;margin-left:10px; margin-right:10px;">
  <mat-icon mat.mdc-icon-button-theme matPrefix>{{matIcon}}</mat-icon>
  <mat-label>{{label}}</mat-label>
  <mat-select [(ngModel)]="sourceFilter" mat-select multiple class="title-select"
    [placeholder]="placeHolder">
    <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
      <mat-icon matTooltip="Select/ Deselect All" class="select-all" style="padding:0 19px 0 14px;" (click)="SelectAll()">
        select_all
      </mat-icon>

      <mat-label style="padding-right:10px">Search: </mat-label>
      <input matInput (keyup)="updateSearchText($event)">

    </div>

    <mat-select-trigger *ngIf="ShowAllText">
      {{'All' }}
    </mat-select-trigger>

    <mat-option (click)="selectionChanged()"
      *ngFor="let item of availableOptions"
      [value]="item.id">
      {{item.value}}
    </mat-option>
  </mat-select>
</mat-form-field>
