import { Component, Inject, ChangeDetectorRef, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IEProperty, IEBuilding, IEFloor, IEPocket } from '@app/core/contracts/eportfolio';

@Component({
  selector: 'app-reorder-structure',
  templateUrl: './reorder-structure.component.html',
  styleUrls: ['./reorder-structure.component.scss']
})
export class ReorderStructureDialogComponent {
  property: IEProperty;
  showPremises = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ReorderStructureDialogComponent>,
    private _snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef // Inject ChangeDetectorRef
  ) {
    this.property = data.property;
  }

  updateSequence(items: { sequence: number }[]) {
    items.forEach((item, index) => {
      item.sequence = index + 1; // Reorder sequences starting from 1
    });
  }

  dropBuilding(event: CdkDragDrop<any[]>, property: IEProperty) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(property.children, event.previousIndex, event.currentIndex);
      this.updateSequence(property.children);
      this.cdr.detectChanges();
    }
  }

  dropFloor(event: CdkDragDrop<IEFloor[]>, building: IEBuilding) {
    if (building.children && event.previousIndex !== event.currentIndex) {
      moveItemInArray(building.children, event.previousIndex, event.currentIndex);
      this.updateSequence(building.children);
      this.cdr.detectChanges(); // Trigger change detection after reordering floors
    }
  }

  dropPocket(event: CdkDragDrop<IEPocket[]>, floor: IEFloor) {
    if (floor.children && event.previousIndex !== event.currentIndex) {
      moveItemInArray(floor.children, event.previousIndex, event.currentIndex);
      this.updateSequence(floor.children);
      this.cdr.detectChanges(); // Trigger change detection after reordering pockets
    }
  }

  saveChanges() {
    try {
      this.dialogRef.close(this.property);
      this._snackBar.open('Changes saved successfully!', 'Close', { duration: 3000 });
    } catch (error) {
      this._snackBar.open('Error saving changes', 'Close', { duration: 3000 });
    }
  }

  trackById(index: number, item: any) {
    return item.id;
  }
}
