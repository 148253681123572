import { Component } from '@angular/core';

@Component({
  selector: 'app-app-shell-dialog',
  templateUrl: './app-shell-dialog.component.html',
  styleUrls: ['./app-shell-dialog.component.scss']
})
export class AppShellDialogComponent {
  constructor() {}
}
