

<div fxLayout="row" class="cardHeader">
  <div fxLayout="column" class="cardHeadercol1">
    <mat-card-title>View Files </mat-card-title>
    <mat-card-subtitle>View files for {{page}}: {{name}}</mat-card-subtitle>
  </div>
</div>
<mat-dialog-content>
  <mat-card-content ng *ngIf="fileTypeCutomObj.length> 0 ">
    <hr class="horizontalline">
    <mat-list *ngFor="let type of fileTypeCutomObj">
      <mat-list-item>
        <div fxLayout="row" class="fullWidth">
          <div class="FileTypeName">
            <span>{{type.FileTypeName}}</span>
          </div>
          <div>
            <button mat-icon-button matTooltip="Download Latest" (click)="DownloadLatestDocument(name,type.FileTypeName,type.FileTypeCount)">
              <mat-icon [matBadge]="type.FileTypeCount" matBadgeColor="primary">download</mat-icon>
            </button>
          </div>
        </div>
      </mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>
    <hr class="horizontalline">

  </mat-card-content>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button class="CancelButton"
    mat-dialog-close>Close
  </button>
</mat-dialog-actions>
