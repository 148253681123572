import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { ROUTES } from './app.routes';
import { CoreModule } from './core/core.module';
import { AppShellDialogComponent } from './layout/app-shell/app-shell-dialog/app-shell-dialog.component';
import { AppShellComponent } from './layout/app-shell/app-shell.component';
import { AuthShellComponent } from './layout/auth-shell/auth-shell.component';
import { ForgotComponent } from './layout/forgot/forgot.component';
import { LoginComponent } from './layout/login/login.component';
import { ResetComponent } from './layout/reset/reset.component';
import { PageNotFoundComponent } from './not-found.component';
import { SharedModule } from './shared/shared.module';
import { UnauthorizedComponent } from './unauthorized.component';
import { SpaceLinkLoginComponent } from './layout/space-link-login/space-link-login.component';

@NgModule({
    declarations: [AppComponent, AppShellComponent, AuthShellComponent, LoginComponent, ForgotComponent, ResetComponent, UnauthorizedComponent, PageNotFoundComponent, AppShellDialogComponent, SpaceLinkLoginComponent],
    imports: [SharedModule, CoreModule.forRoot(), RouterModule.forRoot(ROUTES)],
    bootstrap: [AppComponent]
})
export class AppModule {}
