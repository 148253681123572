import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { IActivityLogGroup, IActivityLogFilter } from '@app/core/contracts/activityLogs';
import { IProjectResources } from '@app/core/contracts/project';
import { IRole, IUserNotification, User, UserAccountDetails } from '@app/core/contracts/users';
import { AuthService } from '@app/core/services/auth-service';
import { DataService } from '@app/core/services/data-service';
import { Subject, takeUntil } from 'rxjs';
import { ColumnSettings } from '../tree-table/classes/columnSetting';
import { TableSettings } from '../tree-table/classes/tableSettings';
import { columnTypes, textAlign } from '../tree-table/enums/columnSettings';
import { LogDetailsDialogComponent } from '@app/features/settings/pages/user-settings/tabs/access-logs-users/dialog/log-details-dialog.component';
import { SwitchUserComponent } from '../switchUser/switch-user.component';
import { AppShellDialogComponent } from '@app/layout/app-shell/app-shell-dialog/app-shell-dialog.component';
import { Router } from '@angular/router';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { IResourceTypes, IUserResourceType } from '@app/core/contracts/resource';
import { ISettingsUserOut } from '@app/core/contracts/settings';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { ResourcesLinkdialogComponent } from '@app/features/settings/pages/user-settings/tabs/managerment-users/dialogs/resources-link/resources-link.component';
import { RolesUserLinkdialogComponent } from '@app/features/settings/pages/user-settings/tabs/managerment-users/dialogs/roles-user-link/roles-user-link.component';
import { ClientProfileLinkdialogComponent } from '@app/features/settings/pages/user-settings/tabs/managerment-users/dialogs/client-profile-link/client-profile-link.component';

@Component({
  selector: 'app-maintain-user-selector',
  templateUrl: './maintain-user.component.html',
  styleUrls: ['./maintain-user.component.scss']
})
export class MaintainUserDialogComponent implements OnInit {
  @Output() changed = new EventEmitter<string[]>();
  componentDestroyed$: Subject<boolean> = new Subject();
  // Data source for MatTable
  dataSource_logs: MatTableDataSource<IActivityLogGroup>;
  dataSource_notifications: MatTableDataSource<IUserNotification>;
  dataSource_resources: MatTableDataSource<IUserResourceType>;

  editMode = true;
  profileView = true;

  private indentStyle = (rowData: any): string => {
    if (!rowData) return '';
    const { name } = rowData;
    if (name.length > 0) return 'padding-left:0px';
    else return 'padding-left:20px';
  };

  // Table configuration settings
  tableSettings: TableSettings = new TableSettings({
    enablePaginator: false,
    backgroundColor: 'white',
    column_Styling: { backgroundColor: '#04172e', color: 'white' },
    row1_Styling: { backgroundColor: 'white', color: '#335672' },
    row2_Styling: { backgroundColor: '#b7c3cd', color: '#04172e' }
  });

  // Column definitions for MatTable
  columnDefinition_logs: ColumnSettings[] = [
    {
      columnName: 'expand',
      displayName: 'empty',
      columnType: columnTypes.expand,
      expandSettings: {
        openIcon: 'arrow_drop_down',
        closeIcon: 'arrow_right',
        levelLimit: [1],
        iconColor: 'green'
      },
      conditionalElementStyle: this.indentStyle
    },
    {
      columnName: 'name',
      displayName: 'Group',
      columnType: columnTypes.text,
      conditionalElementStyle: this.indentStyle
    },
    {
      columnName: 'instruction',
      displayName: 'Instruction',
      columnType: columnTypes.text
    },
    {
      columnName: 'action',
      displayName: 'Action',
      columnType: columnTypes.text
    },
    {
      columnName: 'logDate',
      displayName: 'Log Date',
      columnType: columnTypes.text
    },
    {
      columnName: 'options',
      displayName: 'View',
      columnType: columnTypes.icon,
      align: textAlign.Left,
      icon: {
        materialIcon: 'visibility',
        color: '#0f3859',
        hoverText: 'View Details',
        clickResponse: 'viewDetails',
        link: '',
        displayOnLevels: [2]
      }
    }
  ].map((setting) => new ColumnSettings(setting));

  columnDefinition_notifications: ColumnSettings[] = [
    {
      columnName: 'from',
      displayName: 'From',
      columnType: columnTypes.text
    },
    {
      columnName: 'subject',
      displayName: 'Subject',
      columnType: columnTypes.text
    },
    {
      columnName: 'description',
      displayName: 'Description',
      columnType: columnTypes.text
    },
    {
      columnName: 'createdDate',
      displayName: 'Date',
      columnType: columnTypes.text
    }
  ].map((setting) => new ColumnSettings(setting));

  columnDefinition_resources: ColumnSettings[] = [
    {
      columnName: 'name',
      displayName: 'Resources Assigned',
      columnType: columnTypes.text
    },
    {
      columnName: 'clientRole',
      displayName: 'Client Role',
      columnType: columnTypes.text
    }
  ].map((setting) => new ColumnSettings(setting));

  logoutDialog: MatDialogRef<AppShellDialogComponent> | undefined;

  UserHolder!: UntypedFormGroup;
  title!: UntypedFormControl;
  firstName!: UntypedFormControl;
  lastName!: UntypedFormControl;
  userName!: UntypedFormControl;
  email!: UntypedFormControl;
  mobileNo!: UntypedFormControl;
  telephoneNo!: UntypedFormControl;
  companyName!: UntypedFormControl;
  password!: UntypedFormControl;
  resourceTypes: IResourceTypes[] = [];
  resourceType!: UntypedFormControl;
  currentView = 'internal';
  addUser: boolean = false;
  isExternal!: UntypedFormControl;

  userNameFound: boolean = false;
  usernames: string[] = [''];
  roles!: IRole[];
  imageUrl: any = 'assets/img/defaultImage.png';
  isSuperuser = true;
  userId: string;
  userDetail!: UserAccountDetails;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<any>,
    private snackbar: MatSnackBar,
    private dataService: DataService,
    private authService: AuthService
  ) {
    this.userId = data.userId;
    this.dataSource_logs = new MatTableDataSource();
    this.dataSource_notifications = new MatTableDataSource();
    this.dataSource_resources = new MatTableDataSource();

    this.editMode = data.editMode;
    this.imageUrl = data.imageUrl;
    this.profileView = data.profileView;
  }

  ngOnInit() {
    this.createFilterControls();
    this.createFilterForm();
    this.getUsers();
    this.getUsernames();
  }

  // #region GET DATA
  getUsers() {
    this.dataService
      .getUsersAccountDetails(this.userId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.userDetail = data;
        this.dataSource_resources.data = data.children;
        this.dataSource_logs.data = data.logs;
        this.dataSource_notifications.data = data.notifications;

        this.fillFilterControls();
      });
  }

  viewDetails(event: any) {
    this.openDialog(event.element);
  }

  openDialog(details: any): void {
    this.dialog.open(LogDetailsDialogComponent, {
      width: '400px',
      data: details
    });
  }

  switchUserDialog() {
    this.logoutDialog = this.dialog.open(SwitchUserComponent, {
      width: '800px',
      height: '600px',
      disableClose: false
    });

    this.logoutDialog.afterClosed().subscribe((data) => {
      if (data === true) {
        this.authService.logout();
        this.router.navigate(['']);
      }
    });
  }

  ExitDialog(): void {
    this.logoutDialog = this.dialog.open(AppShellDialogComponent, {
      disableClose: false
    });

    this.logoutDialog.afterClosed().subscribe((data) => {
      if (data === true) {
        this.authService.logout();
        this.router.navigate(['']);
      }
    });
  }

  PasswordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const pattern = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}|:;<>?[\]\\\-='"/.,~`])[a-zA-Z0-9!@#$%^&*()_+{}|:;<>?[\]\\\-='"/.,~`]{8,}$/;
      const value = control.value;

      if (value && !pattern.test(value)) {
        return { password: true };
      }

      return null;
    };
  }

  numberOnlyValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!/^\d+$/.test(value)) {
      return { notNumber: true };
    }
    return null;
  }

  getUsernames() {
    this.dataService.getAllUserNames().subscribe((data) => {
      this.usernames = data;
    });
  }

  getRoles() {
    this.dataService.getAllRoles().subscribe((data) => {
      this.roles = data;
    });
  }

  createFilterControls() {
    this.title = new UntypedFormControl('', [Validators.maxLength(49), Validators.pattern('^[A-Za-z]*$')]);
    this.firstName = new UntypedFormControl('', [Validators.required, Validators.pattern('^[A-Za-z]*$'), Validators.maxLength(149)]);
    this.lastName = new UntypedFormControl('', [Validators.required, Validators.pattern('^[A-Za-z]*$'), Validators.maxLength(149)]);
    this.userName = new UntypedFormControl('', [Validators.required, Validators.pattern('^[A-Za-z]*$'), Validators.maxLength(149)]);
    this.email = new UntypedFormControl({ value: '', disabled: this.editMode }, Validators.email);

    this.companyName = new UntypedFormControl({ value: '', disabled: this.editMode });
    this.password = new UntypedFormControl('');
    this.resourceType = new UntypedFormControl('');
    this.isExternal = new UntypedFormControl({ value: false, disabled: this.editMode });
    this.mobileNo = new UntypedFormControl('', [Validators.required, this.numberOnlyValidator, Validators.maxLength(49)]);
    this.telephoneNo = new UntypedFormControl('', [this.numberOnlyValidator, Validators.maxLength(49)]);
  }

  createFilterForm() {
    this.UserHolder = new UntypedFormGroup({
      title: this.title,
      firstName: this.firstName,
      lastName: this.lastName,
      userName: this.userName,
      email: this.email,
      mobileNo: this.mobileNo,
      telephoneNo: this.telephoneNo,
      companyName: this.companyName,
      password: this.password,
      resourceType: this.resourceType,
      isExternal: this.isExternal
    });
  }

  fillFilterControls() {
    if (this.userDetail != undefined) {
      this.title.setValue(this.userDetail.title);

      this.resourceType.setValue(this.userDetail.children);

      this.firstName.setValue(this.userDetail.firstname);
      this.lastName.setValue(this.userDetail.lastname);
      this.userName.setValue(this.userDetail.userName);
      this.email.setValue(this.userDetail.email);
      this.mobileNo.setValue(this.userDetail.mobileNo.replace(' ', ''));
      this.telephoneNo.setValue(this.userDetail.telephoneNo);
      this.companyName.setValue(this.userDetail.companyName);
      this.password.setValue('0');
      this.isExternal.setValue(this.userDetail.type);
    } else {
      this.title.setValue('');
      this.resourceType.setValue('');
      this.firstName.setValue('');
      this.lastName.setValue('');
      this.userName.setValue('');
      this.email.setValue('');
      this.mobileNo.setValue('');
      this.telephoneNo.setValue('');
      this.companyName.setValue('');
      this.password.setValue('');
      if (this.currentView == 'internal') this.isExternal.setValue(false);
      else this.isExternal.setValue('');
    }
  }

  getResourceTypes() {
    this.dataService.getResourceTypes().subscribe((val) => {
      this.resourceTypes = val;
    });
  }

  editDetails() {
    var user: ISettingsUserOut = {
      id: this.userId,
      title: this.title.value,
      userName: this.userName.value,
      lastName: this.lastName.value,
      firstName: this.firstName.value,
      email: this.email.value,
      mobileNo: this.mobileNo.value,
      telephoneNo: this.telephoneNo.value,
      companyName: this.companyName.value
    };

    if (this.usernames.includes(this.userName.value) && this.userDetail.userName != this.userName.value) {
      this.userNameFound = true;
      return;
    } else this.userNameFound = false;

    this.dataService.CreateUser(user).subscribe((val) => {
      // window.location.reload();
      this._snackBar('Details Saved');
    });
  }

  _snackBar(message: string) {
    this.snackbar.open(message, 'Ok', { duration: 5000 });
  }

  resetPassword() {
    Swal.fire({
      title: 'Reset Your Password',
      input: 'password',
      inputAttributes: {
        autocapitalize: 'off',
        autocorrect: 'off',
        placeholder: 'Enter your new password'
      },
      showCancelButton: true,
      confirmButtonText: 'Reset Password',
      showLoaderOnConfirm: true,
      preConfirm: (password) => {
        const passwordPattern = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+{}|:;<>?[\]\\\-='"/.,~`])[a-zA-Z0-9!@#$%^&*()_+{}|:;<>?[\]\\\-='"/.,~`]{8,}$/;

        if (!passwordPattern.test(password)) {
          Swal.showValidationMessage(`Password must be at least 8 characters long and include at least one letter, one number, and one special character.`);
          return false; // Ensures that the function returns false if the password doesn't meet the criteria
        }

        return password; // Return the password if it passes the validation
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        // Call changePassword with the valid password
        this.changePassword(result.value);

        // Show success message
        Swal.fire({
          title: 'Password Reset Successful',
          icon: 'success',
          text: 'Your password has been successfully reset.'
        });
      }
    });
  }

  changePassword(password: string) {
    this.dataService.ChangeUserPassword(this.userId, password).subscribe();
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.uploadProfilePicture(file);
    }
  }

  uploadProfilePicture(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    this.dataService.ChangeUserProfilePicture(formData, this.userId).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round((100 * event.loaded) / event.total);
        } else if (event instanceof HttpResponse) {
          // Handle success response
        }
      },
      (error) => {
        console.error('Upload failed:', error);
        // Handle error response
      }
    );
  }

  clientProfileLink() {
    const dialogRef = this.dialog.open(ClientProfileLinkdialogComponent, {
      disableClose: false,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {
        userId: this.userDetail.id,
        userName: this.userDetail.userName
      }
    });
  }

  rolesUserLink() {
    const dialogRef = this.dialog.open(RolesUserLinkdialogComponent, {
      disableClose: false,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {
        userId: this.userDetail.id,
        userName: this.userDetail.userName
      }
    });
  }

  ResourcesLink() {
    const dialogRef = this.dialog.open(ResourcesLinkdialogComponent, {
      disableClose: false,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: {
        userId: this.userDetail.id,
        userName: this.userDetail.userName,
        linkedResources: this.userDetail.children
      }
    });

    dialogRef.afterClosed().subscribe((data: any) => {
      this.getUsers();
    });
  }

  // #endregion
}
