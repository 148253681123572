import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DataService } from '@app/core/services/data-service';
import { HttpEventType } from '@angular/common/http';
import { catchError, mergeMap } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { IFileTag, IGenericTagFilters } from '@app/core/contracts/FileTag';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDocumentTypes } from '@app/core/contracts/documentTypes';
import Swal from 'sweetalert2';

export interface DialogData {
  PropertyFileStructure: [{ id: ''; name: '' }];
  LeaseId: string;
  LeaseNumber: string;
  Level: string;
  entity: string;
  entityDetail: string;
  select: [{ id: ''; name: '' }];
}

@Component({
  selector: 'app-save-dialog',
  templateUrl: './upload-lease-document.component.html',
  styleUrls: ['./upload-lease-document.component.scss']
})
export class UploadLeaseDocumentDialogComponent implements OnInit, OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();
  PropertyFileStructure: [{ id: ''; name: '' }];
  LeaseId: string;
  LeaseNumber: string;
  selectedvalues: [{ id: ''; name: '' }];
  step = 1;
  Level = '';
  AutoSelectedValue;
  AutoSelectedIndex;
  search!: string;
  fileTypes!: [IDocumentTypes];
  revisionNumber = 1;
  file: File | any = null;
  fileForm!: UntypedFormGroup;
  entity = '';
  entityDetail = '';
  filetype!: UntypedFormControl;
  filters!: [IGenericTagFilters];
  LeaseTag = '';
  url = '';
  Tags: [IFileTag] = [
    {
      isUrl: false,
      fileId: '',
      Url: '',
      TagId: '',
      value: '',
      friendlyName: ''
    }
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, private dataService: DataService, private dialogRef: MatDialogRef<UploadLeaseDocumentDialogComponent>) {
    this.PropertyFileStructure = data.PropertyFileStructure;
    this.LeaseId = data.LeaseId;
    this.LeaseNumber = data.LeaseNumber;
    this.Level = data.Level;
    this.entity = data.entity;
    this.entityDetail = data.entityDetail;
    this.selectedvalues = data.select;
    this.AutoSelectedValue = data.select;
    this.AutoSelectedIndex = this.PropertyFileStructure.findIndex((x) => x.id == data.select[0].id);

    if (this.Level == 'Pocket') {
      this.search = 'Lease';
      this.url = '/secure/properties/buildings/floors/pockets';
    } else if (this.Level == 'Property') {
      this.search = this.Level;
      this.url = '/secure/properties/list';
    } else if (this.Level == 'Building') {
      this.search = this.Level;
      this.url = '/secure/properties/buildings';
    } else if (this.Level == 'Floor') {
      this.search = this.Level;
      this.url = '/secure/properties/buildings/floors';
    }
  }

  ngOnInit() {
    this.loadDocumentTypes();
    this.createFormControls();
    this.createForm();
    this.GetFileUploadFilters();
    this.getLeaseTag();
  }

  createFormControls() {
    this.filetype = new UntypedFormControl();
  }

  createForm() {
    this.fileForm = new UntypedFormGroup({
      filetype: this.filetype
    });
  }

  loadDocumentTypes() {
    this.dataService.searchDocumentTypesByRespnsible(this.search, 'Drawing Manager ').subscribe((data) => {
      this.fileTypes = data;
    });
  }

  GetFileUploadFilters() {
    this.dataService.GetFileUploadFilters().subscribe((val) => {
      this.filters = val;
    });
  }

  fileTypeSelectionchanged(type: any) {
    this.revisionNumber = 1;
  }

  addFilter(tagTypeId: string, event: any, type: string) {
    var friendlyName;
    var activeFilter = this.Tags.find((x) => x.TagId == tagTypeId);
    if (activeFilter) {
      activeFilter.value = type == 'Text' ? event : event.value;
    } else {
      if (type == 'Dropdown') {
        var dropdownlist = this.filters.find((x) => x.tagId == tagTypeId);
        if (dropdownlist != undefined) {
          friendlyName = dropdownlist.data.find((x) => x.key == event.value)?.value;
        }
      } else if (type == 'Text') {
        friendlyName = event;
      } else {
        friendlyName = this.formatDate(new Date(event.value));
      }
      this.Tags.push({ isUrl: false, fileId: '', TagId: tagTypeId, Url: '', value: type == 'Text' ? event : event.value, friendlyName: friendlyName ? friendlyName : '' });
    }
  }

  formatDate(date: Date): String {
    if (date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${year}/${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}`;
    } else {
      return '';
    }
  }

  removeFiles() {
    this.file = null;
  }

  onFileSelected(event: any) {
    this.file = event.target.files[0];
  }

  getLeaseTag() {
    this.dataService.GetTagByName('Lease').subscribe((val) => {
      this.LeaseTag = val.id;
    });
  }

  onFileUpload() {
    let filetypeobj = this.fileTypes.find((x) => x.id == this.filetype.value);
    let Level = this.filters.find((x) => x.name == 'Level');
    if (this.file) {
      const formData = new FormData();
      formData.append('file', this.file);
      this.dataService
        .uploadFile(formData, this.revisionNumber, this.filetype.value)
        .pipe(
          mergeMap((model) => {
            if (model.type === HttpEventType.Response) {
              if (this.Tags[0].TagId == '') {
                this.Tags.splice(0, 1);
              }
              this.Tags.forEach((tag) => {
                tag.fileId = model.body?.id ? model.body?.id : '';
              });
              this.selectedvalues.forEach((pocket) => {
                var pocketId = pocket.id;
                var pocketName = pocket.name;

                this.Tags.push({
                  isUrl: true,
                  fileId: model.body?.id ? model.body?.id : '', // FileId
                  Url: this.url, // Property, building, Floor & pocket
                  TagId: '', // Hard coded Guid to change when URL based tags is developed
                  value: pocketId, // Id
                  friendlyName: pocketName // Name
                });
              });
              this.Tags.push({
                isUrl: false,
                fileId: model.body?.id ? model.body?.id : '', // FileId
                Url: '', // Property, building or Floor
                TagId: Level ? Level.tagId : '', // Hard coded Guid to change when URL based tags is developed
                value: this.Level, // Id
                friendlyName: this.Level // Name
              });
              if (this.Level == 'Pocket') {
                this.Tags.push({
                  isUrl: false,
                  fileId: model.body?.id ? model.body?.id : '', // FileId
                  Url: '', // Property, building or Floor
                  TagId: this.LeaseTag, // Hard coded Guid to change when URL based tags is developed
                  value: this.LeaseId, // Id
                  friendlyName: this.LeaseNumber // Name
                });
              }

              return this.CreateFileTags(this.Tags);
            }
            return of();
          }),
          catchError((error: any) => {
            let errorMessage = '';
            if (error.error instanceof ErrorEvent) {
              // client-side error
              errorMessage = `Error: ${error.error.message}`;
            } else {
              // server-side error
              errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
            }
            return 'e';
          })
        )
        .subscribe((x) => {
          if (x != 'e') {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'File Uploaded successfully',
              showConfirmButton: false,
              timer: 1500
            });
            this.removeFiles();
            this.Tags = [
              {
                isUrl: false,
                fileId: '',
                Url: '',
                TagId: '',
                value: '',
                friendlyName: ''
              }
            ];
            this.filetype.setValue('');

            // this.fileForm.reset();

            this.filetype.setErrors(null);
            this.dialogRef.close();
          }
        });
    }
  }

  CreateFileTags(Tags: IFileTag[]) {
    return this.dataService.addCustomTagsToFile(Tags);
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
