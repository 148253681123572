import { textAlign, columnTypes } from '../enums/columnSettings';
import { tenancyStatus } from '../enums/iconEnums';
import { ButtonSettings } from './buttonSettings';
import { ExpandSettings } from './expandSettings';
import { HoverSettings } from './hoverSettings';
import { IconSettings } from './iconSettings';
import { matOption } from './matOption';
import { NumberSettings } from './numberSettings';
import { SelectorSettings } from './selectorSettings';
import { wog_settings } from './wog';

type IconEnumType = 'tenancyStatus' | 'instructionStatus';
export class ColumnSettings {
  // This should be the same as what the object returns from C#
  columnName: string = '';

  // Specifies the type of data in the column
  // Types: button, date, hover, hyperlink, icon, matOption, number, text
  columnType?: columnTypes = columnTypes.text;

  // If left blank, defaults to columnName
  displayName?: string;

  // Aligns row data (defaults to left)
  align?: textAlign = textAlign.Left;

  // Settings for displaying an icon in the column
  icon?: IconSettings = new IconSettings();

  // settings for button
  buttonSettings?: ButtonSettings = new ButtonSettings();

  // Settings for formatting numbers in the column
  numberFormat?: NumberSettings = new NumberSettings();

  // Settings for expand button in the column
  expandSettings?: ExpandSettings = new ExpandSettings();

  // Settings for expand button in the column
  hoverSettings?: HoverSettings = new HoverSettings();

  // List of options to display for mat options
  matOptions?: matOption[] = [];

  selectorSettings?: SelectorSettings;

  // Lookup into an enum for displaying an icon instead of text
  iconEnum?: string;

  // Custom CSS style for the column header
  headerStyle?: string = '';

  // Custom CSS style for the row element
  elementStyle?: string = '';

  // Work on grid settings
  wog_settings?: wog_settings;

  // Function to apply conditional CSS styles to row elements based on row data
  conditionalElementStyle?: (rowData: any) => string = (rowData: any) => '';

  // Function to apply conditional CSS styles to column headers based on row data
  conditionalHeaderStyle?: (rowData: any) => string = (rowData: any) => '';

  constructor(options: Partial<ColumnSettings> = {}) {
    Object.assign(this, options);
    if (this.displayName?.toLowerCase() == 'empty') {
      this.displayName = '';
    } else if (!this.displayName) {
      this.displayName = this.columnName;
    }
  }
}

/*

## Example of how to use the conditional styles

conditionalHeaderStyle: (columnSettings: any) => {
  // Example: Set header color to red if the building name contains "building"

  if (columnSettings.columnName.includes('building')) {
    return 'color: red;';
  } else {
    return ''; // Return empty string for default style
  }
},
conditionalElementStyle: (rowData: any) => {
  // Example: Set element color to red if the building name contains "1 NORTH WHARF SQUARE"

  if (rowData.includes('1 NORTH WHARF SQUARE')) {
    return 'color: red;';
  } else {
    return ''; // Return empty string for default style
  }
}

## Events
  (hyperlinkClicked)="changeUrl($event)"
  (matOptionClicked)="matOptionClicked($event)"
  (iconClicked)="viewDetails($event)"
*/
