import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-hyperlink-column',
  templateUrl: './hyperlink-column.component.html',
  styleUrls: ['./hyperlink-column.component.scss']
})
export class HyperlinkColumnComponent implements OnInit, OnChanges {
  @Input() valueIn: string = '';
  @Input() elementIn: string = '';
  @Output() hyperlinkClicked = new EventEmitter<any>();
  clicked: boolean = false;
  value!: string;

  constructor() {}

  toggleClicked(value: any) {
    this.clicked = true;
    this.hyperlinkClicked.emit({ value: value, element: this.elementIn });
  }

  ngOnInit() {
    this.value = this.valueIn;
  }

  ngOnChanges() {
    this.value = this.valueIn;
  }
}
