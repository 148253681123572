 <!-- Text Column -->
<div [class]="column.align === textAlign.Left?'flex-left':column.align === textAlign.Right?'flex-right':'flex-center'"
  [style]="getElementStyle(element,column)">

  <app-expand-column *ngIf="column.columnType === columnTypes.expand"
    [elementIn]="element"
    [expandSettingsIn]="column.expandSettings"
    [tableSettingsIn]="tableSettings"
    [currentLevel]="currentLevel"
    (rowExpand)=" this.rowExpand.emit()">
  </app-expand-column>
  <app-text-column *ngIf="column.columnType === columnTypes.text"
    [valueIn]="element[column.columnName]">
  </app-text-column>
  <!-- Icon Column -->
  <app-icon-column *ngIf="column.columnType === columnTypes.icon && column.icon"
    [currentLevel]="currentLevel"
    [iconIn]="column.icon"
    [elementIn]="element"
    (iconClicked)="this.iconClicked.emit($event)">
  </app-icon-column>
  <!-- Selector Column -->
  <app-selector-column *ngIf="column.columnType === columnTypes.selector && column.selectorSettings"
    [currentLevel]="currentLevel"
    [selectorIn]="column.selectorSettings"
    [elementIn]="element"
    [toggleSelected]="toggleSelected"
    [selectedIds]="selectedIds"
    (selectedChanged)="this.selectedChanged.emit($event)">
  </app-selector-column>
  <!-- Number Column -->
  <app-number-column *ngIf="column.columnType === columnTypes.number && column.numberFormat"
    [valueIn]="element[column.columnName]"
    [settingsIn]="column.numberFormat">
  </app-number-column>
  <!-- Mat Options Column -->
  <app-mat-options-column *ngIf="column.columnType === columnTypes.matOption && column.icon && column.matOptions"
    [iconIn]="column.icon"
    [elementIn]="element"
    [currentLevel]="currentLevel"
    [matOptions]="column.matOptions"
    (matOptionClicked)="this.matOptionClicked.emit($event)">
  </app-mat-options-column>
  <!-- Button Column -->
  <app-button-column *ngIf="column.columnType === columnTypes.button"
    [valueIn]="element[column.columnName]"
    [elementIn]="element"
    [buttonSettings]="column.buttonSettings"
    [currentLevel]="currentLevel"
    (buttonClicked)="this.buttonClicked.emit($event)">
  </app-button-column>
  <!-- Hover Column -->
  <app-hover-column *ngIf="column.columnType === columnTypes.hover"
    [hoverTextIn]="element[column.hoverSettings.columnName]"
    [valueIn]="element[column.columnName]">
  </app-hover-column>
  <!-- Hyperlink Column -->
  <app-hyperlink-column *ngIf="column.columnType === columnTypes.hyperlink"
    [valueIn]="element[column.columnName]"
    [elementIn]="element"
    (hyperlinkClicked)=" this.hyperlinkClicked.emit($event)">
  </app-hyperlink-column>
  <!-- Icon Enum -->
  <app-icon-enum-column *ngIf="column.columnType === columnTypes.iconEnum"
    [valueIn]="element[column.columnName]"
    [iconEnumType]="column?.iconEnum"
    [elementIn]="element"
    (iconClicked)="this.iconClicked.emit($event)">
  </app-icon-enum-column>
  <!-- Mat Dropdown Column -->
  <app-mat-dropdown-column *ngIf="column.columnType === columnTypes.matDropdown && column.matOptions"
    [valueIn]="element[column.columnName]"
    [currentLevel]="currentLevel"
    [elementIn]="element"
    [matOptions]="column.matOptions"
    (matOptionClicked)=" this.matOptionClicked.emit($event)">
  </app-mat-dropdown-column>

  <app-wog-text-field-column class="wog-container" *ngIf="column.columnType === columnTypes.wog_textField && column.wog_settings"
    [elementIn]="element"
    [columnIn]="column"
    [wog_settingsIn]="column.wog_settings"
    [currentLevel]="currentLevel"
    [refreshBit]="refreshBit"
    (wog_textFieldChanged)="wog_textFieldChanged.emit($event)">
  </app-wog-text-field-column>

  <app-wog-numeric-field-column *ngIf="column.columnType === columnTypes.wog_numericField && column.numberFormat&& column.wog_settings"
    [elementIn]="element"
    [columnIn]="column"
    [wog_settingsIn]="column.wog_settings"
    [currentLevel]="currentLevel"
    [settingsIn]="column.numberFormat"
    [refreshBit]="refreshBit"
    (wog_numericFieldChanged)="wog_numericFieldChanged.emit($event)">
  </app-wog-numeric-field-column>

  <app-wog-dropdown-column class="wog-container" *ngIf="column.columnType === columnTypes.wog_dropdown&& column.wog_settings"
    [elementIn]="element"
    [parentData]="parentData"
    [columnIn]="column"
    [wog_settingsIn]="column.wog_settings"
    [currentLevel]="currentLevel"
    (wog_dropDownSelectionChanged)="wog_dropDownSelectionChanged.emit($event)">
  </app-wog-dropdown-column>
  <!-- Disable refresh for dropdown bugs out -->
  <!-- [refreshBit]="refreshBit" -->

</div>
