// iframe.component.ts
import { Component, OnInit } from '@angular/core';
import { IframeService } from '@app/core/services/IframeService';
import { Observable, ReplaySubject, take } from 'rxjs';

@Component({
  selector: 'app-iframe',
  template: ` <iframe src="http://localhost:4200/" width="800" height="600" title="Google"></iframe>`
  
})
// template: `<iframe *ngIf="showIframe"
//   #iframe
//   (load)="onIframeLoad(iframe)"
// ></iframe>`
// template: ` <iframe *ngIf="showIframe" [innerHtml]="iframeService.websiteUrl" width="100%" height="800"></iframe> `
export class IframeComponent  {
  websiteURL: any;
  showIframe: boolean = true;
  iframeDoc$ = new ReplaySubject<any>(1);

  constructor(public iframeService: IframeService) {
    this.iframeService.openIframe();
  }
  onIframeLoad(iframe: any): void {
    this.iframeDoc$.next(iframe.contentDocument || iframe.contentWindow);
  }

  getIframeDoc(): Observable<any> {
    return this.iframeDoc$.asObservable();
 }
 
 yourFunctionForChangingHTML(): void {
   this.getIframeDoc().pipe(take(1)).subscribe(iframeDoc => {
      iframeDoc.open();
      iframeDoc.write(this.iframeService.websiteUrl);
   });
 }
}
