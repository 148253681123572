import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { tenancyStatus, iconProperties, instructionStatus, IconPropertyKey } from '../../../enums/iconEnums';

@Component({
  selector: 'app-icon-enum-column',
  templateUrl: './icon-enum-column.component.html',
  styleUrls: ['./icon-enum-column.component.scss']
})
export class IconEnumColumnComponent implements OnInit, OnChanges {
  @Input() valueIn: string | boolean = '_blank';
  @Input() elementIn: string = '';
  @Input() iconEnumType!: '';
  @Output() iconClicked = new EventEmitter<any>();

  clicked: boolean = false;
  value!: string;
  currentIconDetail!: { icon: string; hoverText: string; color: string };
  errorState = false;

  constructor() {}

  toggleClicked() {
    this.iconClicked.emit({ option: this.iconEnumType, element: this.elementIn, value: this.value });
  }

  ngOnInit() {
    this.value = this.valueIn.toString();
    this.updateIconDetails();
  }

  ngOnChanges() {
    if (this.valueIn == true) {
      this.valueIn = 'true';
    } else if (this.valueIn == false) {
      this.valueIn = 'false';
    } else if (this.valueIn == undefined || this.valueIn == null || this.valueIn == '') {
      this.valueIn = '_blank';
    }
    this.value = this.valueIn.toString();
    this.updateIconDetails();
  }

  private updateIconDetails() {
    // Assuming 'value' is directly usable as a key after replacement of spaces,
    // and is correctly cased as defined in your enums:
    const enumKey: IconPropertyKey | undefined = this.normalizeValue(this.value) as IconPropertyKey;

    if (enumKey && enumKey in iconProperties) {
      this.currentIconDetail = iconProperties[enumKey];
    } else {
      this.errorState = true;
      console.error('Invalid status:', this.normalizeValue(this.value));
      // Optionally set a default icon detail here
    }
  }

  normalizeValue(value: string) {
    return value.replace(/ /g, '').replace('(', '').replace(')', '').replace('-', '').replace('–', '').replace('/', '');
  }
}
