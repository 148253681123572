
<ng-container *ngIf="readOnly === false">
  <select [(ngModel)]="this.elementIn[this.columnIn.columnName]"
    (ngModelChange)="changed($event)"
    *ngIf="wog_settingsIn.level?.includes(this.currentLevel)" class="dropdown" name="cars"
    id="cars">
    <option [value]="''"> </option>
    <option *ngFor="let option of options; let i= index " [value]="option.key">{{option.value}}</option>
  </select>
</ng-container>

<ng-container *ngIf="!wog_settingsIn.level?.includes(this.currentLevel) || (readOnly === true) ">
  {{this.elementIn[this.columnIn.columnName]}}
</ng-container>
