import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgPipesModule } from 'ngx-pipes';
import { QuillModule } from 'ngx-quill';
import { CustomCurrencyPipe } from './components/custom-pipes/currency-pipe';
import { CustomDatePipe } from './components/custom-pipes/date-pipe';
import { NumberFormatterDirective } from './components/numberFormatterDirective/number-formatter.directive';
import { material, charts, sharedPipes, sharedComponents, sharedDialogs } from './shared.declarations';
import { CustomDecimalPipe } from './components/custom-pipes/decimal-pipe';
import { TooltipFormatterPipe } from './components/custom-pipes/tooltip-pipe';
import { CustomTableTooltipComponent } from './components/tree-table/components/tooltip/custom-table-tooltip.component';
import { TooltipDialogComponent } from './components/tree-table/components/tooltip/tooltip-dialog/tooltip-dialog.component';
import { CustomTooltipComponent } from './components/customTooltip/custom-tooltip.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  imports: [
    RouterModule,
    DragDropModule,
    CommonModule,
    FlexLayoutModule,
    NgPipesModule,
    ...material,
    ...charts,
    FormsModule,
    NgxFileDropModule,
    ReactiveFormsModule,
    NgxChartsModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          ['blockquote'],
          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
          [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
          [{ direction: 'rtl' }], // text direction
          [{ size: ['8px', false, '24px', '32px', '54px'] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],
          ['clean'], // remove formatting button
          ['link', 'image', 'video'] // link and image, video
        ],
        keyboard: {
          bindings: {
            // Remove Tab
            tab: {
              key: 9,
              handler: function () {
                // do nothing
              }
            },
            // Remove Shift + Tab
            'remove tab': {
              key: 9,
              shiftKey: true,
              collapsed: true,
              prefix: /\t$/,
              handler: function () {
                // do nothing
              }
            }
          }
        }
      }
    })
  ],
  declarations: [
    ...sharedPipes,
    ...sharedComponents,
    ...sharedDialogs,
    CustomDecimalPipe,
    TooltipFormatterPipe,
    CustomCurrencyPipe,
    NumberFormatterDirective,
    CustomDatePipe,
    CustomTooltipComponent,
    CustomTableTooltipComponent,
    TooltipDialogComponent
  ],

  exports: [
    CommonModule,
    NgxFileDropModule,
    FlexLayoutModule,
    NgPipesModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule,
    ...material,
    ...charts,
    ...sharedPipes,
    ...sharedComponents,
    ...sharedDialogs,
    CustomDecimalPipe,
    CustomCurrencyPipe,
    NumberFormatterDirective,
    CustomDatePipe,
    NgxChartsModule
  ]
})
export class SharedModule {}
