<!--
<button
  class="button"
  *ngIf="buttonSettings.displayOnLevels?.includes(currentLevel)"
  (click)="toggleClicked(buttonSettings.clickResponse)"
  [style]="'color:'+ buttonSettings.color+'; background-color:'+buttonSettings.backgroundColor">
  <mat-icon *ngIf="buttonSettings.materialIcon">{{buttonSettings.materialIcon}}</mat-icon>
  {{buttonSettings.text}}
</button> -->

<button
  class="button-1"
  *ngIf="buttonSettings.displayOnLevels?.includes(currentLevel)"
  (click)="toggleClicked(buttonSettings.clickResponse)">
  <mat-icon *ngIf="buttonSettings.materialIcon">{{buttonSettings.materialIcon}}</mat-icon>
  {{buttonSettings.text}}
</button>
