import { KeyValue } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ILoginIn, ILogin, IForgotPasswordIn, IResetPasswordIn } from '../contracts/accounts';
import { IActivityLogFilter, IActivityLogGroup } from '../contracts/activityLogs';
import {
  ITenantAmendmentsIn,
  ILeaseTermAmendmentsIn,
  ITenantAmendmentOut,
  ILeaseTermAmendmentOut,
  IAmendmentStatusChangeOut,
  IPartialExtensionOut,
  IPartialVacateOut,
  IPartialVacateIn,
  IPartialParkingVacateIn,
  IContactAmendmentsIn,
  IContactAmendmentOut
} from '../contracts/amendment';
import { IBuildingNestedMenu, IBuildingFilter, IBuilding, IBuildingSelection, IBuildingIn, IBuildingFloors, IBuildingFloorsIn } from '../contracts/building';
import { IBusinessUnit } from '../contracts/businessUnit';
import { IClientProfile, IClientProfileDeleteWarnings } from '../contracts/clientprofile';
import { IPagedResult } from '../contracts/common';
import {
  IBasicDIRInfo,
  IDIRProperty,
  IDIRPremisesSelection,
  IDIRScopeOut,
  IDirFiles,
  IDIRStatuses,
  IArchivedVersionsFilter,
  IArchivedVersions,
  IDIRInformation,
  IDirFilter,
  IDir,
  IDirTypes,
  IDirProperty,
  IDIRParkingSelection
} from '../contracts/dir';
import {
  IChecklistPack,
  IChecklistElement,
  IChecklistResourceType,
  IChecklistDocumentType,
  IChecklistProjectDocumentType,
  IChecklistFile,
  IChecklistProjectDocumentTypeOut,
  IChecklistUpload
} from '../contracts/documentChecklist';
import { IEmailDocumentFilesOut, IDocumentTypes, IDocumentProperties } from '../contracts/documentTypes';
import { IEProperty } from '../contracts/eportfolio';
import { IFile, IFileFilter, IFileAdvancedIn, IFileStatus, IFileStatusChange, IGenericDocumentGroup } from '../contracts/file';
import { IFileTag, IFileTagIn, ITag } from '../contracts/FileTag';
import { IFloorIn, IFloor } from '../contracts/floor';
import { ILandlord } from '../contracts/landlord';
import {
  ILeaseActionLogIn,
  ILeaseFinacials,
  ILeaseParking,
  ILeaseParkingOut,
  ILeaseDetails,
  ILeaseDetailsOut,
  ILeaseScope,
  ILeaseScopeOut,
  ILeaseTenant,
  ILeaseTenantOut,
  IBasicLeaseInfo,
  ILeaseProject,
  ILeaseProperty,
  ITcnMigrateData,
  IMigrateTcnLeaseGrid,
  ILeaseObj,
  ILeaseGridIn
} from '../contracts/lease';
import { ISelectedPocketDisplay } from '../contracts/LeaseScopeAndTerm';
import { ILeaseType } from '../contracts/leaseType';
import { IMailVariables, INotificationTrigger, IMailTemplate, IUserEmailIn } from '../contracts/mails';
import { MailTreeIn, IMailUser } from '../contracts/mailTree';
import { IPack } from '../contracts/pack';
import { IEPropertyParking, IParkingTypes, IParkingLCSRestrictions, IBasicPCSInfo, IPCSList, IPCSDetails, IPendingParkingProperty } from '../contracts/parking';
import { IRoleIn } from '../contracts/permissions';
import { SapoaType } from '../contracts/pocket';
import {
  IPMEvent,
  IPMJob,
  IPMJobOut,
  IPMStep,
  IPMStepOut,
  IPMSchedule,
  IPMScheduleOut,
  IPMDailyFrequency,
  IPMDailyFrequencyOut,
  IPMWeeklyFrequency,
  IPMWeeklyFrequencyOut,
  IPMMonthlyFrequency,
  IPMUnitType,
  IPMUnitTypeOut,
  IPMAPIs,
  IPMAPIsOut,
  IPMQuery,
  IPMQueryOut
} from '../contracts/processManager';
import {
  IProjectObj,
  IProjectFinacial,
  IProjectMilestone,
  IProjectEntity,
  IProjectHeader,
  IProjectType,
  IInstructionType,
  IProjectMilestoneTemplate,
  IProjectInvoice,
  IProjectFinacialOut,
  IProjectGrid,
  IProjectDocumentStatuses,
  IProjectResources,
  IProjectPackDocument,
  IProjectDocumentIn,
  IProjectScope,
  IProjectFinancialType
} from '../contracts/project';
import { IProperty, IPropertyTakeOnFilter, IPropertyNames, IPropertyLeaseInfo, IPropertyFilter, IPropertyIn, IPortfoliosPending, IPendingView, IPropertyDetailsSummary } from '../contracts/property';
import { PropertyResourceTypes } from '../contracts/propertyResourceTypes';
import { IPropertySummary, IPendingPropertySummary, IPremisesSummary } from '../contracts/propertySummaryData';
import { IPtoFiles, IBasicPtoInfo, IPtoFilter, IPtoIn, IPtoPropertyResourcesIn, IPtoPropertyDetailOut, IPtoPropertyResourcesOut, IPtoPropertyDetailIn } from '../contracts/pto';
import { IRegion } from '../contracts/region';
import { ITenantLifeCycle } from '../contracts/reports';
import { IResourceDetails, IResourceTypeUser } from '../contracts/resource';

import { ISAPOAType } from '../contracts/sapoaType';
import { IScopeOfWork } from '../contracts/scope';
import {
  ISettingsUserOut,
  IProjectUserOut,
  ISettingsDocumentTypes,
  ISettingsDocumentTypesSimple,
  ISettingsDocumentResources,
  ISettingsDocumentTypesSave,
  ISettingsRole,
  ISettingsRolePermisions,
  ISettingsMilestoneTemplate,
  ISettingsResourceOut,
  ISettingsResourceType,
  ISettingsFinancials
} from '../contracts/settings';
import { ITagSettings } from '../contracts/tag';
import { ITcnActionLogIn, ITCNLeasesInfo, ITCNSummary, IBasicTCNInfo } from '../contracts/tcn';
import { ITCNInformation } from '../contracts/tcnInformation';
import { ITCNType } from '../contracts/tcnType';
import { ITenant, ITenantContact } from '../contracts/tenant';
import { IPropNode } from '../contracts/TreeNode';
import { IUnitUsage } from '../contracts/unitUsage';
import { IRole, IUserContext, User, UserAccountDetails, UserSettings } from '../contracts/users';
import { IMeasurementModel } from '../contracts/measurementModel';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(private http: HttpClient) {}

  public loginUser(credentials: ILoginIn) {
    return this.http.post<ILogin>('accounts/login', JSON.stringify(credentials));
  }

  public userSwitch(userId?: string) {
    return this.http.post<ILogin>('accounts/login/switch', JSON.stringify(userId));
  }

  public ExternalLoginUser(credentials: ILoginIn) {
    return this.http.post<ILogin>('accounts/login/external', JSON.stringify(credentials));
  }

  public refeshToken(refreshToken: string) {
    return this.http.get<ILogin>('accounts/token/refresh/' + refreshToken);
  }

  public forgotPassword(forgot: IForgotPasswordIn) {
    return this.http.post<boolean>('accounts/forgot', JSON.stringify(forgot));
  }
  public resetPassword(reset: IResetPasswordIn) {
    return this.http.post<boolean>('accounts/reset', JSON.stringify(reset));
  }

  public getUserContext() {
    return this.http.get<IUserContext>('users/context');
  }

  public getSwitchedUserContext(userId: string) {
    return this.http.get<IUserContext>(`users/context/switch/${userId}`);
  }

  public getPingResult() {
    return this.http.head<void>('users/ping');
  }
  public CreateUser(user: ISettingsUserOut) {
    return this.http.post<string>(`users/create`, user);
  }

  public SwitchLockedUser(userId: string, isLocked: boolean) {
    return this.http.post<boolean>(`users/${userId}/locked/${isLocked}`, {});
  }

  public SwitchActiveUser(userId: string, isActive: boolean) {
    return this.http.post<boolean>(`users/${userId}/active/${isActive}`, {});
  }

  public ChangeUserPassword(userId: string, password: string) {
    return this.http.post<boolean>(`users/${userId}/password/${password}`, {});
  }

  public ChangeUserProfilePicture(formData: FormData, userId: string) {
    return this.http.post<IFile>(`document/profilepicture/${userId}`, formData, {
      headers: new HttpHeaders({ 'no-header': 'true' }),
      reportProgress: true,
      observe: 'events'
    });
  }

  public DownloadProfilePictureFile(id: string) {
    return this.http.get(`document/profilePicture/${id}`, { responseType: 'blob' });
  }

  public DownloadImageFromPath(path: string) {
    return this.http.get(`document/imagefrompath/${encodeURIComponent(path)}`, { responseType: 'blob' });
  }

  public CreatePrincipal(user: IProjectUserOut) {
    return this.http.post<boolean>(`users/create/principal`, user);
  }

  //#region Property

  public uploadImportData(formData: FormData, propertyId: string, importType: number) {
    return this.http.post<IFile>(`document/uploadDataPush/${propertyId}/${importType}`, formData, {
      headers: new HttpHeaders({ 'no-header': 'true' }),
      reportProgress: true,
      observe: 'events'
    });
  }

  public getEPropertySummary(propertyId: string) {
    return this.http.get<IPropertyDetailsSummary>(`properties/eportfolio/summary/${propertyId}`);
  }

  public syncToForceLink(propertyId: string) {
    return this.http.get<boolean>(`settings/forcelink/sync/${propertyId}`);
  }

  public getProperty(id: string) {
    return this.http.get<IProperty>(`properties/${id}`);
  }

  public getEProperty(id: string) {
    return this.http.get<IEProperty>(`properties/eportfolio/${id}`);
  }

  public getAllEProperty() {
    return this.http.get<[IEProperty]>(`properties/eportfolio`);
  }

  public saveEProperty(property: IEProperty) {
    return this.http.post<IEProperty>(`properties/eportfolio`, property);
  }

  public getPropertyTakeOn(id: string) {
    return this.http.get<[IPropertyTakeOnFilter]>(`properties/takeon/${id}`);
  }

  public PropertyTakeOn(id: string) {
    return this.http.post<any>(`properties/takeon/${id}`, {});
  }

  public getPropertyNames() {
    return this.http.get<IPropertyNames[]>(`properties/names`);
  }

  public getPropertyLeaseInfo() {
    return this.http.get<IPropertyLeaseInfo[]>(`properties/lease/select`);
  }

  public searchProperties(propertyFilter: IPropertyFilter, pageNo: number, pageSize: number, sortBy: string, sortDir: string, all: string) {
    return this.http.post<IPagedResult<IProperty>>(`properties/search?pageno=${pageNo}&pagesize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}&all=${all}`, JSON.stringify(propertyFilter));
  }

  public createProperty(property: IPropertyIn) {
    return this.http.post<boolean>(`properties`, JSON.stringify(property));
  }

  public updateProperty(property: IPropertyIn) {
    return this.http.put<boolean>(`properties`, JSON.stringify(property));
  }

  public changePropertyStatus(propertyId: string, status: number) {
    return this.http.post<boolean>(`properties/${propertyId}/changestatus/${status}`, {});
  }

  public propertyStructureReport(propertyId: string) {
    return this.http.get<any>(`properties/report/${propertyId}`, {});
  }

  public uploadPropertyPic(formData: FormData, propertyId: string) {
    return this.http.post<IFile>(`document/propertypicture/${propertyId}`, formData, {
      headers: new HttpHeaders({ 'no-header': 'true' }),
      reportProgress: true,
      observe: 'events'
    });
  }
  //VIEWS

  public getLandlords() {
    return this.http.get<[ILandlord]>(`properties/landlords`);
  }

  //#endregion Country

  //#region Owner

  //#endregion Owner

  //#region Lease
  //LCS

  public processLeases() {
    return this.http.post<[ILeaseActionLogIn]>(`lcs/processleases`, {});
  }

  public getLeaseLogs(leaseId: string) {
    return this.http.get<[ILeaseActionLogIn]>(`lcs/${leaseId}/actionLogs`);
  }

  public getLeaseExportData(leaseId: string) {
    return this.http.get<any>(`lcs/${leaseId}/export`);
  }

  public createProjectFromLease(LeaseId: string, projectNumber: any) {
    return this.http.post<any>(`lcs/${LeaseId}/createproject`, projectNumber);
  }

  public getLeaseSpecialConditions(LeaseId: string) {
    return this.http.get<any>(`lcs/${LeaseId}/leasespecialconditions`);
  }

  public saveLeaseSpecialConditions(comments: string, leaseId: string) {
    return this.http.post<boolean>(`lcs/save/leasespecialconditions/${leaseId}`, JSON.stringify(comments));
  }

  public getLeaseFinancials(LeaseId: string) {
    return this.http.get<[ILeaseFinacials]>(`lcs/${LeaseId}/leasefinancials`);
  }

  public saveLeaseFinancials(leasefinancials: [ILeaseFinacials]) {
    return this.http.post<boolean>(`lcs/save/leasefinancials`, leasefinancials);
  }

  public getLeaseParking(LeaseId: string) {
    return this.http.get<[ILeaseParking]>(`lcs/${LeaseId}/leaseparking`);
  }

  public saveLeaseParking(leaseparking: ILeaseParkingOut[], leaseId: string) {
    return this.http.post<boolean>(`lcs/save/leaseparking/${leaseId}/`, leaseparking);
  }

  public getLeaseDetails(LeaseId: string) {
    return this.http.get<ILeaseDetails>(`lcs/${LeaseId}/leasedetail`);
  }

  public saveLeaseDetails(leasedetail: ILeaseDetailsOut) {
    return this.http.post<boolean>(`lcs/save/leasedetail`, leasedetail);
  }

  public getLeaseScope(LeaseId: string) {
    return this.http.get<[ILeaseScope]>(`lcs/${LeaseId}/leasescope`);
  }

  public saveLeaseScope(leaseScope: ILeaseScopeOut[]) {
    return this.http.post<boolean>(`lcs/save/leasescope`, leaseScope);
  }

  public getLeaseTenant(LeaseId: string) {
    return this.http.get<ILeaseTenant>(`lcs/${LeaseId}/leasetenant`);
  }

  public saveLeaseTenant(leaseTenant: ILeaseTenantOut) {
    return this.http.post<boolean>(`lcs/save/leasetenant`, leaseTenant);
  }

  public getBasicLeaseInfo(LeaseId: string) {
    return this.http.get<IBasicLeaseInfo>(`lcs/${LeaseId}/basicinfo`);
  }

  public hasLinkedRenewal(leaseId: string) {
    return this.http.get<ILeaseProject>(`lcs/${leaseId}/hasLinkedRenewal`);
  }

  public hasLinkedTCN(leaseId: string) {
    return this.http.get<boolean>(`lcs/${leaseId}/hasLinkedTCN`);
  }

  public saveLeaseProperty(LeaseProperty: ILeaseProperty) {
    return this.http.post<string>(`lcs/save/leaseproperty`, LeaseProperty);
  }

  public getLeaseProperty(LeaseId: string) {
    return this.http.get<string>(`lcs/${LeaseId}/leaseproperty`);
  }

  public getMigrateData() {
    return this.http.get<any>(`lcs/migratedata`);
  }

  public geTcnMigrateData() {
    return this.http.get<[ITcnMigrateData]>(`lcs/tcn/migratedata`);
  }

  public getTcnMigrationLeases(tcnPropertyName: string, tcnTenantName: string) {
    return this.http.get<[IMigrateTcnLeaseGrid]>(`lcs/migrate/tcn/migratedata/${tcnPropertyName}/${tcnTenantName}`);
  }

  public MigrateLease(Ids: [number]) {
    return this.http.post<boolean>(`lcs/migrate`, JSON.stringify(Ids));
  }

  public MigratePropertiesLease(LeaseNumbers: [string]) {
    return this.http.post<boolean>(`lcs/migratedata/properties`, JSON.stringify(LeaseNumbers));
  }

  public MigrateLeaseTcn(MigrateObj: any) {
    return this.http.post<boolean>(`lcs/tcn/migrate`, JSON.stringify(MigrateObj));
  }

  public getLease(id: string) {
    return this.http.get<ILeaseObj>(`lcs/${id}`);
  }

  public changeLCSStatus(lcsId: string, status: string) {
    return this.http.post<boolean>(`lcs/${lcsId}/changestatus/${status}`, {});
  }

  public searchLeases(filters: any) {
    return this.http.post<[ILeaseGridIn]>(`lcs/search`, JSON.stringify(filters));
  }

  public getBusinessUnits() {
    return this.http.get<[IBusinessUnit]>(`lcs/businessunits`);
  }

  public getTenants() {
    return this.http.get<[ITenant]>(`lcs/tenants`);
  }

  public getTenantContacts(tenantId: string) {
    return this.http.get<[ITenantContact]>(`lcs/tenant/${tenantId}/contacts`);
  }

  public getFinancialYears() {
    return this.http.get<any>(`lcs/finacials/years`);
  }

  public getLeaseTypes() {
    return this.http.get<ILeaseType[]>(`lcs/leasetypes`);
  }

  public createRenewalFromLease(leaseId: string, renewalType: number) {
    return this.http.post<string>(`lcs/renewalfromlease/${leaseId}/${renewalType}`, {});
  }

  public changePtoStatus(ptoId: string, statusCode: string) {
    return this.http.put<boolean>(`pto/status/${ptoId}/${statusCode}`, {});
  }

  //AMENDMENT

  public getLeaseContactAmendments(lcsId: string) {
    return this.http.get<IContactAmendmentsIn[]>(`lcs/lease/contactamendments/${lcsId}`);
  }

  public getLeaseTenantAmendments(lcsId: string) {
    return this.http.get<ITenantAmendmentsIn[]>(`lcs/lease/${lcsId}/tenant/amendment`);
  }

  public GetLeaseTermAmendments(lcsId: string) {
    return this.http.get<ILeaseTermAmendmentsIn[]>(`lcs/lease/${lcsId}/leaseTerm/amendment`);
  }

  public AddEditTenantAmendment(tenantAmendment: ITenantAmendmentOut) {
    return this.http.post<boolean>(`lcs/lease/tenant/amendment/save`, JSON.stringify(tenantAmendment));
  }

  public AddEditLeaseTermAmendment(leaseTermAmendment: ILeaseTermAmendmentOut) {
    return this.http.post<boolean>(`lcs/lease/leaseTerm/amendment/save`, JSON.stringify(leaseTermAmendment));
  }

  public CreateLeaseContactAmendment(leaseContactAmendment: IContactAmendmentOut) {
    return this.http.post<boolean>(`lcs/lease/contact/amendment`, JSON.stringify(leaseContactAmendment));
  }

  public LeaseCreatePartialVacate(leaseTermAmendment: IPartialVacateOut) {
    return this.http.post<boolean>(`lcs/lease/partial/vacate`, JSON.stringify(leaseTermAmendment));
  }

  public LeaseCreatePartialExtension(leaseTermAmendment: IPartialExtensionOut) {
    return this.http.post<boolean>(`lcs/lease/partial/extension`, JSON.stringify(leaseTermAmendment));
  }

  public GetLeasePartialVacate(lcsid: string) {
    return this.http.get<IPartialVacateIn[]>(`lcs/lease/partial/vacate/${lcsid}`);
  }

  public GetLeasePartialParkingVacate(lcsid: string) {
    return this.http.get<IPartialParkingVacateIn[]>(`lcs/lease/partial/parking/vacate/${lcsid}`);
  }

  public LeaseTermAmendmentStatusChange(leaseTermAmendmentStatus: IAmendmentStatusChangeOut) {
    return this.http.put<boolean>(`lcs/lease/leaseTerm/amendment/status`, JSON.stringify(leaseTermAmendmentStatus));
  }

  public LeaseTenantAmendmentStatusChange(leaseTenantAmendmentStatus: IAmendmentStatusChangeOut) {
    return this.http.put<boolean>(`lcs/lease/tenant/amendment/status`, JSON.stringify(leaseTenantAmendmentStatus));
  }

  //PTO
  public createPtoPropertyAndResources(ptoId: string, ptoHierarchyParentId: string) {
    let optionalUrlParam = ptoHierarchyParentId ? `?hierarchyParent=${ptoHierarchyParentId}` : '';
    return this.http.post<boolean>(`pto/create/property/resources/${ptoId}${optionalUrlParam}`, {});
  }

  public deletePtoDocument(ptoDocumentId?: string) {
    return this.http.delete<boolean>(`pto/remove/documents/${ptoDocumentId}`);
  }

  public gePtoRDocuments(ptoId?: string) {
    return this.http.get<IPtoFiles[]>(`pto/documents/${ptoId}`);
  }

  public createPtoDocuments(files: IPtoFiles[], ptoId: string) {
    return this.http.post<boolean>(`pto/save/file/${ptoId}`, JSON.stringify(files));
  }

  public getBasicPtoLeaseInfo(LeaseId: string) {
    return this.http.get<IBasicPtoInfo>(`pto/${LeaseId}/basicinfo`);
  }

  public getPtoGridData(propertyFilter: IPtoFilter, pageNo: number, pageSize: number, sortBy: string, sortDir: string) {
    return this.http.post<IPagedResult<IPtoIn>>(`pto/search?pageno=${pageNo}&pagesize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`, JSON.stringify(propertyFilter));
  }

  public getPtoNumberFilter() {
    return this.http.get<any>(`pto/number/filter/options`);
  }

  public getPtoPropertyFilter() {
    return this.http.get<any>(`pto/property/filter/options`);
  }

  public getPTOPropertyResources(leaseId: string) {
    return this.http.get<IPtoPropertyResourcesIn>(`pto/lease/${leaseId}/property/resources`);
  }

  public savePTOInstructionDetail(propertyDetail: IPtoPropertyDetailOut) {
    return this.http.post<string>(`pto/save/instruction/detail`, propertyDetail);
  }

  public savePTOPropertyResources(ptoPropertyResources: IPtoPropertyResourcesOut) {
    return this.http.post<string>(`pto/save/property/resources`, ptoPropertyResources);
  }

  public getLeasePropertyDetails(leaseId?: string) {
    return this.http.get<IPtoPropertyDetailIn>(`pto/lease/${leaseId}/property/details`);
  }

  public getAllBusinessUnits() {
    return this.http.get<[IBusinessUnit]>(`pto/all/businessUnits`);
  }

  public getAllRegions() {
    return this.http.get<[IRegion]>(`pto/all/regions`);
  }

  public getAllLandLords() {
    return this.http.get<[ILandlord]>(`pto/all/landlords`);
  }

  public getAllMeasurementModels() {
    return this.http.get<[IMeasurementModel]>('pto/all/measurementModels');
  }
  //TCN

  public getTCNExportData(tcnId: string) {
    return this.http.get<any>(`tcn/${tcnId}/export`);
  }

  public getTCNPartialVacate(tcnId: string) {
    return this.http.get<any>(`tcn/partial/vacate/${tcnId}`);
  }

  public getTCNPartialParkingVacate(tcnId: string) {
    return this.http.get<any>(`tcn/partial/parking/vacate/${tcnId}`);
  }

  public createProjectFromTcn(tcnId: string, projectNumber: any) {
    return this.http.post<any>(`tcn/${tcnId}/createproject`, projectNumber);
  }
  public getTCNLogs(tcnId: string) {
    return this.http.get<[ITcnActionLogIn]>(`tcn/${tcnId}/actionLogs`);
  }

  public getTcnSpecialConditions(tcnId: string) {
    return this.http.get<any>(`tcn/${tcnId}/tcnpecialconditions`);
  }

  public saveTcnSpecialConditions(comments: string, tcnId: string) {
    return this.http.post<boolean>(`tcn/save/tcnpecialconditions/${tcnId}`, JSON.stringify(comments));
  }

  public addLeasestoTCN(id: string, leases: any[]) {
    return this.http.post<boolean>(`tcn/addlease/${id}`, leases);
  }

  public updateTCNChangedDate(id: string, changeDate: any) {
    return this.http.post<boolean>(`tcn/changedate/${id}`, changeDate);
  }

  public getTcnLeasesInfo(id: string) {
    return this.http.get<[ITCNLeasesInfo]>(`tcn/lcsinfo/${id}`);
  }

  public getTCNSummary(id: string) {
    return this.http.get<ITCNSummary>(`tcn/summary/${id}`);
  }

  public getTCNBasicInfo(id: string) {
    return this.http.get<IBasicTCNInfo>(`tcn/basicinfo/${id}`);
  }

  public createTCNFromLCS(lcsId: string, type: string) {
    return this.http.post<string>(`lcs/${lcsId}/tcn/${type}`, {});
  }

  public migrateTCN(lcsId: string, tcnNumber: string, type: string) {
    return this.http.post<string>(`lcs/${lcsId}/tcn/${tcnNumber}/${type}`, {});
  }

  public changeTCNStatus(tcnid: string, status: string) {
    return this.http.post<boolean>(`tcn/${tcnid}/changestatus/${status}`, {});
  }

  public getTCN(id: string) {
    return this.http.get<ITCNInformation>(`tcn/${id}`);
  }

  public getTcnType() {
    return this.http.get<[ITCNType]>(`tcn/tcntype/`);
  }

  public getLeaseId(leasenumber: string) {
    return this.http.get<string>(`lcs/leaseid/${leasenumber}`);
  }

  public getTcnId(leasenumber: string) {
    return this.http.get<string>(`tcn/leaseid/${leasenumber}`);
  }

  public getDIRBasicInfo(id: string) {
    return this.http.get<IBasicDIRInfo>(`dir/${id}/basicinfo`);
  }

  public getDIRProperty(dirId: string) {
    return this.http.get<string>(`dir/${dirId}/dirproperty`);
  }

  public saveDIRProperty(dirProperty: IDIRProperty) {
    return this.http.post<string>(`dir/save/dirproperty`, dirProperty);
  }

  public getDIRScope(dirId: string) {
    return this.http.get<[IDIRPremisesSelection]>(`dir/${dirId}/dirscope`);
  }

  public saveDIRScope(leaseScope: IDIRScopeOut) {
    return this.http.post<boolean>(`dir/save/ditrscope`, leaseScope);
  }

  public saveDIRSpecifications(Specifications: IDIRPremisesSelection[], pocketNumber: number) {
    return this.http.post<boolean>(`dir/save/ditrspecifications/${pocketNumber}`, Specifications);
  }

  public getDIRSpecialConditions(dirId: string) {
    return this.http.get<any>(`dir/${dirId}/dirspecialconditions`);
  }

  public saveDIRSpecialConditions(comments: string, dirId: string) {
    return this.http.post<boolean>(`dir/save/dirspecialconditions/${dirId}`, JSON.stringify(comments));
  }

  public saveDIRDocuments(files: IDirFiles[], dirId: string) {
    return this.http.post<boolean>(`dir/save/file/${dirId}`, files);
  }

  public getDIRDocuments(dirId: string) {
    return this.http.get<IDirFiles[]>(`dir/files/${dirId}`);
  }

  public removeDIRDocuments(fileId: string) {
    return this.http.get<IDirFiles[]>(`dir/file/remove/${fileId}`);
  }

  public AcceptDIR(dirId: string, validFrom: Date) {
    return this.http.post<boolean>(`dir/save/${dirId}/validfrom`, { validfrom: validFrom });
  }

  public getDirStatuses() {
    return this.http.get<IDIRStatuses[]>(`dir/status`);
  }

  public SavePreviewStructure(property: IEProperty, dirid: string) {
    return this.http.post<boolean>(`dir/save/previewstructure/${dirid}`, property);
  }

  //#endregion Lease

  //#region Buildings

  public GetBuildingNestedMenu(propertyId: string, key: string, value: string, useArchive: boolean) {
    return this.http.get<IBuildingNestedMenu[]>(`buildings/nestedmenu/${propertyId}/${key}/${value}/${useArchive}`);
  }
  public GetBuildingFloorTree(id: string, state: string, tenants: any[]) {
    return this.http.post<[IPropNode]>(`buildings/${id}/tree/${state}`, tenants);
  }

  public searchBuildings(buildingFilter: IBuildingFilter, pageNo: number, pageSize: number, sortBy: string, sortDir: string) {
    return this.http.post<IPagedResult<IBuilding>>(`buildings/search?pageno=${pageNo}&pagesize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`, JSON.stringify(buildingFilter));
  }

  public simpleBuildings(propertyId: string) {
    return this.http.get<IBuilding[]>(`buildings/selections/${propertyId}`);
  }

  public GetBuildingSelections(buildingId: string, filterType: string) {
    return this.http.get<IBuildingSelection[]>(`buildings/selections/${buildingId}/${filterType}`);
  }

  public GetBuildingFloorStructire(buildingId: string) {
    return this.http.get<any[]>(`buildings/buildingfloor/${buildingId}`);
  }

  public createBuilding(building: IBuildingIn) {
    return this.http.post<IBuilding>(`buildings`, JSON.stringify(building));
  }

  public updateBuilding(building: IBuildingIn) {
    return this.http.put<IBuilding>(`buildings`, JSON.stringify(building));
  }

  public buildingCalculate(id: string) {
    return this.http.put<IBuildingFloors>(`buildings/${id}/calculate`, {});
  }

  public updateBuildingFloors(buildingFloors: IBuildingFloorsIn) {
    return this.http.put<void>(`buildings/${buildingFloors.id}/floors`, JSON.stringify(buildingFloors));
  }

  public deleteBuilding(id: string) {
    return this.http.put<void>(`buildings/delete/${id}`, {});
  }
  //#endregion Buildings

  //#region Floors

  public createFloor(floor: IFloorIn) {
    return this.http.post<IFloor>(`floors`, JSON.stringify(floor));
  }

  public updateFloor(floor: IFloorIn) {
    return this.http.put<IFloor>(`floors`, JSON.stringify(floor));
  }

  public deleteFloor(id: string) {
    return this.http.put<void>(`floors/delete/${id}`, {});
  }
  //#endregion Floors

  //#region Pockets

  public getSapoaPockets(sapoaType?: SapoaType) {
    return this.http.get<string[]>(`pocket/sapoa?sapoaType=${sapoaType}`);
  }

  public deletePocket(id: string) {
    return this.http.put<void>(`pocket/delete/${id}`, {});
  }

  //#endregion Pockets

  //#region File
  public getArchivedDocumentVersions(filter: IArchivedVersionsFilter) {
    return this.http.post<[IArchivedVersions]>(`document/archived/versions`, JSON.stringify(filter));
  }

  public EmailMultipleFiles(data: IEmailDocumentFilesOut) {
    return this.http.post('document/email/multiple', data);
  }

  public getFiles(searchText: string = '') {
    return this.http.get<IFile[]>(`document?searchText=${searchText}`);
  }

  public uploadFile(formData: FormData, revisionNumber: any, fileType: any) {
    return this.http.post<IFile>(`document/upload/${fileType}/${revisionNumber}`, formData, {
      headers: new HttpHeaders({ 'no-header': 'true' }),
      reportProgress: true,
      observe: 'events'
    });
  }

  public addCustomTagsToFile(FileTagCustom: IFileTag[]) {
    return this.http.post<boolean>(`document/tag/custom`, JSON.stringify(FileTagCustom));
  }

  public searchDocumentTypes(categoryFilter: number) {
    return this.http.get<[IDocumentTypes]>(`document/documenttypes/search?category=${categoryFilter}`);
  }

  public searchDocumentTypesByRespnsible(categoryFilter: string, resposible: string) {
    return this.http.get<[IDocumentTypes]>(`document/documenttypes/search/resposible?category=${categoryFilter}&resposible=${resposible}`);
  }

  public FileExistsForParent(categoryId: string, parentId: string) {
    return this.http.get<boolean>(`document/documenttype/${categoryId}/${parentId}`);
  }

  public FileTypeParentCount(parentId: string, categoryId?: string) {
    return this.http.get<any>(`document/documenttype/${categoryId}/${parentId}/count`);
  }

  public DownloadLatestFileType(parentId: string, categoryId?: string) {
    return this.http.get(`document/documenttype/${categoryId}/${parentId}/download`, { responseType: 'blob' });
  }

  public DownloadFile(fileId: string) {
    return this.http.get(`document/download/${fileId}`, { responseType: 'blob' });
  }

  public DownloadMultipleFile(fileIds: string[]) {
    return this.http.post(`document/download/multiple`, fileIds, { responseType: 'blob' });
  }

  public GetFilesByFilters(genericFilters: IFileFilter, type: string) {
    return this.http.post<any>(`document/filter/${type}`, genericFilters);
  }

  public GetFilesByAdvancedFilters(genericFilters: IFileFilter, type: string) {
    return this.http.post<IFileAdvancedIn[]>(`document/filter/${type}/advanced`, genericFilters);
  }

  public GetFilesByBuilding(id: string, status: string) {
    return this.http.get<any>(`document/floor/${id}/${status}`);
  }

  public GetFilesByFloor(id: string, status: string) {
    return this.http.get<any>(`document/building/${id}/${status}`);
  }

  public GetFilesByProperty(id: string, status: string) {
    return this.http.get<any>(`document/property/${id}/${status}`);
  }

  public GetFileFilters() {
    return this.http.get<any>(`document/filters`);
  }

  public GetFileUploadFilters() {
    return this.http.get<any>(`document/filters/upload`);
  }

  public GetFileStatuses() {
    return this.http.get<IFileStatus[]>(`document/statuses`);
  }

  public GetFileTags(fileId: string) {
    return this.http.get<[IFileTagIn]>(`document/${fileId}/tags`);
  }

  public GetTags() {
    return this.http.get<[ITag]>(`document/tags`);
  }

  public GetTagByName(name: string) {
    return this.http.get<ITag>(`document/tag/${name}`);
  }

  public ChangeFileStatuses(fileStatusChange: IFileStatusChange) {
    return this.http.put<boolean>(`document/status/edit`, fileStatusChange);
  }

  public EditFileTag(fileTagId: string, value: string, friendlyName: string) {
    return this.http.put<boolean>(`document/tags/${fileTagId}`, { value: value, friendlyName: friendlyName });
  }
  //#endregion File

  //#region Unit Usage
  public getUnitUsages() {
    return this.http.get<IPagedResult<IUnitUsage>>(`unitusage`);
  }

  public addUnitUsages(name: string, sapoaTypeId: string) {
    return this.http.post<string>(`unitusage/${sapoaTypeId}/${name}`, {});
  }
  //#endregion Unit Usage

  //#region Sapoa Types
  public getSapoaTypes() {
    return this.http.get<IPagedResult<ISAPOAType>>(`sapoatypes`);
  }
  //#endregion Sapoa Types

  //#region Project
  public getProject(projectId: string) {
    return this.http.get<IProjectObj>(`projects/header/${projectId}`);
  }

  public getProjectExportData(projectId: string) {
    return this.http.get<any>(`projects/${projectId}/export`);
  }

  public refrshRollupStatuses() {
    return this.http.post<boolean>(`projects/update/rollups`, {});
  }

  public assignProjectCustomNumber(projectId: string, customNumber: string) {
    return this.http.post<boolean>(`projects/customnumber/${projectId}`, { customNumber: customNumber });
  }

  public saveProjectNotes(projectId: string, notes: string) {
    return this.http.post<boolean>(`projects/notes/${projectId}`, { customNumber: notes });
  }

  public getProjectScope(projectId: string) {
    return this.http.get<[ISelectedPocketDisplay]>(`projects/scopes/${projectId}`);
  }

  public getProjectFinancials(projectId: string) {
    return this.http.get<[IProjectFinacial]>(`projects/financials/${projectId}`);
  }

  public getProjectMilestones(projectId: string) {
    return this.http.get<[IProjectMilestone]>(`projects/milestones/${projectId}`);
  }

  public getProjectEntityData(projectEntityId: string, projectEntityType: string) {
    return this.http.get<IProjectEntity>(`projects/header/entitytype/${projectEntityId}&${projectEntityType}`);
  }

  public saveProjectHeader(project: IProjectHeader) {
    return this.http.post<any>(`projects/new`, project);
  }

  public getProjectTypes() {
    return this.http.get<[IProjectType]>(`projects/types`);
  }

  public getInstructionTypes() {
    return this.http.get<[IInstructionType]>(`projects/instructiontypes`);
  }

  public getMilestonesTemplates(projectId: string, hasMilestones: boolean) {
    return this.http.get<[IProjectMilestoneTemplate]>(`projects/milestonetemplates/${projectId}/${hasMilestones}`);
  }

  public saveProjectMilestone(milstone: IProjectMilestone[]) {
    return this.http.post<boolean>(`projects/milestone`, milstone);
  }

  public saveProjectInvoices(milstone: IProjectInvoice[]) {
    return this.http.post<boolean>(`projects/invoices`, milstone);
  }

  public saveProjectFinacials(finacials: IProjectFinacialOut[]) {
    return this.http.post<boolean>(`projects/financials`, finacials);
  }

  public GetProjectPrincipalAgents() {
    return this.http.get<[any]>(`projects/principalagents`);
  }

  public GetProjectQuantitySurveyor() {
    return this.http.get<[any]>(`projects/quantitySurveyor`);
  }

  public GetProjectsGrid(filter: any) {
    return this.http.post<[IProjectGrid]>(`projects/search`, JSON.stringify(filter));
  }

  public getProjectPendingInstructions(projectId: string) {
    return this.http.get<[string[]]>(`projects/${projectId}/pendinginstructions`);
  }

  public getProjectDocumentStatuses(projectid: string) {
    return this.http.get<[IProjectDocumentStatuses]>(`projects/${projectid}/documentstatus`);
  }

  public IsProjectMilestonesDone(projectid: string) {
    return this.http.get<boolean>(`projects/ismilstonesdone/${projectid}`);
  }

  public getResources() {
    return this.http.get<IResourceDetails[]>(`projects/list/resources`);
  }
  public getResourceTypes() {
    return this.http.get<[any]>(`projects/resourcetypes`);
  }

  public getResourceTypesClientroles() {
    return this.http.get<[any]>(`projects/resourcetypes/clientroles`);
  }

  public getResourceTypesUsers(resourceTypeIds: any) {
    return this.http.post<[any]>(`settings/resource/users`, resourceTypeIds);
  }

  public getProjectResources(projectId: string) {
    return this.http.get<IProjectResources[]>(`projects/resources/${projectId}`);
  }

  public saveProjectResources(resource: IProjectResources[]) {
    return this.http.post<boolean>(`projects/resources`, resource);
  }

  public getProjectPacks(projectTypeId: string, projectId: string) {
    return this.http.get<[IPack]>(`projects/${projectId}/packs/${projectTypeId}`);
  }

  public getDocumentDueDate(packElementDocumentTypeId: string, projectId: string) {
    return this.http.get<string>(`projects/documentduedate/${projectId}/packelement/${packElementDocumentTypeId}`);
  }

  public CreateProjectDocument(ProjectPackDocument: IProjectPackDocument) {
    return this.http.post<[IPack]>(`projects/create/document`, ProjectPackDocument);
  }

  public GetProjectDocument(projectId: string) {
    return this.http.get<[IProjectDocumentIn]>(`projects/${projectId}/documents`);
  }

  public getProjectProperties(propertyId: string) {
    return this.http.get<any>(`projects/properties/${propertyId}`);
  }

  public getScopeOfWork() {
    return this.http.get<[IScopeOfWork]>(`projects/scopeofwork`);
  }

  public saveProjectScope(scope: IProjectScope[]) {
    return this.http.post<boolean>(`projects/scopes`, scope);
  }

  public changeProjectStatus(projectId: string, status: string) {
    return this.http.post<boolean>(`projects/${projectId}/changestatus/${status}`, {});
  }

  public packAdministration(projectId: string) {
    return this.http.get<any>(`projects/${projectId}/packadministration`);
  }
  public packAdministrationMandatory(projectId: string, PackAdministration: any) {
    return this.http.post<boolean>(`projects/${projectId}/packadministration`, PackAdministration);
  }

  // Checklist
  public GetProjectPackChecklist(projectId: string) {
    return this.http.get<[IChecklistPack]>(`projects/${projectId}/checklists/packs`);
  }
  public GetProjectPackDocumentStatus(projectId: string) {
    return this.http.get<[IChecklistPack]>(`projects/${projectId}/checklists/packs/documentStatus`);
  }
  public GetProjectChecklistElements(projectId: string, packid: any) {
    return this.http.get<[IChecklistElement]>(`projects/${projectId}/checklist/elements/${packid}`);
  }
  public GetProjectChecklistElementsDocumentStatus(elements: string, projectId: any) {
    return this.http.get<[IChecklistElement]>(`projects/${projectId}/checklist/elements/documentStatus/${elements}`);
  }
  public GetProjectChecklistResources(projectId: string, packElementId: any) {
    return this.http.get<[IChecklistResourceType]>(`projects/${projectId}/checklists/resources/${packElementId}`);
  }
  public GetProjectChecklistDocumentTypes(projectId: string, resourceTypeId: any, elementId: any) {
    return this.http.get<[IChecklistDocumentType]>(`projects/${projectId}/checklists/documentTypes/${elementId}/${resourceTypeId}`);
  }
  public GetProjectChecklistProjectDocumentTypes(projectId: string, documentTypeId: any) {
    return this.http.get<[IChecklistProjectDocumentType]>(`projects/${projectId}/checklists/projectdocumentTypes/${documentTypeId}`);
  }
  public GetProjectChecklistFiles(projectId: string, projectDocumentTypeId: any) {
    return this.http.get<[IChecklistFile]>(`projects/${projectId}/checklists/files/${projectDocumentTypeId}`);
  }
  public SaveProjectDocument(projectId: string, projectDocumentType: IChecklistProjectDocumentTypeOut) {
    return this.http.post<string>(`projects/${projectId}/checklists/projectDocument`, projectDocumentType);
  }

  public ChecklistDocumentUpload(checklistUpload: IChecklistUpload) {
    return this.http.post<boolean>(`projects/create/document`, checklistUpload);
  }

  public DeleteProjectDocument(id: string) {
    return this.http.post<boolean>(`projects/delete/document/${id}`, {});
  }

  //#endregion Project

  //#region DIR

  public CreateDir(dir: IDIRInformation) {
    return this.http.post<boolean>(`dir/new`, dir);
  }

  public GetDIRDocumentType() {
    return this.http.get<string>(`dir/documentType`);
  }

  public searchDirs(propertyFilter: IDirFilter, pageNo: number, pageSize: number, sortBy: string, sortDir: string) {
    return this.http.post<IPagedResult<IDir>>(`dir/search?pageno=${pageNo}&pagesize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`, JSON.stringify(propertyFilter));
  }

  public getDirTypes() {
    return this.http.get<[IDirTypes]>(`dir/types`);
  }

  public changeDIRStatus(dirId: string, status: string) {
    return this.http.post<boolean>(`dir/${dirId}/changestatus/${status}`, {});
  }

  public getDir(id: string) {
    return this.http.get<IDIRInformation>(`dir/${id}`);
  }

  public getDirRaw(id: string) {
    return this.http.get<IDIRInformation>(`dir/${id}/rawdata`);
  }

  public getSavedDirRaw(id: string) {
    return this.http.get<IDIRInformation>(`dir/${id}/saved/rawdata`);
  }

  public AcceptRawImportData(id: string) {
    return this.http.post<IDIRInformation>(`dir/${id}/accept/importdata`, {});
  }

  public declineRawImportData(id: string) {
    return this.http.post<IDIRInformation>(`dir/${id}/decline/importdata`, {});
  }

  public GetDirPremisesChanges(id: string) {
    return this.http.get<IDIRInformation>(`dir/${id}/premiseschanges`, {});
  }

  public UpdateDirPremisesChanges(FloorsIn: IFloorIn) {
    return this.http.put<IDIRInformation>(`dir/premiseschanges`, FloorsIn);
  }

  public PopulateDirStructure(dirid: string) {
    return this.http.put<IDIRInformation>(`dir/PropertyStructure/${dirid}`, {});
  }

  public GetDirPropertyStructure(dirid: string) {
    return this.http.get<IDirProperty>(`dir/PropertyStructure/${dirid}`);
  }

  public GetDirPropertyStructureAsEProp(dirid: string) {
    return this.http.get<IEProperty>(`dir/PropertyStructure/eprop/${dirid}`);
  }

  public saveDIRParking(parking: IDIRScopeOut) {
    return this.http.post<boolean>(`dir/parking`, parking);
  }

  public getDIRParking(id: string) {
    return this.http.get<[IDIRParkingSelection]>(`dir/${id}/parking`);
  }

  public saveDIRParkingSpecifications(Specifications: IDIRParkingSelection[]) {
    return this.http.post<boolean>(`dir/save/dirparkingspecifications`, Specifications);
  }

  public PopulateDirParkingStructure(dirid: string) {
    return this.http.put<IDIRInformation>(`dir/ParkingStructure/${dirid}`, {});
  }

  public GetDirParkingStructure(dirid: string) {
    return this.http.get<IDirProperty>(`dir/ParkingStructure/${dirid}`);
  }

  //#endregion DIR
  //#region Users
  public getAllUsers(userType?: string) {
    return this.http.get<User[]>(`users?usertype=${userType}`);
  }

  public getAllUsersSettings(userFilter: any) {
    return this.http.post<UserSettings[]>(`users/userSettings`, userFilter);
  }

  public getUsersAccountDetails(userId: string) {
    return this.http.get<UserAccountDetails>(`users/accountsettings/${userId}`);
  }

  public getAllUserNames() {
    return this.http.get<[string]>(`users/usernames`);
  }

  public getAllUserEmailAddresses() {
    return this.http.get<[string]>(`users/mailaddresses`);
  }

  public deleteUser(id: string) {
    return this.http.delete<boolean>(`users/${id}`);
  }

  public getAllRoles() {
    return this.http.get<[IRole]>(`users/roles`);
  }

  public deleteRole(id: string) {
    return this.http.delete<boolean>(`users/role/${id}`);
  }

  public getAllPermissions() {
    return this.http.get<[any]>(`users/permissions`);
  }

  public getRoleDetails(roleId: string) {
    return this.http.get<IRoleIn>(`users/role/${roleId}/permissions`);
  }
  //#endregion Users
  //#region Mail

  public getMailAttachmentIds(mailTemplateId: string) {
    return this.http.get(`mail/${mailTemplateId}/attachmentIds`);
  }
  public addMailtoQue(code: string, parentId: string) {
    return this.http.post<boolean>(`mail/${code}/send/${parentId}`, {});
  }

  public ProccessNotification() {
    return this.http.post<boolean>(`mail/proccessnotification`, {});
  }

  public getMailVariables(mailid: string) {
    return this.http.get<[IMailVariables]>(`mail/${mailid}/variables`, {});
  }

  public getAllVariables() {
    return this.http.get<[IMailVariables]>(`mail/variables`, {});
  }

  public linkMailVariables(mailid: string, variableIds: string[]) {
    return this.http.post<boolean>(`mail/${mailid}/variables`, variableIds);
  }

  public getAllNotificationTriggers() {
    return this.http.get<[INotificationTrigger]>(`mail/triggers`, {});
  }

  public getMailTemplate(mailid: string) {
    return this.http.get<IMailTemplate>(`mail/${mailid}`, {});
  }

  public getAllMailTemplates() {
    return this.http.get<[IMailTemplate]>(`mail`, {});
  }

  public getNotificationLogs() {
    return this.http.get<[any]>(`mail/logs`, {});
  }

  public SaveMailTemplate(mailTemplate: IMailTemplate, selectedTemplateIds: string[]) {
    mailTemplate.selectedAttachmentIds = selectedTemplateIds;
    return this.http.post<boolean>(`mail/save`, mailTemplate);
  }

  public mailTreeView() {
    return this.http.get<any>(`users/mail/treeview`);
  }

  public saveMailSelectedUsers(mailUsersIn: MailTreeIn) {
    return this.http.post<boolean>(`users/mail/add`, mailUsersIn);
  }

  public getMailTemplateUsers(emailTemplateId: string | null) {
    return this.http.get<IMailUser[]>(`users/mail/assignedusers?emailTemplateId=${emailTemplateId}`);
  }

  public getActiveMailUsers() {
    return this.http.get<IUserEmailIn[]>('users/mail/available');
  }

  //#endregion Mail
  //#region Reports
  public getTenantLifeCycle() {
    return this.http.get<[ITenantLifeCycle]>(`properties/tenantlifecycle`);
  }

  public postTenantLifeCycle(records: any[]) {
    return this.http.post<boolean>(`properties/tenantlifecycle`, records);
  }

  public getTenantProperties(tenants: any) {
    return this.http.post<[string]>(`properties/tenants/properties`, JSON.stringify(tenants));
  }
  //#endregion Reports
  //#region Administration

  //#region Pending Portfolio
  public getPendingPortfolios(filter: string) {
    return this.http.get<IPortfoliosPending[]>(`administration/portfolios/pending/${filter}`);
  }
  public getPendingPortfolio(propertyId: string) {
    return this.http.get<IPendingView[]>(`administration/pendingportfolio/${propertyId}`);
  }

  //DIR

  //Property Resources
  public getPropertyResources() {
    return this.http.get<[PropertyResourceTypes]>(`administration/property/resources`);
  }
  //#endregion
  //#region Settings

  //Document

  public getSettingsDocumentTypes() {
    return this.http.get<[ISettingsDocumentTypes]>(`settings/document/documenttypes`);
  }
  public getSettingsDocumentTypesSimple() {
    return this.http.get<[ISettingsDocumentTypesSimple]>(`settings/document/documenttypes/simple`);
  }
  public getDocumentSources() {
    return this.http.get<[IDocumentProperties]>(`settings/document/sources`);
  }
  public getDocumentPropertyLevels() {
    return this.http.get<[IDocumentProperties]>(`settings/document/propertylevels`);
  }
  public getSettingsResources() {
    return this.http.get<[ISettingsDocumentResources]>(`settings/document/resourcetypes`);
  }
  public editDocumentType(docType: ISettingsDocumentTypesSave) {
    return this.http.post<boolean>(`settings/document/edittype`, docType);
  }

  public OrderDocumentType(docType: ISettingsDocumentTypes) {
    return this.http.post<boolean>(`settings/document/saveorder`, docType);
  }

  //Timeline

  //Roles
  public CreateRole(template: ISettingsRole) {
    return this.http.post<boolean>(`settings/role/new`, template);
  }
  public SaveRole(template: ISettingsRolePermisions, roleId: string) {
    return this.http.post<boolean>(`settings/role/${roleId}`, template);
  }
  public getRolesHierarchys(userId: string) {
    return this.http.get<[string]>(`users/roles/${userId}`);
  }

  public editUserRoles(userId: string, Ids: string[]) {
    return this.http.post<string>(`users/roles/${userId}/edit`, Ids);
  }

  public getRolesTreeView() {
    return this.http.get<any>(`users/roles/treeview`);
  }

  public savePermsion(obj: any, permisionId: string) {
    return this.http.post<any>(`settings/permision/${permisionId}`, obj);
  }

  //Milestone
  public getSettingsMilestoneTemplates() {
    return this.http.get<[ISettingsMilestoneTemplate]>(`settings/timeline/milestone/templates`);
  }

  public saveMilestoneSetup(template: ISettingsMilestoneTemplate) {
    return this.http.post<boolean>(`settings/timeline/milestone/template/save`, template);
  }

  public SaveMilestoneDocumentType(milestoneId: string, documentList: string[]) {
    return this.http.post<boolean>(`settings/timeline/milestone/${milestoneId}/linkmilestonedoc`, documentList);
  }

  public GetMilestoneDocumentType(milestoneId: string) {
    return this.http.get<any>(`settings/timeline/milestone/${milestoneId}/linkmilestonedoc`);
  }

  //Resources
  public CreateResource(resource: ISettingsResourceOut) {
    return this.http.post<boolean>(`settings/resource/create`, resource);
  }
  public CreateResourceType(resourceType: ISettingsResourceType) {
    return this.http.post<boolean>(`settings/resource/createtype`, resourceType);
  }

  public getResourceUsersByType(resourceType: string) {
    return this.http.get<[IResourceTypeUser]>(`resource/users/type/${resourceType}`);
  }

  //Project
  public getFinancialTypes() {
    return this.http.get<[IProjectFinancialType]>(`settings/project/financial/types`);
  }
  public getProjectTypeFinancials() {
    return this.http.get<[ISettingsFinancials]>(`settings/project/financials`);
  }

  public SaveProjectFinancialType(projectTypeId: string, selectedFinacials: string[]) {
    return this.http.post<boolean>(`settings/project/${projectTypeId}/financialtypes`, selectedFinacials);
  }

  public getProjectTypeMilestoneTemplates() {
    return this.http.get<KeyValue<string, string>[]>('settings/project/templates/milestones');
  }
  public SaveProjectMilestoneTemplates(projectTypeId: string, selectedTemplateIds: string[]) {
    return this.http.post<boolean>(`settings/project/${projectTypeId}/templates/milestones`, selectedTemplateIds);
  }

  //ClientProfile
  public getClientProfile() {
    return this.http.get<IClientProfile>(`settings/clientprofile`);
  }

  public createClientProfile(client: IClientProfile) {
    return this.http.post<string>(`settings/clientprofile`, client);
  }

  public getClientProfileHierarchys(userId: string) {
    return this.http.get<[string]>(`settings/clientprofile/${userId}`);
  }

  public editClientProfile(userId: string, Ids: string[]) {
    return this.http.post<string>(`settings/clientprofile/${userId}/edit`, Ids);
  }

  public editResourceslink(userId: string, Ids: string[]) {
    return this.http.post<string>(`settings/resourceslink/${userId}/edit`, Ids);
  }

  public ClientProfileTreeView() {
    return this.http.get<any>(`settings/clientprofile/treeview`);
  }

  public ResourceTreeView() {
    return this.http.get<any>(`settings/resource/treeview`);
  }

  public ClientProfileParentNode(name: string) {
    return this.http.post<string>(`settings/clientprofile/parent`, { name: name });
  }

  public ClientProfileChildNode(name: string, parentId: string, isProperty: boolean) {
    return this.http.post<string>(`settings/clientprofile/child`, { name: name, parentId: parentId, isProperty: isProperty });
  }

  public SaveClientNode(id: string, name: string) {
    return this.http.put<string>(`settings/clientprofile/save`, { id: id, name: name });
  }

  public DeleteClientNode(id: string, name: string) {
    return this.http.put<string>(`settings/clientprofile/delete`, { id: id, name: name });
  }

  public GetDeleteClientNodeWarnings(id: string, name: string) {
    return this.http.put<IClientProfileDeleteWarnings>(`settings/clientprofile/deletewarnings`, { id: id, name: name });
  }

  //Packs
  public PackTreeView() {
    return this.http.get<any>(`settings/pack/treeview`);
  }

  public PackParentNode(name: string) {
    return this.http.post<any>(`settings/pack/parent`, { name: name });
  }

  public newPackChildNode(name: string, id: string, isDocument: boolean) {
    return this.http.post<any>(`settings/pack/new/child`, { parentId: id, name: name, isProperty: isDocument });
  }

  public SavePackNode(id: string, name: string) {
    return this.http.put<string>(`settings/pack/save`, { id: id, name: name });
  }

  public DeletePackNode(id: string, name: string) {
    return this.http.put<string>(`settings/pack/delete`, { id: id, name: name });
  }

  public ClientPackChildNode(name: string, childId: string, parentId: string, isDocument: boolean) {
    return this.http.post<string>(`settings/pack/new/child`, { name: name, childId: childId, parentId: parentId, isProperty: isDocument });
  }

  // SystemSettings
  public GetTagListData(archive: boolean) {
    return this.http.get<[ITagSettings]>(`settings/systemsettings/tags?archived=${archive}`);
  }

  public AddTag(tag: ITagSettings) {
    return this.http.post<boolean>(`settings/systemsettings/tags`, tag);
  }

  public EditTag(tag: ITagSettings) {
    return this.http.put<boolean>(`settings/systemsettings/tags`, tag);
  }

  public LoginForceLink() {
    return this.http.post(`settings/forcelink`, null, { responseType: 'text' });
  }

  //#endregion

  // #region Process Manager

  public GetPMEvents() {
    return this.http.get<[IPMEvent]>(`processmanager/events`);
  }

  public GetPMJobs() {
    return this.http.get<[IPMJob]>(`processmanager/jobs`);
  }

  public SavePMJobs(jobOut: IPMJobOut) {
    return this.http.post<IPMJob>(`processmanager/jobs`, jobOut);
  }

  public ExecuteJob(jobId: string) {
    return this.http.get<boolean>(`processmanager/job/execute/${jobId}`);
  }

  public GetPMSteps() {
    return this.http.get<[IPMStep]>(`processmanager/steps`);
  }

  public GetPMStep(stepId: string) {
    return this.http.get<IPMStep>(`processmanager/steps/${stepId}`);
  }

  public SavePMSteps(stepOut: IPMStepOut) {
    return this.http.post<IPMStep>(`processmanager/steps`, stepOut);
  }

  public GetPMSchedules() {
    return this.http.get<[IPMSchedule]>(`processmanager/schedules`);
  }

  public SavePMSchedules(schedule: IPMScheduleOut) {
    return this.http.post<IPMSchedule>(`processmanager/schedules`, schedule);
  }

  public GetPMDailyFrequency() {
    return this.http.get<[IPMDailyFrequency]>(`processmanager/dailyfrequency`);
  }

  public SavePMDailyFrequency(dailyFrequency: IPMDailyFrequencyOut) {
    return this.http.post<IPMDailyFrequency>(`processmanager/dailyfrequency`, dailyFrequency);
  }

  public GetPMWeeklyFrequency() {
    return this.http.get<[IPMWeeklyFrequency]>(`processmanager/weeklyfrequency`);
  }

  public SavePMWeeklyFrequency(weeklyFrequency: IPMWeeklyFrequencyOut) {
    return this.http.post<IPMWeeklyFrequency>(`processmanager/weeklyfrequency`, weeklyFrequency);
  }

  public GetPMMonthlyFrequency() {
    return this.http.get<[IPMMonthlyFrequency]>(`processmanager/monthlyfrequency`);
  }

  public GetPMUnitTypes() {
    return this.http.get<[IPMUnitType]>(`processmanager/unittypes`);
  }

  public SavePMUnitTypes(unitUsage: IPMUnitTypeOut) {
    return this.http.post<IPMUnitType>(`processmanager/unittypes`, unitUsage);
  }

  public GetPMAPIs() {
    return this.http.get<[IPMAPIs]>(`processmanager/api`);
  }

  public SavePMAPIs(api: IPMAPIsOut) {
    return this.http.post<[IPMAPIs]>(`processmanager/api`, api);
  }

  public GetPMQueries() {
    return this.http.get<[IPMQuery]>(`processmanager/queries`);
  }

  public SavePMQueries(query: IPMQueryOut) {
    return this.http.post<IPMQuery>(`processmanager/queries`, query);
  }

  // #endregion Process Manager

  //data warehouse api's
  public GetDWListData(restrictionType: string) {
    return this.http.get<IPropertySummary[]>(`propertysummary/dwpropertydata/${restrictionType}`, {});
  }

  public GetDWViewData(dwPropertyId: string, restrictionType: string) {
    return this.http.get<IPropertySummary[]>(`propertysummary/dwpropertydata/${dwPropertyId}/${restrictionType}`, {});
  }

  public getDwPendingViewData(dwPropertyId: string) {
    return this.http.get<IPendingPropertySummary[]>(`propertysummary/dwpropertydata/pending/${dwPropertyId}`, {});
  }

  public changeLeaseDataStatus(lcsId: string) {
    return this.http.post<boolean>(`propertysummary/dwpropertydata/changeleasedatastatus/${lcsId}`, {});
  }

  public saveTempDataStatus(id: string, level: string, status: string) {
    return this.http.post<IPremisesSummary>(`propertysummary/dwpropertydata/${id}/save/${level}/TempDataStatus/${status}`, {});
  }

  public saveTempDataPremises(id: string, data: any) {
    return this.http.post<IPremisesSummary>(`propertysummary/dwpropertydata/${id}/savetemppremises`, data);
  }

  public saveTempDataSapoaConvertPremises(id: string, data: any) {
    return this.http.post<IPremisesSummary>(`propertysummary/dwpropertydata/${id}/savesapoaconvert`, data);
  }

  public ptoAcceptance(propertyId: string) {
    return this.http.post<IPremisesSummary>(`propertysummary/ptoAcceptance/${propertyId}`, {});
  }

  // Graphs
  public GetPropertyPortfolioOverview() {
    return this.http.get<IPropertySummary[]>(`propertysummary/graph/PropertyPortfolioOverview`, {});
  }

  public GetPropertiesByPortfolioManager() {
    return this.http.get<IPropertySummary[]>(`propertysummary/graph/PropertiesbyPortfolioManager`, {});
  }

  public GetSquareMetersByPortfolioManager() {
    return this.http.get<IPropertySummary[]>(`propertysummary/graph/SquareMetersrbyPortfolioManager`, {});
  }

  public GetPropertiesByCategory(catagory: string) {
    return this.http.get<IPropertySummary[]>(`propertysummary/graph/PropertiesByCategory/${catagory}`, {});
  }

  public GetLeasingRobot() {
    return this.http.get<IPropertySummary[]>(`propertysummary/graph/LeasingRobot`, {});
  }

  public GetLeasesByStatus() {
    return this.http.get<IPropertySummary[]>(`propertysummary/graph/LeasesbyStatus`, {});
  }

  public GetLeasesByExpiryMonth() {
    return this.http.get<IPropertySummary[]>(`propertysummary/graph/LeasesbyExpiryMonth`, {});
  }

  public GetProjectsByStatus() {
    return this.http.get<IPropertySummary[]>(`propertysummary/graph/ProjectsbyStatus`, {});
  }

  public GetProjectsByFinancialYear() {
    return this.http.get<IPropertySummary[]>(`propertysummary/graph/ProjectsbyFinacialYear`, {});
  }

  public dwPremisesConstruct(propertyId: string) {
    return this.http.post<IPropertySummary[]>(`propertysummary/rollup/construct/${propertyId}`, {});
  }

  public dwPremisesStatus(propertyId: string) {
    return this.http.post<IPropertySummary[]>(`propertysummary/rollup/status/${propertyId}`, {});
  }

  public dwPremisesLease(propertyId: string) {
    return this.http.post<IPropertySummary[]>(`propertysummary/rollup/leases/${propertyId}`, {});
  }

  // Parking

  public GetParkingProperties() {
    return this.http.get<IEPropertyParking[]>(`parking/properties`, {});
  }

  public GetParkingProperty(propertyId: string) {
    return this.http.get<IEPropertyParking>(`parking/property/${propertyId}`, {});
  }

  public GetLCSParkingProperty(propertyId: string) {
    return this.http.get<IEPropertyParking>(`parking/property/lcs/${propertyId}`, {});
  }

  public GetBayUsages() {
    return this.http.get<IParkingTypes[]>(`parking/bayusages`, {});
  }

  public GetParkingTypes() {
    return this.http.get<IParkingTypes[]>(`parking/parkingtypes`, {});
  }

  public saveEParking(parking: IEPropertyParking) {
    return this.http.post<boolean>(`parking/eparking`, parking);
  }

  public saveParkingRestrictions(ParkingRestrictions: any) {
    return this.http.post<boolean>(`parking/parkingrestrictions`, ParkingRestrictions);
  }
  public getLCSParkingRestrictions(lcsId: string) {
    return this.http.get<IParkingLCSRestrictions>(`parking/lcs/${lcsId}/restrictions`);
  }

  public createPCSProperty(propertyId: any) {
    return this.http.get<string>(`parking/pcs/create/${propertyId}`);
  }

  public getBasicPCSInfo(id: any) {
    return this.http.get<IBasicPCSInfo>(`parking/pcs/basicinfo/${id}`);
  }

  public getPCSList(finacialYears: string[]) {
    return this.http.post<IPCSList>(`parking/pcs/list`, finacialYears);
  }

  public getPCSSpecialConditions(id: string) {
    return this.http.get<any>(`parking/pcs/${id}/specialconditions`);
  }

  public savePCSSpecialConditions(comments: string, id: string) {
    return this.http.post<boolean>(`parking/pcs/save/specialconditions/${id}`, JSON.stringify(comments));
  }

  public getPCSTenant(id: string) {
    return this.http.get<ILeaseTenant>(`parking/pcs/${id}/tenant`);
  }

  public savePCSTenant(leaseTenant: ILeaseTenantOut) {
    return this.http.post<boolean>(`parking/pcs/tenant/save`, leaseTenant);
  }

  public getPCSDetails(LeaseId: string) {
    return this.http.get<IPCSDetails>(`parking/pcs/${LeaseId}/leasedetail`);
  }

  public savePCSDetails(leasedetail: ILeaseDetailsOut) {
    return this.http.post<boolean>(`parking/pcs/save/leasedetail`, leasedetail);
  }

  public getPCSParking(id: string) {
    return this.http.get<[ILeaseParking]>(`parking/pcs/${id}/leaseparking`);
  }

  public savePCSParking(leaseparking: ILeaseParkingOut[], id: string) {
    return this.http.post<boolean>(`parking/pcs/save/leaseparking/${id}/`, leaseparking);
  }

  public getPCSProperty(id: string) {
    return this.http.get<string>(`parking/pcs/${id}/property`);
  }

  public changePCSStatus(id: string, status: string) {
    return this.http.post<boolean>(`parking/pcs/${id}/changestatus/${status}`, {});
  }

  public hasPCSLinkedTCN(id: string) {
    return this.http.get<boolean>(`parking/pcs/${id}/hasLinkedTCN`);
  }

  public createTCNFromPCS(id: string, type: string) {
    return this.http.post<string>(`parking/pcs/${id}/tcn/${type}`, {});
  }

  public dwParkingConstruct(propertyId: string) {
    return this.http.post<IPropertySummary[]>(`parking/rollup/construct/${propertyId}`, {});
  }

  public dwParkingStatus(propertyId: string) {
    return this.http.post<IPropertySummary[]>(`parking/rollup/status/${propertyId}`, {});
  }

  public dwParkingLease(propertyId: string) {
    return this.http.post<IPropertySummary[]>(`parking/rollup/leases/${propertyId}`, {});
  }

  public GetPendingParkingList() {
    return this.http.get<IPendingParkingProperty[]>(`parking/pending`);
  }

  public GetPendingParking(propertyId: string) {
    return this.http.get<IPendingParkingProperty>(`parking/pending/${propertyId}`);
  }

  public GetSapoaParkingList() {
    return this.http.get<IPendingParkingProperty[]>(`parking/sapoa`);
  }

  public GetSapoaParking(propertyId: string) {
    return this.http.get<IPendingParkingProperty>(`parking/sapoa/${propertyId}`);
  }

  public saveTempDataParking(id: string, data: any) {
    return this.http.post<IPremisesSummary>(`parking/pending/${id}/savetempparking`, data);
  }

  public changePCSDataStatus(lcsId: string) {
    return this.http.post<boolean>(`parking/pending/changeleasedatastatus/${lcsId}`, {});
  }

  //region General Documents
  public GetGeneralDocuments() {
    return this.http.get<IGenericDocumentGroup[]>(`document/generalgroup/get`);
  }

  public CreateGeneralDocumentGroup(data: any) {
    return this.http.post<boolean>(`document/generalgroup/save`, data);
  }

  public CreateGeneralDocument(data: any) {
    return this.http.post<boolean>(`document/generaldocuments/save`, data);
  }

  public GetGeneralDocumentType() {
    return this.http.get<string>(`document/generaldoc/type`);
  }

  public RemoveGeneralDocument(docid: string) {
    return this.http.post<boolean>(`document/generaldocuments/remove/${docid}`, {});
  }
  //endregion General Documents

  //region ActivityLogs
  public GetActivityLogs(filter: IActivityLogFilter) {
    return this.http.post<IActivityLogGroup[]>(`users/activityLogs`, filter);
  }

  //endregion General Documents
}
