import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-hover-column',
  templateUrl: './hover-column.component.html',
  styleUrls: ['./hover-column.component.scss']
})
export class HoverColumnComponent implements OnInit, OnChanges {
  @Input() valueIn: string = '';
  @Input() hoverTextIn: string = 'Add {{columnName}} to {{hoverSettings}}';

  value!: string;
  hoverText!: string;

  constructor() {}

  ngOnInit() {
    this.value = this.valueIn;
    this.hoverText = this.hoverTextIn;
  }

  ngOnChanges() {
    this.value = this.valueIn;
    this.hoverText = this.hoverTextIn;
  }
}
