

<span
  [matMenuTriggerFor]="menu"
  class="hyperlink">{{value}}
</span>

<mat-menu #menu="matMenu">
  <ng-container *ngFor="let option of matOptions; let i= index ">
    <button *ngIf="option.level?.includes(this.currentLevel)" mat-menu-item (click)="toggleClicked(option)">
      <mat-icon *ngIf="option.icon !==''" [style]="{color:option.color}">{{option.icon}}</mat-icon>
      <span [style]="{color:option.color}">{{option.name}}</span>
    </button>
  </ng-container>

</mat-menu>
