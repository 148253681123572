import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PendingRequestsInterceptor } from '@app/core/interceptors/pending-requests-interceptor';
import { AuthService } from '@app/core/services/auth-service';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  authForm!: UntypedFormGroup;
  username!: UntypedFormControl;
  password!: UntypedFormControl;
  isReset = false;
  error = '';
  returnUrl!: string;
  matcher = new MyErrorStateMatcher();
  isLoading$: Observable<boolean>;
  isActive = false;
  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute, private pendingRequestsInterceptor: PendingRequestsInterceptor) {
    this.isLoading$ = this.pendingRequestsInterceptor.pendingRequestsStatus$;
  }

  ngOnInit() {
    localStorage.clear();
    this.createFormControls();
    this.createForm();
    if (this.route.snapshot.queryParams['token']) {
      let encoded: any = atob(this.route.snapshot.queryParams['token']).split(':');
      this.externalLogin(encoded[0], encoded[1]);
    }
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/secure/dashboard';
  }

  createFormControls() {
    this.username = new UntypedFormControl('', [Validators.required]);
    this.password = new UntypedFormControl('', [Validators.required]);
  }

  createForm() {
    this.authForm = new UntypedFormGroup({
      username: this.username,
      password: this.password
    });
  }

  onSubmit(): void {
    if (this.authForm.valid) {
      this.authService
        .login(this.authForm.value)
        .pipe(
          mergeMap((model) => {
            return this.authService.setUserContext().pipe(
              map(() => {
                if (model.requiresPasswordChange) {
                  this.authService.resetPasswordCode = this.password.value;
                  this.router.navigate(['/resetpassword']);
                } else {
                  this.router.navigateByUrl(this.returnUrl);
                }
              })
            );
          })
        )
        .subscribe(
          () => {},
          (err) => {
            if (err.status === 0) {
              this.error = 'Your network is unavailable. Check your data or wifi connection.';
            } else {
              this.error = 'Incorrect Username or Password';
            }
            setTimeout(() => {
              this.error = '';
            }, 5000);
          }
        );
    }
  }

  externalLogin(externalUserName: string, externalPassword: string) {
    this.authService
      .externalLogin({ username: externalUserName, password: externalPassword, platformInformation: '' })
      .pipe(
        mergeMap((model) => {
          return this.authService.setUserContext().pipe(
            map(() => {
              if (model.requiresPasswordChange) {
                this.authService.resetPasswordCode = this.password.value;
                this.router.navigate(['/resetpassword']);
              } else {
                this.router.navigateByUrl(this.returnUrl);
              }
            })
          );
        })
      )
      .subscribe(
        () => {},
        (err) => {
          if (err.status === 0) {
            this.error = 'Your network is unavailable. Check your data or wifi connection.';
          } else {
            this.error = 'Incorrect Username or Password';
          }
          setTimeout(() => {
            this.error = '';
          }, 5000);
        }
      );
  }

  getPlatformInformation() {
    const res = {
      model: 'Not Set',
      platform: navigator.platform,
      version: navigator.appCodeName + ' ' + navigator.appVersion,
      manufacturer: 'Not Set'
    };

    return JSON.stringify(res);
  }

  forgotPassword() {
    this.router.navigate(['/forgotpassword']);
  }
}
