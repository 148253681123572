

<div class="tree-table-container" [style]="'height:' + this.tableSettings.height+';max-height:' + this.tableSettings.maxHeight">
  <table [style]="'height:' + this.tableSettings.height+';max-height:' + this.tableSettings.maxHeight" mat-table [dataSource]="dataSource"
    [style]="getTableStyle()">
    <ng-container *ngFor="let column  of this.displayedColumns; let i = index; trackBy: trackById">
      <ng-container [matColumnDef]="column.columnName">
        <thead>
          <th style="z-index: 200;" mat-header-cell *matHeaderCellDef
            [class]="column.align === textAlign.Left ? 'left-text' : column.align === textAlign.Right ? 'right-text' : 'center-text'"
            [style]="getHeaderStyle(column)">
            <div *ngIf="column.columnType === columnTypes.iconEnum" class="header-container">
              <app-custom-table-tooltip
                [tooltipContent]="getIconsAndTooltips(column.iconEnum)"></app-custom-table-tooltip>
              <span>{{ column.displayName }}</span>
            </div>

            <section *ngIf="column.columnType !== columnTypes.iconEnum">
              <span>{{ column.displayName }}</span>
            </section>
          </th>
        </thead>

        <td mat-cell *matCellDef="let element"
          [style]="getElementStyle(element,column)">

          <div [style]="getRow1_Styling(column)">
            <app-column-controller
              [parentData]="[]"
              [column]="column" [element]="element"
              [tableSettings]="tableSettings"
              [currentLevel]="1"
              [toggleSelectedIn]="selectAll"
              [refreshBit]="refreshBit"
              [selectedIds]="selectedIds"
              (hyperlinkClicked)="this.hyperlinkClicked.emit($event);catchEvent('hyperlinkClicked',$event)"
              (matOptionClicked)="this.matOptionClicked.emit($event);catchEvent('matOptionClicked',$event)"
              (iconClicked)="this.iconClicked.emit($event);catchEvent('iconClicked',$event)"
              (buttonClicked)="this.buttonClicked.emit($event);catchEvent('buttonClicked',$event)"
              (selectedChanged)="this.selectedChanged.emit($event);catchEvent('selectedChanged',$event)"
              (wog_dropDownSelectionChanged)="wog_dropDownSelectionChanged.emit($event);catchEvent('wog_dropDownSelectionChanged',$event)"
              (wog_numericFieldChanged)="wog_numericFieldChanged.emit($event);catchEvent('wog_numericFieldChanged',$event)"
              (wog_textFieldChanged)="wog_textFieldChanged.emit($event);catchEvent('wog_textFieldChanged',$event)"
              (rowExpand)="element.expanded = !element.expanded;">
            </app-column-controller>
          </div>

          <div *ngIf="element.expanded && element.children" class="tree-grid">
            <hr *ngIf="this.tableSettings.showLevelLines" class="horizontal-line ">
            <div *ngFor="let child of element.children; let isFirst=first; let isLast=last; let i = index; trackBy: trackById"
              [class.first-item]="isFirst"
              [class.last-item]="isLast"
              class="tree-grid-item"
              [style]="getRow2_Styling(column)">
              <app-column-controller
                [parentData]="element.children"
                [column]="column" [element]="child"
                [tableSettings]="tableSettings"
                [currentLevel]="2"
                [toggleSelectedIn]="selectAll"
                [refreshBit]="refreshBit"
                [selectedIds]="selectedIds"
                (hyperlinkClicked)="this.hyperlinkClicked.emit($event);catchEvent('hyperlinkClicked',$event)"
                (matOptionClicked)="this.matOptionClicked.emit($event);catchEvent('matOptionClicked',$event)"
                (iconClicked)="this.iconClicked.emit($event);catchEvent('iconClicked',$event)"
                (buttonClicked)="this.buttonClicked.emit($event);catchEvent('buttonClicked',$event)"
                (selectedChanged)="this.selectedChanged.emit($event);catchEvent('selectedChanged',$event)"
                (wog_dropDownSelectionChanged)="wog_dropDownSelectionChanged.emit($event);catchEvent('wog_dropDownSelectionChanged',$event)"
                (wog_numericFieldChanged)="wog_numericFieldChanged.emit($event);catchEvent('wog_numericFieldChanged',$event)"
                (wog_textFieldChanged)="wog_textFieldChanged.emit($event);catchEvent('wog_textFieldChanged',$event)"
                (rowExpand)="child.expanded = !child.expanded;">
              </app-column-controller>

              <div *ngIf="child.expanded && child.children" class="tree-grid">
                <hr *ngIf="this.tableSettings.showLevelLines" class="horizontal-line ">
                <div *ngFor="let childLower of child.children; let isFirst=first; let isLast=last; let i = index; trackBy: trackById"
                  [class.first-item]="isFirst"
                  [class.last-item]="isLast"
                  class="tree-grid-item"
                  [style]="getRow3_Styling(column)">
                  <app-column-controller
                    [parentData]="child.children"
                    [column]="column" [element]="childLower"
                    [tableSettings]="tableSettings"
                    [currentLevel]="3"
                    [toggleSelectedIn]="selectAll"
                    [refreshBit]="refreshBit"
                    [selectedIds]="selectedIds"
                    (hyperlinkClicked)="this.hyperlinkClicked.emit($event);catchEvent('hyperlinkClicked',$event)"
                    (matOptionClicked)="this.matOptionClicked.emit($event);catchEvent('matOptionClicked',$event)"
                    (iconClicked)="this.iconClicked.emit($event);catchEvent('iconClicked',$event)"
                    (buttonClicked)="this.buttonClicked.emit($event);catchEvent('buttonClicked',$event)"
                    (selectedChanged)="this.selectedChanged.emit($event);catchEvent('selectedChanged',$event)"
                    (wog_dropDownSelectionChanged)="wog_dropDownSelectionChanged.emit($event);catchEvent('wog_dropDownSelectionChanged',$event)"
                    (wog_numericFieldChanged)="wog_numericFieldChanged.emit($event);catchEvent('wog_numericFieldChanged',$event)"
                    (wog_textFieldChanged)="wog_textFieldChanged.emit($event);catchEvent('wog_textFieldChanged',$event)"
                    (rowExpand)="childLower.expanded = !childLower.expanded;">
                  </app-column-controller>
                  <div *ngIf="childLower.expanded && childLower.children" class="tree-grid">
                    <hr *ngIf="this.tableSettings.showLevelLines" class="horizontal-line ">
                    <div *ngFor="let finalChild of childLower.children; let isFirst=first; let isLast=last; let i = index; trackBy: trackById"
                      [class.first-item]="isFirst"
                      [class.last-item]="isLast"
                      class="tree-grid-item"
                      [style]="getRow4_Styling(column)">
                      <app-column-controller
                        [parentData]="childLower.children"
                        [column]="column" [element]="finalChild"
                        [tableSettings]="tableSettings"
                        [currentLevel]="4"
                        [toggleSelectedIn]="selectAll"
                        [refreshBit]="refreshBit"
                        [selectedIds]="selectedIds"
                        (hyperlinkClicked)="this.hyperlinkClicked.emit($event);catchEvent('hyperlinkClicked',$event)"
                        (matOptionClicked)="this.matOptionClicked.emit($event);catchEvent('matOptionClicked',$event)"
                        (iconClicked)="this.iconClicked.emit($event);catchEvent('iconClicked',$event)"
                        (buttonClicked)="this.buttonClicked.emit($event);catchEvent('buttonClicked',$event)"
                        (selectedChanged)="this.selectedChanged.emit($event);catchEvent('selectedChanged',$event)"
                        (wog_dropDownSelectionChanged)="wog_dropDownSelectionChanged.emit($event);catchEvent('wog_dropDownSelectionChanged',$event)"
                        (wog_numericFieldChanged)="wog_numericFieldChanged.emit($event);catchEvent('wog_numericFieldChanged',$event)"
                        (wog_textFieldChanged)="wog_textFieldChanged.emit($event);catchEvent('wog_textFieldChanged',$event)"
                        (rowExpand)="finalChild.expanded = !finalChild.expanded;">
                      </app-column-controller>
                    </div>
                    <hr *ngIf="this.tableSettings.showLevelLines" class="horizontal-line ">

                  </div>
                </div>
                <hr *ngIf="this.tableSettings.showLevelLines" class="horizontal-line ">

              </div>
            </div>
            <hr *ngIf="this.tableSettings.showLevelLines" class="horizontal-line ">

          </div>
        </td>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
  </table>

  <mat-paginator *ngIf="showPaginator" #paginator
    [pageSize]="paginatorOptionsIn.pageSize"
    [pageSizeOptions]="paginatorOptionsIn.pageSizeOptions"
    [showFirstLastButtons]="paginatorOptionsIn.showFirstLastButtons">
  </mat-paginator>

</div>
