import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-space-link-login',
  templateUrl: './space-link-login.component.html',
  styleUrls: ['./space-link-login.component.scss']
})
export class SpaceLinkLoginComponent implements OnInit, AfterViewInit {
  @ViewChild('submitForm') form!: ElementRef;
  url: string = '';
  subscriberId: string = '';
  apiKey: string = '';

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.url = 'https://dev6896-core.forcelink.net/forcelink/rest/usermanager/apiKeyLogin';
    this.subscriberId = 'fm_nlsa';
    this.apiKey = 'f73f51e0-7269-11ee-a47a-627aef4de265';
  }

  ngAfterViewInit() {
    this.form.nativeElement.submit();
  }
}
