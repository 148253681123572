

<mat-toolbar color="primary">
  <mat-icon class="custoIcon">conveyor_belt</mat-icon>
  <span> {{ data.logType }} Details</span>
  <span class="spacer"></span>

  <button mat-icon-button class="icon" mat-dialog-close hint="cancel">
    <mat-icon matTooltip="Close">close</mat-icon>
  </button>
</mat-toolbar>

<div mat-dialog-content>
  <section class="section-header">
    <p><strong>Log Date:</strong> {{ data.logDate }}</p>
  </section>
  <section class="section-user">
    <h3>User Information</h3>
    <p><strong>User:</strong> {{ data.user }}</p>
    <p><strong>Username:</strong> {{ data.username }}</p>
    <p><strong>Email:</strong> {{ data.email }}</p>
  </section>
  <section class="section-activity">
    <h3>Activity Details</h3>
    <p><strong>Action:</strong> {{ data.action }}</p>
    <p><strong>Description:</strong> {{ data.description }}</p>
  </section>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onClose()">Close</button>
</div>
