<div class="app-shell">
  <mat-progress-bar class="progressbar" *ngIf="isLoading$ | async" mode="indeterminate"></mat-progress-bar>
  <div class="header">
    <div class="header-child-left">
      <img style="width: 250px" src="/assets/img/SpaceUP-logo-small.png" />
    </div>

    <div class="header-child-right">
      <mat-icon class="pop-out-info" (click)="isInfoOpen=!isInfoOpen;isProfileOpen=false">info</mat-icon>
      <img
        *ngIf="authService.imageUrl"
        [src]="authService.imageUrl"
        alt="User Icon"
        class="pop-out-profile"
        (click)="isProfileOpen=!isProfileOpen; isInfoOpen = false">
      <span (click)="isProfileOpen=!isProfileOpen;isInfoOpen = false" class="innerContent pop-out-text">{{ authService.userContext.firstName }} {{
        authService.userContext.lastName }}</span>
    </div>
  </div>

  <div class="content">
    <div class="fixed-nav side-menu-small">
      <mat-nav-list>
        <button mat-button matRippleDisabled matTooltip="Expand menu" class="menulink" routerLinkActive="active" (click)="sidenav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
        <ng-container *ngFor="let group of groups">
          <mat-list-item *ngFor="let menu of group.items" routerLinkActive="active" class="routerLinks" [matTooltip]="menu.permissionName"
            [routerLink]="menu.permissionName === 'Maintenance'?null:menu.navigateTo" (click)="menu.permissionName === 'Maintenance'?openMaintenance():close()">

            <span style="color: white;" class="material-symbols-outlined">
              {{ menu.icon }}
            </span>
          </mat-list-item>
          <hr style="color: white;">
        </ng-container>
      </mat-nav-list>
    </div>

    <mat-sidenav-container class="menu-container">
      <mat-sidenav class="side-menu" #sidenav mode="over">
        <mat-nav-list>
          <button mat-button class="menulink">
          </button>
          <ng-container *ngFor="let group of groups">
            <mat-list-item *ngFor="let menu of group.items" routerLinkActive="active" class="routerLinks"
              [routerLink]="menu.permissionName === 'Maintenance'?null:menu.navigateTo"
              (click)="menu.permissionName === 'Maintenance'?openMaintenance():close()">
              <span matListItemTitle>{{ menu.permissionName }}</span>
            </mat-list-item>
            <hr class="divider">
          </ng-container>
        </mat-nav-list>

      </mat-sidenav>

      <div class="main-content">
        <router-outlet></router-outlet>

      </div>
    </mat-sidenav-container>
  </div>

  <div class="footer">
    <a href="https://cbos.co.za/" target="_blank" class="footer-link">
      <img src="assets/img/cbos.png" alt="Logo" class="footer-logo">
      <span>Powered by CBOS</span>
    </a>

  </div>

  <div (click)="closeCards()" *ngIf="isProfileOpen ||isInfoOpen" class="overlay"></div>

  <section class="profile">
    <mat-card *ngIf="isProfileOpen" class="profile-card" appearance="outlined">

      <div class="profile-content">
        <!-- Profile Image and User Name -->
        <div class="left-section">
          <div class="profile-image-container">
            <img [src]="authService.imageUrl" alt="Logo" class="profile-image">
          </div>
          <div style="color: white;">
            {{ authService.userContext.firstName }} {{ authService.userContext.lastName }}
          </div>
        </div>

        <!-- Vertical Line -->
        <hr class="vertical-line">

        <!-- Buttons -->
        <div class="right-section">
          <!-- Settings Button -->
          <div class="wrap-button">
            <button class="full_width" (click)="maintainUserDialog()" mat-flat-button>
              <mat-icon color="primary">manage_accounts</mat-icon> Manage Account
            </button>
          </div>

          <!-- Switch User -->
          <div *ngIf="isSuperuser" class="wrap-button">
            <button class="full_width" (click)="switchUserDialog()" mat-flat-button>
              <mat-icon color="primary">shield_person</mat-icon> Switch User
            </button>
          </div>

          <!-- Logout Button -->
          <div class="wrap-button" (click)="openDialog()">
            <button class="full_width" mat-flat-button>
              <mat-icon color="primary">exit_to_app</mat-icon> Logout
            </button>
          </div>
        </div>
      </div>

    </mat-card>
  </section>

  <section class="cbos">
    <mat-card *ngIf="isInfoOpen" class="cbos-card" appearance="outlined">

      <div class="cbos-content">
        <!-- Profile Image and User Name -->
        <div class="left-section">
          <div class="cbos-image-container">
            <img src="assets/img/cbospfp.png" alt="Logo" class="cbos-image">
          </div>
          <hr class="horizontalline-white">

          <!-- Report bug -->
          <button class="full_width button" (click)="openWebsite()" mat-flat-button>
            <mat-icon color="white">link</mat-icon> Visit our website
          </button>

          <!-- Report bug -->
          <button class="full_width button" (click)="reportABug()" mat-flat-button>
            <mat-icon color="white">bug_report</mat-icon> Report a Bug
          </button>

          <hr class="horizontalline-white">

          <div class="version">
            <span class="white">
              V 1.1.0
            </span>

          </div>

        </div>

        <!-- Vertical space -->
        <span class="spacer"></span>

        <!-- Buttons -->
        <div class="right-section">
          <!-- Settings Button -->
          <div class="text">
            <span class="title">About SpaceUp</span>
            <hr class="horizontalline">
            SpaceUP is a cloud-based space management solution that enables you to create a real-time, accurate view of the status of your real estate
            portfolio. Monitoring performance and compliance at all organizational levels, replacing manual processes with automation, SpaceUP provides
            information that is accurate, current and compliant.

          </div>

          <div class="text">
            <span class="title">About CBOS</span>
            <hr class="horizontalline">
            CBOS is a dynamic technology company committed to driving innovation and shaping the digital landscape, with a vision to empower businesses and
            transform industries. The CBOS vision is to enable, optimise, and streamline business operations and processes within and between organisations
            through technology innovation and cloud-based operating solutions.

          </div>

        </div>
      </div>

    </mat-card>
  </section>

</div>

<!-- <app-iframe></app-iframe> -->
