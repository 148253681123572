import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-column',
  templateUrl: './text-column.component.html',
  styleUrls: ['./text-column.component.scss']
})
export class TextColumnComponent implements OnInit, OnChanges {
  @Input() valueIn: string = '';

  value!: string;

  constructor() {}

  ngOnInit() {
    this.value = this.valueIn;
  }

  ngOnChanges() {
    this.value = this.valueIn;
  }
}
