import { Component, Input, OnInit } from '@angular/core';
import { Color, ScaleType } from '@swimlane/ngx-charts';
import { IPieGraph } from '../graph-Interfaces';

@Component({
  selector: 'app-advanced-pie-graph',
  templateUrl: './advanced-pie-graph.component.html',
  styleUrls: ['./advanced-pie-graph.component.scss']
})
export class AdvancedPieGraphComponent implements OnInit {
  @Input() graphSettings: IPieGraph = { data: [] };

  // Customize pie chart options
  view!: [number, number];

  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;

  dataSource: any;
  label: string = 'Total';
  height: number = 250;
  width: number = 400;
  title: string = '';

  colorScheme: Color = {
    name: 'cool', // or any other valid name
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#04172e', '#0059e6', '#335672', '#b7c3cd', '#37a8cb', '#57748b']
  };

  constructor() {}

  ngOnInit() {
    this.setProperties();
  }

  setProperties() {
    this.dataSource = this.graphSettings.data ?? this.dataSource;
    this.label = this.graphSettings.label ?? this.label;
    this.height = this.graphSettings.height ?? this.height;
    this.width = this.graphSettings.width ?? this.width;
    this.title = this.graphSettings.title ?? this.title;

    if (this.title != '') {
      this.height = this.height - 30;
    }
    this.view = [this.width, this.height];
  }

  // Function to format label
  labelFormatting(value: any): string {
    return `${value.label}: ${value.value}`;
  }

  // Function to handle click events
  onSelect(event: any): void {}

  onActivate(data: any): void {}

  onDeactivate(data: any): void {}
}
