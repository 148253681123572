import { Routes } from '@angular/router';
import { AuthGuard } from './core/services/auth-guard-service';
import { AppShellComponent } from './layout/app-shell/app-shell.component';
import { AuthShellComponent } from './layout/auth-shell/auth-shell.component';
import { ForgotComponent } from './layout/forgot/forgot.component';
import { LoginComponent } from './layout/login/login.component';
import { ResetComponent } from './layout/reset/reset.component';
import { PageNotFoundComponent } from './not-found.component';
import { UnauthorizedComponent } from './unauthorized.component';
import { SpaceLinkLoginComponent } from '@app/layout/space-link-login/space-link-login.component';

export const ROUTES: Routes = [
  {
    path: '',
    component: AuthShellComponent,
    children: [
      { path: '', component: LoginComponent },
      { path: 'forgotpassword', component: ForgotComponent },
      { path: 'resetpassword', component: ResetComponent }
    ]
  },
  {
    path: 'secure',
    component: AppShellComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        canLoad: [AuthGuard],
        loadChildren: () => import('./features/dashboard/dashboard.module').then((m) => m.DashboardModule),
        data: { title: 'Dashboard' }
      },
      {
        path: 'properties',
        canLoad: [AuthGuard],
        loadChildren: () => import('./features/e-portfolio/e-portfolio.module').then((m) => m.EPortfolio),
        data: { title: 'Properties' }
      },
      {
        path: 'sapoa',
        canLoad: [AuthGuard],
        loadChildren: () => import('./features/sapoa/sapoa.module').then((m) => m.SAPOAModule),
        data: { title: 'SAPOA' }
      },
      {
        path: 'lease',
        canLoad: [AuthGuard],
        loadChildren: () => import('./features/lease/lease.module').then((m) => m.LeaseModule),
        data: { title: 'leases' }
      },
      {
        path: 'reports',
        canLoad: [AuthGuard],
        loadChildren: () => import('./features/compliance/compliance.module').then((m) => m.ComplianceModule),
        data: { title: 'Compliance' }
      },
      {
        path: 'project',
        canLoad: [AuthGuard],
        loadChildren: () => import('./features/project/project.module').then((m) => m.ProjectModule),
        data: { title: 'Projects' }
      },
      {
        path: 'documents',
        canLoad: [AuthGuard],
        loadChildren: () => import('./features/document/document.module').then((m) => m.DocumentModule),
        data: { title: 'Documents' }
      },
      {
        path: 'settings',
        canLoad: [AuthGuard],
        loadChildren: () => import('./features/settings/settings.module').then((m) => m.SettingsModule),
        data: { title: 'Settings' }
      },
      {
        path: 'spacelink',
        component: SpaceLinkLoginComponent,
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  { path: '**', component: PageNotFoundComponent, pathMatch: 'full' }
];
