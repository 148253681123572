<mat-toolbar>
  <span>{{title}}</span>
</mat-toolbar>
<div class="wrapper">
  <div *ngIf="description" class="center">
    <p style="padding: 0px 10px;">{{description}}</p>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <button mat-button *ngFor="let element of actions" [mat-dialog-close]="element.optionValue">{{ element.optionLabel }}</button>
  </div>
</div>
