import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TableSettings } from '@app/shared/components/tree-table/classes/tableSettings';
import { ColumnSettings } from '@app/shared/components/tree-table/classes/columnSetting';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ISAPOAType, SapoaTypeEnum } from '@app/core/contracts/sapoaType';
import { IEProperty, IEBuilding, IEFloor, IEPocket } from '@app/core/contracts/eportfolio';

@Component({
  selector: 'app-property-builder',
  templateUrl: './property-builder.component.html',
  styleUrls: ['./property-builder.component.scss']
})
export class PropertyBuilderComponent {
  @Input({ required: true }) dataSource: MatTableDataSource<IEProperty>;
  @Input({ required: true }) tableSettings: TableSettings;
  @Input({ required: true }) columnSettings: ColumnSettings[];
  @Input({ required: true }) sapoaTypes!: ISAPOAType[];
  @Input() expandAll: boolean = false;
  @Output() saveProperty = new EventEmitter<IEProperty>();
  @Output() updateSaveRequired = new EventEmitter<boolean>();

  enum_SapoaType = SapoaTypeEnum;
  copiedObject: any;
  copiedObjectType: any;

  constructor(private snackbar: MatSnackBar) {
    this.dataSource = new MatTableDataSource();
    this.tableSettings = new TableSettings();
    this.columnSettings = [new ColumnSettings()];
  }

  handleIconClicked(event: any) {
    const action = event.option;
    const element = event.element;
    switch (action) {
      case 'addNewChild':
        var level = element.level;
        if (level.includes('[P]')) {
          this.updateSaveRequired.emit(true);
          this.addBuilding(element);
        } else if (level.includes('[B]')) {
          this.updateSaveRequired.emit(true);
          this.addFloor(element);
        } else if (level.includes('[FLR]')) {
          this.updateSaveRequired.emit(true);
          this.addPocket(element);
        }
        break;

      case 'delete':
        this.removeStructure(element);
        break;

      default:
        console.error('Unhandled option:', action);
        break;
    }
  }

  handleWogChanged(event: any) {
    const element = event.element;
    var level = element.level;
    if (level.includes('[B]')) {
      this.updateSaveRequired.emit(true);
      this.markBuildingChanged(element);
    } else if (level.includes('[FLR]')) {
      this.updateSaveRequired.emit(true);
      this.markFloorChanged(element);
    } else {
      this.updateSaveRequired.emit(true);
      this.markPocketChanged(element);
    }
  }

  handleMatOptionClicked(event: any) {
    const selected = event.option;
    const element = event.element;

    switch (selected) {
      case 'Paste Building':
        if (this.copiedObjectType == '[B]') {
          this.pasteBuilding();
        } else {
          this.showErrorMessage(`Cant paste ${this.copiedObject.name} into a ${element.name}`);
        }
        break;
      case 'Copy Building':
        this.copyBuilding(element);
        break;
      case 'Paste Floor':
        if (this.copiedObjectType == '[FLR]') {
          this.pasteFloor(element);
        } else {
          this.showErrorMessage(`Cant paste ${this.copiedObject.name} into a ${element.name}`);
        }
        break;
      case 'Copy Floor':
        this.copyFloor(element);
        break;
      default:
        console.error('Unhandled option:', selected);
        break;
    }
  }

  // save(){
  //     if (this.validateProperty() == true) {
  //         this.saveProperty.emit(this.dataSource.data[0]);
  //     }
  // }

  public validateProperty(): boolean {
    // Function to show error and return false
    const showErrorAndReturnFalse = (message: string): boolean => {
      this.showErrorMessage(message);
      return false;
    };

    for (const building of this.dataSource.data[0].children) {
      if (building.changed) {
        if (!building.name || building.name.trim().length === 0) {
          return showErrorAndReturnFalse('Please specify a building name.');
        }

        for (const floor of building.children) {
          if (floor.changed) {
            if (!floor.name || floor.name.trim().length === 0) {
              return showErrorAndReturnFalse(`Please specify a floor name in ${building.name}`);
            }

            for (const premises of floor.children) {
              if (premises.changed) {
                if (!premises.unitUsageId || premises.unitUsageId.trim().length === 0) {
                  return showErrorAndReturnFalse(`Please specify a Unit Usage for ${premises.name} on ${floor.name} in ${building.name}.`);
                }
                if (!premises.sapoaTypeId || premises.sapoaTypeId.trim().length === 0) {
                  return showErrorAndReturnFalse(`Please specify a Sapoa Type for ${premises.name} on ${floor.name} in ${building.name}.`);
                }
                const sapoaTypeCode = this.sapoaTypes.find((x) => x.id == premises.sapoaTypeId)?.code;
                if (sapoaTypeCode === this.enum_SapoaType.Usable) {
                  if (!premises.usableArea || premises.usableArea === 0) {
                    return showErrorAndReturnFalse(`Please specify a Usable Area for ${premises.name} on ${floor.name} in ${building.name}.`);
                  }
                } else if (sapoaTypeCode === this.enum_SapoaType.Supplementary) {
                  if (!premises.supplementaryArea || premises.supplementaryArea === 0) {
                    return showErrorAndReturnFalse(`Please specify a Supplementary Area for ${premises.name} on ${floor.name} in ${building.name}.`);
                  }
                } else {
                  if (!premises.commonArea || premises.commonArea === 0) {
                    return showErrorAndReturnFalse(`Please specify a Common Area for ${premises.name} on ${floor.name} in ${building.name}.`);
                  }
                }
              }
            }
          }
        }
      }
    }
    return true;
  }

  //#region Manipulate Structure
  private addBuilding(property: IEProperty) {
    // Find the property within the data source
    const parentProperty = this.dataSource.data.find((p) => p.id === property.id);

    if (!parentProperty) {
      this.showErrorMessage('An Error occurred while adding building. ');
      console.error('Property not found');
      return;
    }

    // Create a new building
    const emptyBuilding: IEBuilding = {
      id: crypto.randomUUID(),
      level: '[B]', // Example level identifier, you can adjust as needed
      name: '',
      rentableArea: 0,
      supplementaryArea: 0,
      lettableArea: 0,
      incomeGeneration: 0,
      usableArea: 0,
      commonArea: 0,
      changed: true,
      sequence: 0,
      children: []
    };

    // Add the new building to the property's children
    parentProperty.children.push(emptyBuilding);
    this._snackBar(`Building ${emptyBuilding.name} added to ${parentProperty.name}`);
  }

  private addFloor(building: IEBuilding) {
    // Find the property that contains the specified building
    const property = this.dataSource.data.find((p) => p.children.some((b) => b.id === building.id));

    if (!property) {
      this.showErrorMessage('An Error occurred while adding floor. ');

      console.error('Property not found');
      return;
    }

    // Find the building within the property
    const parentBuilding = property.children.find((b) => b.id === building.id);

    if (!parentBuilding) {
      this.showErrorMessage('An Error occurred while adding floor. ');

      console.error('Building not found');
      return;
    }

    // Create a new floor
    const emptyFloor: IEFloor = {
      id: crypto.randomUUID(),
      level: '[FLR]', // Example level identifier, you can adjust as needed
      name: '',
      rentableArea: 0,
      supplementaryArea: 0,
      lettableArea: 0,
      incomeGeneration: 0,
      usableArea: 0,
      commonArea: 0,
      changed: true,
      sequence: parentBuilding.children.length + 1, // Sequence number
      children: []
    };

    // Add the new floor to the building's children
    parentBuilding.children.push(emptyFloor);
    this._snackBar(`Floor ${emptyFloor.name} added to ${parentBuilding.name}`);

    parentBuilding.changed = true;
  }

  addPocket(floor: IEFloor) {
    // Find the property that contains the building that contains the specified floor
    const property = this.dataSource.data.find((p) => p.children.some((b) => b.children.some((f) => f.id === floor.id)));

    if (!property) {
      this.showErrorMessage('An Error occurred while adding premises. ');
      console.error('Property not found');
      return;
    }

    // Find the building within the property that contains the specified floor
    const building = property.children.find((b) => b.children.some((f) => f.id === floor.id));

    if (!building) {
      this.showErrorMessage('An Error occurred while adding premises. ');
      console.error('Building not found');
      return;
    }

    // Find the floor within the building
    const parentFloor = building.children.find((f) => f.id === floor.id);

    if (!parentFloor) {
      this.showErrorMessage('An Error occurred while adding premises. ');
      console.error('Floor not found');
      return;
    }
    var emptyPocket: IEPocket;
    let newPocketNumber = parseInt(parentFloor.children[parentFloor.children.length - 1]?.name.replace("P", "") ?? 0) + 1
    let newPocketName = `P${newPocketNumber}`;
    if (property?.measurementModelCode == 2) {
      let usableSapoaType = this.sapoaTypes.find((x: any) => x.code == this.enum_SapoaType.Usable);
      // Create a new pocket
      emptyPocket = {
        id: crypto.randomUUID(),
        level: '',
        name: newPocketName,
        linkedPocket: '',
        linkedPocketId: undefined,
        unitUsage: '',
        unitUsageId: '',
        sapoaType: usableSapoaType?.name ?? '',
        sapoaTypeCode: `${usableSapoaType?.code}`,
        sapoaTypeId: usableSapoaType?.id ?? '',
        rentableArea: 0,
        supplementaryArea: 0,
        lettableArea: 0,
        incomeGeneration: 0,
        commonArea: 0,
        usableArea: 0,
        sequence: parentFloor.children.length + 1,
        isActive: true,
        changed: true
      };
    } else {
      // Create a new pocket
      emptyPocket = {
        id: crypto.randomUUID(),
        level: '',
        name: newPocketName,
        linkedPocket: '',
        linkedPocketId: undefined,
        unitUsage: '',
        unitUsageId: '',
        sapoaType: '',
        sapoaTypeCode: '',
        sapoaTypeId: '',
        rentableArea: 0,
        supplementaryArea: 0,
        lettableArea: 0,
        incomeGeneration: 0,
        commonArea: 0,
        usableArea: 0,
        sequence: parentFloor.children.length + 1,
        isActive: true,
        changed: true
      };
    }

    // Add the new pocket to the floor's children
    parentFloor.children.push(emptyPocket);
    this._snackBar(`Premises ${emptyPocket.name} added to ${parentFloor.name}`);

    parentFloor.changed = true;
    building.changed = true;
  }

  private removeStructure(selected: any) {
    this.showWarningDialog(`Are you sure you want to remove ${selected.name} , you will lose all related data?`).then((confirmed) => {
      if (confirmed) {
        this.updateSaveRequired.emit(true);
        selected.remove = true;
        selected.changed = true;
        if (selected.children) {
          selected.children.forEach((child: any) => {
            child.remove = true;
            child.changed = true;
            if (child.children) {
              child.children.forEach((loweChild: any) => {
                loweChild.remove = true;
                loweChild.changed = true;
                if (loweChild.children) {
                  loweChild.children.forEach((lowestChild: any) => {
                    lowestChild.remove = true;
                    lowestChild.changed = true;
                  });
                }
              });
            }
          });
        }
      } else {
        //  do not cancel
      }
    });
  }

  markBuildingChanged(building: IEBuilding) {
    // Find the property that contains the specified building
    const property = this.dataSource.data[0];
    if (!property) {
      this.showErrorMessage('An Error occurred while changing building. Could not find Property.');
      console.error('Property not found');
      return;
    }

    // Find the building within the property
    const _building = property.children.find((b) => b.id === building.id);

    if (!_building) {
      this.showErrorMessage('An Error occurred while changing building. Could not find Building.');
      console.error('Building not found');
      return;
    }

    _building.changed = true;
  }

  markFloorChanged(floor: IEFloor) {
    // Find the property that contains the building that contains the specified floor
    const property = this.dataSource.data[0];
    if (!property) {
      this.showErrorMessage('An Error occurred while changing floor. Could not find Property.');
      console.error('Property not found');
      return;
    }
    // Find the building within the property that contains the specified floor
    const building = property.children.find((b) => b.children.some((f) => f.id === floor.id));

    if (!building) {
      this.showErrorMessage('An Error occurred while changing floor. Could not find Building.');
      console.error('Building not found');
      return;
    }

    // Find the floor within the building
    const _floor = building.children.find((f) => f.id === floor.id);

    if (!_floor) {
      this.showErrorMessage('An Error occurred while changing floor. Could not find Floor.');
      console.error('Floor not found');
      return;
    }

    building.changed = true;
    _floor.changed = true;
  }

  markPocketChanged(pocket: IEPocket) {
    // Find the property that contains the building that contains the specified pocket
    const property = this.dataSource.data[0];

    if (!property) {
      this.showErrorMessage('An Error occurred while changing premises. Could not find Property.');
      console.error('Property not found');
      return;
    }

    // Find the building within the property that contains the specified pocket
    const building = property.children.find((b) => b.children.some((f) => f.children.some((po) => po.id === pocket.id)));
    if (!building) {
      this.showErrorMessage('An Error occurred while changing premises. Could not find Building.');
      console.error('Building not found');
      return;
    }

    // Find the floor within the building that contains the specified pocket
    const floor = building.children.find((f) => f.children.some((po) => po.id === pocket.id));
    if (!floor) {
      this.showErrorMessage('An Error occurred while changing premises. Could not find Floor.');
      console.error('Floor not found');
      return;
    }

    const _pocket = floor.children.find((p) => p.id === pocket.id);
    if (!_pocket) {
      this.showErrorMessage('An Error occurred while changing premises. Could not find Premises.');
      console.error('Pocket not found');
      return;
    }

    building.changed = true;
    floor.changed = true;
    _pocket.changed = true;
  }

  copyBuilding(selected: IEBuilding) {
    const holder = structuredClone(selected); // Deep clone the object
    holder.id = crypto.randomUUID();
    holder.children.forEach((floor) => {
      floor.id = crypto.randomUUID();
      floor.children.forEach((premises) => {
        premises.id = crypto.randomUUID();
        premises.linkedPocket = '';
        premises.linkedPocketId = undefined;
      });
    });
    this.copiedObject = holder;
    this.copiedObjectType = '[B]';
  }

  pasteBuilding() {
    if (this.copiedObjectType === '[B]') {
      const parentProperty = this.dataSource.data[0];
      parentProperty.children.push(this.copiedObject);
      this.dataSource.data = [...this.dataSource.data]; // Trigger data refresh
      this.copiedObjectType = '';
      this.copiedObject = null;
      this.updateSaveRequired.emit(true);
    } else {
      console.error('Copied object is not a building');
    }
  }

  copyFloor(selected: IEFloor) {
    const holder = structuredClone(selected); // Deep clone the object
    holder.id = crypto.randomUUID();
    holder.children.forEach((premises) => {
      premises.id = crypto.randomUUID();
      premises.linkedPocket = '';
      premises.linkedPocketId = undefined;
    });
    this.copiedObject = holder;
    this.copiedObjectType = '[FLR]';
  }

  pasteFloor(selected: IEBuilding) {
    if (this.copiedObjectType === '[FLR]') {
      const parentBuilding = this.dataSource.data[0].children.find((b) => b.id === selected.id);
      if (parentBuilding) {
        parentBuilding.children.push(this.copiedObject);
        this.dataSource.data = [...this.dataSource.data]; // Trigger data refresh
        this.copiedObjectType = '';
        this.copiedObject = null;
        this.updateSaveRequired.emit(true);
      } else {
        console.error('Parent building not found');
      }
    } else {
      console.error('Copied object is not a floor');
    }
  }
  //#endregion

  //#region Utils
  private showErrorMessage(title: string): void {
    Swal.fire({
      position: 'center',
      icon: 'error',
      title: 'Error',
      text: title
    });
  }

  _snackBar(message: string) {
    this.snackbar.open(message, 'Ok', { duration: 3000 });
  }

  private showWarningDialog(message: string): Promise<boolean> {
    return Swal.fire({
      title: 'Warning',
      text: message,
      icon: 'warning',
      confirmButtonText: 'Ok',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result: SweetAlertResult) => {
      return result.isConfirmed;
    });
  }

  //#endregion
}
