<h2 mat-dialog-title>Updating Warehouse</h2>
<div mat-dialog-content>
  <div *ngFor="let step of filteredSteps; let i = index">
    <div class="step">
      <mat-icon class="status-icon" [ngClass]="{'success': step.success, 'failure': step.failure, 'in-progress': step.inProgress}">
        {{ step.success ? 'check_circle' : (step.failure ? 'cancel' : 'schedule') }}
      </mat-icon>
      <span class="step-text">{{ step.text }}</span>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="closeDialog()">Close</button>
</div>
