

<section fxLayout="row" *ngIf="wog_settingsIn.level?.includes(this.currentLevel) && readOnly === false">
  <input
    [class]="columnIn.align === textAlign.Left?'input left':columnIn.align === textAlign.Right?'input right':'input center'"
    [(ngModel)]="this.elementIn[this.columnIn.columnName]"
    (ngModelChange)="changed()"
    type="number"
    id="uname"
    name="name"
    (change)="changesMade = true;"
    size="4" />
</section>

<!-- Normal Text value (No Editor) -->
<section
  *ngIf="numberTypes.currency === settings.numberType && !wog_settingsIn.level?.includes(this.currentLevel) ||numberTypes.currency === settings.numberType && readOnly === true ">
  {{ settings.prefix }} {{ value | customCurrency }}{{ settings.suffix }}
</section>
<section
  *ngIf="numberTypes.decimal === settings.numberType && !wog_settingsIn.level?.includes(this.currentLevel) || numberTypes.decimal === settings.numberType && readOnly === true ">
  {{settings.prefix}} {{value| customDecimal | number : '1.0-0'}} {{settings.suffix}}
</section>
<section
  *ngIf="numberTypes.int === settings.numberType && !wog_settingsIn.level?.includes(this.currentLevel) || numberTypes.int === settings.numberType && readOnly === true ">
  {{settings.prefix}} {{value}} {{settings.suffix}}
</section>
