import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class HttpValidParamsInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestParams: HttpParams = request.params;
    const resRequest = request.clone({
      params: this.getParamsObject(requestParams),
      url: request.url.replace('undefined', '').replace('null', '')
    });

    return next.handle(resRequest);
  }

  getParamsObject(params: HttpParams): HttpParams {
    for (const key of params.keys()) {
      if (params.get(key) === undefined || params.get(key) == null) {
        params = params.delete(key, undefined);
      }
    }

    return params;
  }
}
