<div class="wrapper">
  <mat-toolbar color="primary">
    <mat-icon class="custoIcon">list_alt</mat-icon>
    <mat-card-title style="color: white;">Reorder {{property.name}} Structure</mat-card-title>
    <span class="spacer"></span>

    <button color="primary" style="margin-left: 10px;margin-top: 10px;" (click)="showPremises=!showPremises" mat-flat-button>
      <mat-icon>{{showPremises?'expand':'unfold_less'}}</mat-icon> Toggle Premises
    </button>
    <button style="color: white;" (click)="saveChanges()" mat-icon-button hint="done">
      <mat-icon>done</mat-icon>
    </button>
  </mat-toolbar>

  <mat-dialog-content class="full-width">
    <div class="grid-container" cdkDropListGroup>
      <div class="grid-building"
        *ngIf="property.children"
        cdkDropList
        [cdkDropListData]="property.children"
        (cdkDropListDropped)="dropBuilding($event,property)">

        <!-- Apply cdkDrag to the entire building, including its children -->

        <div class="grid-building" *ngFor="let building of property.children; trackBy: trackById" cdkDrag>
          <div class="grid-item building-item">{{ building.name }}</div>
          <!-- Floors inside the building -->
          <div class="grid-floor-container"
            *ngIf="building.children"
            cdkDropList
            [cdkDropListData]="building.children"
            (cdkDropListDropped)="dropFloor($event, building)">

            <div class="grid-floor" *ngFor="let floor of building.children; trackBy: trackById" cdkDrag>
              <div class="grid-item floor-item">{{ floor.name }}</div>
              <!-- Pockets inside the floor -->
              <div *ngIf="showPremises && floor.children" class="grid-pocket-container"
                cdkDropList
                [cdkDropListData]="floor.children"
                (cdkDropListDropped)="dropPocket($event, floor)">

                <div class="grid-pocket" *ngFor="let pocket of floor.children; trackBy: trackById" cdkDrag>
                  <div class="grid-item pocket-item">{{ pocket.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
