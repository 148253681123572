import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IconSettings } from '../../../classes/iconSettings';
import { SelectorSettings } from '../../../classes/selectorSettings';

@Component({
  selector: 'app-selector-column',
  templateUrl: './selector-column.component.html',
  styleUrls: ['./selector-column.component.scss']
})
export class SelectorColumnComponent implements OnInit, OnChanges {
  @Input() selectorIn!: SelectorSettings;
  @Input() currentLevel: number = 1;
  @Input() selectedIds: any[] = [];

  @Input() toggleSelected!: boolean | undefined;
  @Output() selectedChanged = new EventEmitter<any>();
  @Input() elementIn: any = '';

  selector!: SelectorSettings;
  selected = false;

  constructor() {}

  ngOnInit() {
    if (this.elementIn.selector) {
      this.selected = this.elementIn.selector;
    }
    this.selector = this.selectorIn;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('toggleSelected' in changes) {
      const newValue = changes.toggleSelected.currentValue;
      if (newValue !== undefined) {
        this.selected = newValue;
      }
    }
    if ('selectedIds' in changes) {
      this.selected = changes.selectedIds.currentValue.includes(this.elementIn.id);
    }
  }

  toggleClicked() {
    if (this.selected === true) {
      this.selectedChanged.emit({ action: 'de_selected', element: this.elementIn });
      this.selected = false;
    } else {
      this.selectedChanged.emit({ action: 'selected', element: this.elementIn });
      this.selected = true;
    }
  }
}
