import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { columnTypes, textAlign } from '../../../enums/columnSettings';
import { ColumnSettings } from '../../../classes/columnSetting';
import { TableSettings } from '../../../classes/tableSettings';
import { SelectorColumnComponent } from '../selector-column/selector-column.component';

@Component({
  selector: 'app-column-controller',
  templateUrl: './column-controller.component.html',
  styleUrls: ['./column-controller.component.scss']
})
export class ColumnControllerComponent implements OnChanges {
  @ViewChild(SelectorColumnComponent) selector!: SelectorColumnComponent;
  @Input() parentData: any;
  @Input() column: any;
  @Input() element: any;
  @Input() currentLevel: number = 1;
  @Input() tableSettings: TableSettings = new TableSettings();
  @Input() toggleSelectedIn!: boolean | undefined;
  @Input() refreshBit: number = 0;
  @Input() selectedIds: any[] = [];

  @Output() hyperlinkClicked = new EventEmitter<any>();
  @Output() matOptionClicked = new EventEmitter<any>();
  @Output() rowExpand = new EventEmitter<any>();
  @Output() iconClicked = new EventEmitter<any>();
  @Output() buttonClicked = new EventEmitter<any>();
  @Output() selectedChanged = new EventEmitter<any>();
  @Output() wog_dropDownSelectionChanged = new EventEmitter<any>();
  @Output() wog_numericFieldChanged = new EventEmitter<any>();
  @Output() wog_textFieldChanged = new EventEmitter<any>();

  columnTypes = columnTypes;
  textAlign = textAlign;
  toggleSelected: boolean | undefined;

  ngOnChanges(changes: SimpleChanges) {
    if ('toggleSelectedIn' in changes) {
      const newValue = changes.toggleSelectedIn.currentValue;
      if (newValue !== undefined) {
        this.toggleSelected = newValue;
      }
    }
  }

  getElementStyle(element: any, column: ColumnSettings) {
    var elementStyle = column.elementStyle;
    var style1 = column.conditionalElementStyle ? column.conditionalElementStyle(element) : '';
    return style1 + elementStyle;
  }
}
