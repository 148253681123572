
<div class="layout">
  <mat-toolbar color="primary">
    <mat-icon class="custoIcon">recent_actors</mat-icon>
    <span>Assign Properties for {{UserName}}</span>
    <span class="spacer"></span>
    <button mat-icon-button class="icon" hint="save" (click)="saveClientProfile()">
      <mat-icon>save</mat-icon>
    </button>
    <button mat-icon-button class="icon" mat-dialog-close hint="cancel">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <hr class="horizontalline">
  <div fxLayout="row" class="container">
    <mat-card appearance="outlined" class="leftcol custom-card">
      <mat-card-title>
        <div fxLayout="row" fxLayoutAlign="space-between">
          {{UserName}} Overview
        </div>
      </mat-card-title>
      <mat-card-subtitle>
        List of Selected Properties
      </mat-card-subtitle>
      <mat-card-content [class]="'scroll'">
        <hr class="horizontalline">
        <div fxLayout="column">
          <mat-list>
            <mat-list-item *ngFor="let property of SelectedProperties">
              <mat-icon class="DisableIcon" mat-list-icon>home</mat-icon>
              <div class="subheaderTextDisable" mat-line> {{property}} </div>
            </mat-list-item>
          </mat-list>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined" class="rightcol custom-card">
      <mat-card-title>
        <div fxLayout="row" fxLayoutAlign="space-between">
          Client Profile Tree
        </div>
      </mat-card-title>
      <mat-card-subtitle>Select the tree values applicable for the {{UserName}}</mat-card-subtitle>
      <mat-card-content [class]="'scroll'">
        <hr class="horizontalline">
        <div fxLayout="row" fxLayoutAlign="space-between">
          &nbsp;
          <button *ngIf="!this.treecollapsed" class="planingModeButton" mat-icon-button
            (click)="closeTree()">
            <mat-icon>unfold_more</mat-icon>
          </button>
          <button *ngIf="this.treecollapsed" class="planingModeButton" mat-icon-button
            (click)="expandTree()">
            <mat-icon>unfold_less</mat-icon>
          </button>
        </div>
        <mat-tree #tree [dataSource]="dataSource" [treeControl]="treeControl">
          <!-- This is the tree node template for leaf nodes -->
          <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
            <!-- use a disabled button to provide padding for tree leaf -->
            <button mat-icon-button disabled><mat-icon class="HouseIcon">
                {{'remove'}}
              </mat-icon> </button>

            <button (click)="SelectTreeChanged(node)" [class]="SelectedTree.includes(node.id)?
              'selectedTree':node.isProperty?'Property':''"
              mat-button
              color="primary">
              <mat-icon *ngIf="node.isProperty" class="HouseIcon">
                {{'home'}}
              </mat-icon> {{node.name}}</button>
          </mat-tree-node>
          <!-- This is the tree node template for expandable nodes -->
          <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
            <button mat-icon-button matTreeNodeToggle
              [attr.aria-label]="'Toggle ' + node.name">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            <button (click)="SelectTreeChanged(node)" [class]="SelectedTree.includes(node.id)?
              'selectedTree':''"
              mat-button
              color="primary">{{node.name}}</button>
          </mat-tree-node>
        </mat-tree>
      </mat-card-content>
    </mat-card>

  </div>
</div>
