import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss']
})
export class CustomTooltipComponent {
  @Input() type: any;
  @Input() id!: string;

  showTooltip = false;

  constructor() {}

  show() {
    this.showTooltip = true;
  }

  hide() {
    this.showTooltip = false;
  }
}
