import { Component, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { Subject, from } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AuthService } from './core/services/auth-service';

@Component({
  selector: 'app-root',
  template: '<div class="mat-typography" style="height:100%"><router-outlet></router-outlet></div>'
})
export class AppComponent implements OnDestroy {
  componentDestroyed$: Subject<boolean> = new Subject();

  public constructor(private titleService: Title, private authService: AuthService, private router: Router) {
    this.titleService.setTitle('SpaceUP - v' + environment.version);

    from(this.authService.authenticated()).subscribe((val) => {
      if (val && this.router.url === '') {
        this.router.navigate(['/secure/dashboard']);
      }
    });

    this.authService.authenticated().then((val) => {
      if (val && this.router.url === '') {
        this.router.navigate(['/secure/dashboard']);
      }
    });
  }
  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
