import { Component, Inject, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-tooltip-dialog',
  templateUrl: './tooltip-dialog.component.html',
  styleUrls: ['./tooltip-dialog.component.scss']
})
export class TooltipDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { content: Array<{ icon: string; hoverText: string; color: string }> }, private dialogRef: MatDialogRef<TooltipDialogComponent>) {}

  @HostListener('mouseleave') onMouseLeave() {
    this.dialogRef.close();
  }
}
