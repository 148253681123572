import { AfterContentChecked, AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { IStaticFilter } from '@app/core/contracts/common';

export interface ColumnItem {
  value: string;
}

export interface dropdownObj {
  dropdownOpen: boolean;
  column: string;
}

@Component({
  selector: 'app-multi-select-filter',
  templateUrl: './multi-select-filter.component.html',
  styleUrls: ['./multi-select-filter.component.scss']
})
export class MultiSelectFilterComponent implements OnChanges {
  @Input() source!: IStaticFilter[];
  @Input() placeHolder!: string;
  @Input() matIcon!: string;
  @Input() label!: string;
  @Input() defaultValue!: string[];
  @Output() SelectedIdsChanged: EventEmitter<string[]> = new EventEmitter<string[]>();
  sourceFilter: any;
  searchText = '';
  ShowAllText = false;
  availableOptions!: IStaticFilter[];
  selectAll = false;

  selectedOptions!: IStaticFilter[];
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['source']) {
      this.availableOptions = changes.source.currentValue;
    }
    if (changes['defaultValue']) {
      if (changes.defaultValue.currentValue) {
        if (changes.defaultValue.currentValue.length != 0) {
          this.sourceFilter = changes.defaultValue.currentValue;
          this.selectionChanged();
        }
      }
    }
  }

  selectionChanged(): void {
    if (this.availableOptions && this.sourceFilter) {
      if (this.availableOptions.length == this.sourceFilter.length && this.sourceFilter.length != 0) {
        this.ShowAllText = true;
      } else {
        this.ShowAllText = false;
      }
    }

    this.SelectedIdsChanged.emit(this.sourceFilter);
  }

  SelectAll() {
    this.selectAll = !this.selectAll;
    if (this.selectAll == true) {
      this.ShowAllText = true;

      var ids = this.source.map(function (val) {
        return val.id;
      });
      this.sourceFilter = ids;
    } else {
      this.ShowAllText = false;

      this.sourceFilter = [];
    }

    this.selectionChanged();
  }

  updateSearchText(event: any) {
    if (event.target.value != '') {
      this.availableOptions = this.source.filter((x) => x.value.toString().toLowerCase().includes(event.target.value.toLowerCase()));
    } else {
      this.availableOptions = this.source;
    }
  }
}
