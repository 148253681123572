import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Color, ScaleType } from '@swimlane/ngx-charts';
import { IVerticalBarChart } from '../graph-Interfaces';

@Component({
  selector: 'app-vertical-bar-chart',
  templateUrl: './vertical-bar-chart.component.html',
  styleUrls: ['./vertical-bar-chart.component.scss']
})
export class VerticalBarCartComponent implements OnInit, OnChanges {
  @Input() graphSettings: IVerticalBarChart = { data: [] };

  // Customize pie chart options
  view!: [number, number];

  colorScheme: Color = {
    name: 'cool', // or any other valid name
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#04172e', '#0059e6', '#335672', '#b7c3cd', '#37a8cb', '#57748b']
  };

  maxWidth: number = 100000;
  minWidth: number = 0;
  width: number = 0;
  height: number = 0;
  yName: string = '';
  xName: string = '';
  dataSource: any;
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Country';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = 'Population';
  title: string = '';

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['graphSettings']) {
      this.setProperties();
    }
  }

  ngOnInit() {
    this.setProperties();
  }

  setProperties() {
    this.maxWidth = this.graphSettings.maxWidth ?? this.maxWidth;
    this.minWidth = this.graphSettings.minWidth ?? this.minWidth;
    this.width = this.graphSettings.width ?? this.width;
    this.height = this.graphSettings.height ?? this.height;
    this.yName = this.graphSettings.yName ?? this.yName;
    this.xName = this.graphSettings.xName ?? this.xName;
    this.dataSource = this.graphSettings.data ?? this.dataSource;
    this.showXAxis = this.graphSettings.showXAxis ?? this.showXAxis;
    this.showYAxis = this.graphSettings.showYAxis ?? this.showYAxis;
    this.gradient = this.graphSettings.gradient ?? this.gradient;
    this.showLegend = this.graphSettings.showLegend ?? this.showLegend;
    this.showXAxisLabel = this.graphSettings.showXAxisLabel ?? this.showXAxisLabel;
    this.xAxisLabel = this.graphSettings.xAxisLabel ?? this.xAxisLabel;
    this.showYAxisLabel = this.graphSettings.showYAxisLabel ?? this.showYAxisLabel;
    this.yAxisLabel = this.graphSettings.yAxisLabel ?? this.yAxisLabel;
    this.title = this.graphSettings.title ?? this.title;

    if (this.title != '') {
      this.height = this.height - 30;
    }
    this.setWidth();
  }

  setWidth() {
    if (this.minWidth >= this.width) {
      this.view = [this.minWidth, this.height];
    } else if (this.maxWidth <= this.width) {
      this.view = [this.maxWidth, this.height];
    } else {
      this.view = [this.width, this.height];
    }
  }

  // Function to format label
  labelFormatting(value: any): string {
    return `${value.label}: ${value.value}`;
  }

  // Function to handle click events
  onSelect(event: any): void {}
}
