import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Color, ScaleType } from '@swimlane/ngx-charts';
import { columnTypes, textAlign } from '../../enums/columnSettings';
import { ColumnSettings } from '../../classes/columnSetting';
import { PaginatorOptions } from '../../classes/paginator';
import { TableSettings } from '../../classes/tableSettings';
import { ColumnControllerComponent } from '../columns/column-controller/column-controller.component';
import {
  blank,
  complianceStatus,
  dataManagementStatus,
  iconProperties,
  IconPropertyKey,
  instructionStatus,
  leaseStatus,
  PCS,
  propertyStatus,
  tenancyStatus,
  trueFalse,
  UserActiveStatus,
  UserLockStatus
} from '../../enums/iconEnums';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { color } from 'd3';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnChanges {
  @ViewChild(ColumnControllerComponent) columns!: ColumnControllerComponent;
  @Input() selectedIds: any[] = [];
  @Input() dataSourceIn: any;
  @Input() tableSettingsIn: TableSettings = new TableSettings();
  @Input() displayedColumnsIn: ColumnSettings[] = [];
  @Input() paginatorOptionsIn: PaginatorOptions = new PaginatorOptions();
  @Input() toggleSelectAll: boolean | undefined;

  @Input() showPaginator: boolean = true;
  @Input() treeTable: boolean = false;
  @Input() expandAll: boolean = false;

  @Output() hyperlinkClicked = new EventEmitter<any>();
  @Output() matOptionClicked = new EventEmitter<any>();
  @Output() iconClicked = new EventEmitter<any>();
  @Output() buttonClicked = new EventEmitter<any>();
  @Output() selectedChanged = new EventEmitter<any>();
  @Output() wog_dropDownSelectionChanged = new EventEmitter<any>();
  @Output() wog_numericFieldChanged = new EventEmitter<any>();
  @Output() wog_textFieldChanged = new EventEmitter<any>();

  private paginator!: MatPaginator;
  selectAll: boolean | undefined;
  refreshBit = 0;
  expandedElement: any | null;

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    if (mp) {
      this.paginator = mp;
      this.dataSource.paginator = this.paginator;
      this.cdRef.detectChanges();
    }
  }

  columnTypes = columnTypes;
  textAlign = textAlign;

  displayedColumns!: ColumnSettings[];
  columnsToDisplay: string[] = [];

  dataSource: MatTableDataSource<any>;
  tableSettings!: TableSettings;

  statusEnumMap: { [key: string]: any } = {
    tenancyStatus,
    instructionStatus,
    complianceStatus,
    dataManagementStatus,
    leaseStatus,
    PCS,
    propertyStatus,
    trueFalse,
    blank,
    UserActiveStatus,
    UserLockStatus
    // Add other enums here...
  };

  constructor(private cdRef: ChangeDetectorRef, private sanitizer: DomSanitizer) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.dataSource.data = this.dataSourceIn;
    this.tableSettings = this.tableSettingsIn;
    this.displayedColumns = this.displayedColumnsIn;
    this.columnsToDisplay = this.displayedColumns.map((column) => column.columnName);
    if (this.toggleSelectAll != undefined) {
      this.selectAll = this.toggleSelectAll;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataSourceIn) {
      this.dataSource.data = this.dataSourceIn;
    }
    if (changes.displayedColumnsIn) {
      this.displayedColumns = this.displayedColumnsIn;
      this.columnsToDisplay = this.displayedColumns.map((column) => column.columnName);
    }
    if (changes.tableSettingsIn) {
      this.tableSettings = this.tableSettingsIn;
    }

    if (changes.expandAll) {
      this.setAllExpanded(this.dataSource.data, this.expandAll);
    }

    if (changes.toggleSelectAll) {
      if (this.toggleSelectAll != undefined) {
        this.selectAll = this.toggleSelectAll;
      }
    }
  }

  private setAllExpanded(elements: any[], expanded: boolean): void {
    elements.forEach((element) => {
      element.expanded = expanded;
      if (element.children) {
        this.setAllExpanded(element.children, expanded);
      }
    });
  }

  trackById(index: number, item: any): any {
    return item.id;
  }

  getIconsAndTooltips(iconEnum?: string): Array<{ icon: string; hoverText: string; color: string }> {
    const result = [];

    if (iconEnum) {
      const statusEnum = this.statusEnumMap[iconEnum];

      if (statusEnum) {
        for (const [key, value] of Object.entries(statusEnum)) {
          const iconProperty = iconProperties[key as keyof typeof iconProperties];
          if (iconProperty) {
            result.push({
              icon: iconProperty.icon,
              hoverText: iconProperty.hoverText,
              color: iconProperty.color
            });
          }
        }
      }
    }

    return result;
  }

  getTableStyle() {
    var backgroundColor = this.tableSettings.backgroundColor;
    var settings = `background-color:${backgroundColor}`;
    return settings;
  }

  getHeaderStyle(column: ColumnSettings) {
    var backgroundColor = this.tableSettings.column_Styling?.backgroundColor;
    var color = this.tableSettings.column_Styling?.color;
    var fontSize = this.tableSettings.column_Styling?.fontSize;
    var fontWeight = this.tableSettings.column_Styling?.fontWeight;

    var settings = `background-color:${backgroundColor};font-weight:${fontWeight};color:${color};font-size:${fontSize} ;`;
    var style1 = column.headerStyle ? column.headerStyle : '';
    var style2 = column.conditionalHeaderStyle ? column.conditionalHeaderStyle(column) : '';
    return settings + style1 + style2;
  }

  getElementStyle(element: any, column: ColumnSettings) {
    var style1 = column.align;
    var style2 = column.elementStyle ? column.elementStyle : '';
    // var style3 = column.conditionalElementStyle ? column.conditionalElementStyle(element[column.columnName]) : '';
    return style1 + style2;
  }

  getRow1_Styling(column: ColumnSettings) {
    var backgroundColor = this.tableSettings.row1_Styling?.backgroundColor;
    var color = this.tableSettings.row1_Styling?.color;
    var fontSize = this.tableSettings.row1_Styling?.fontSize;
    var fontWeight = this.tableSettings.row1_Styling?.fontWeight;

    return `background-color:${backgroundColor};font-weight:${fontWeight};color:${color};font-size:${fontSize} ;${column.align}`;
  }

  getRow2_Styling(column: ColumnSettings) {
    var backgroundColor = this.tableSettings.row2_Styling?.backgroundColor;
    var color = this.tableSettings.row2_Styling?.color;
    var fontSize = this.tableSettings.row2_Styling?.fontSize;
    var fontWeight = this.tableSettings.row2_Styling?.fontWeight;

    return `background-color:${backgroundColor};font-weight:${fontWeight};color:${color};font-size:${fontSize} ;${column.align}`;
  }

  getRow3_Styling(column: ColumnSettings) {
    var backgroundColor = this.tableSettings.row3_Styling?.backgroundColor;
    var color = this.tableSettings.row3_Styling?.color;
    var fontSize = this.tableSettings.row3_Styling?.fontSize;
    var fontWeight = this.tableSettings.row3_Styling?.fontWeight;

    return `background-color:${backgroundColor};font-weight:${fontWeight};color:${color};font-size:${fontSize} ;${column.align}`;
  }

  getRow4_Styling(column: ColumnSettings) {
    var backgroundColor = this.tableSettings.row4_Styling?.backgroundColor;
    var color = this.tableSettings.row4_Styling?.color;
    var fontSize = this.tableSettings.row4_Styling?.fontSize;
    var fontWeight = this.tableSettings.row4_Styling?.fontWeight;

    return `background-color:${backgroundColor};font-weight:${fontWeight};color:${color};font-size:${fontSize} ;${column.align}`;
  }

  catchEvent(Type: string, event: any) {
    if (Type.includes('wog')) {
      if (event.refresh === true) {
        this.refreshWogComponents();
      }
    }
  }

  refreshWogComponents() {
    this.refreshBit = this.refreshBit + 1;
  }

  //Events
}
