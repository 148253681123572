import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Color, ScaleType } from '@swimlane/ngx-charts';
import { numberTypes } from '../../../enums/columnSettings';
import { NumberSettings } from '../../../classes/numberSettings';

@Component({
  selector: 'app-number-column',
  templateUrl: './number-column.component.html',
  styleUrls: ['./number-column.component.scss']
})
export class NumberColumnComponent implements OnInit, OnChanges {
  @Input() valueIn: number = 0;
  @Input() settingsIn: NumberSettings = new NumberSettings();
  value!: number;
  settings!: NumberSettings;
  numberTypes = numberTypes;

  constructor() {}

  ngOnInit() {
    this.value = this.valueIn;
    this.settings = this.settingsIn;
  }

  ngOnChanges() {
    this.value = this.valueIn;
    this.settings = this.settingsIn;
  }
}
