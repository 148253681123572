
<input
  *ngIf="wog_settingsIn.level?.includes(this.currentLevel) && readOnly === false"
  [class]="columnIn.align === textAlign.Left?'input left':columnIn.align === textAlign.Right?'input right':'input center'"
  [(ngModel)]="this.elementIn[this.columnIn.columnName]"
  (ngModelChange)="changed()"
  [disabled]="isDisabled(this.elementIn,wog_settingsIn)"
  type="text"
  id="uname"
  name="name"
  (change)="changesMade = true;"
  size="4" />

<ng-container *ngIf="!wog_settingsIn.level?.includes(this.currentLevel) ||  readOnly === true">
  {{this.elementIn[this.columnIn.columnName]}}
</ng-container>
