import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-error-snack-bar',
  templateUrl: './error-snack-bar.html',
  styleUrls: ['./error-snack-bar.scss']
})
export class ErrorSnackBarComponent {
  constructor(private snackBar: MatSnackBar) {}
  openSnackBar(message: string, action: string, className: string) {
    this.snackBar.open(message, action, {
      duration: 10000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      panelClass: [className]
    });
  }
}
