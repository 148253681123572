import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { wog_settings } from '../../../classes/wog';
import { textAlign } from '../../../enums/columnSettings';

@Component({
  selector: 'app-wog-text-field-column',
  templateUrl: './wog_text-field-column.component.html',
  styleUrls: ['./wog_text-field-column.component.scss']
})
export class WogTextFieldColumnComponent implements OnInit, OnChanges {
  @Input() columnIn: any;
  @Input() elementIn: any;
  @Input() currentLevel: number = 1;
  @Input() wog_settingsIn: wog_settings = new wog_settings();
  @Input() refreshBit: number = 0;
  @Output() wog_textFieldChanged = new EventEmitter<any>();

  value: string = '';
  changesMade = false;
  textAlign = textAlign;
  readOnly = false;

  constructor() {}

  ngOnInit() {
    this.value = this.cleanValue(this.elementIn[this.columnIn.columnName]);
    this.readOnly = this.isDisabled(this.elementIn, this.wog_settingsIn);
  }

  ngOnChanges() {
    this.value = this.value = this.cleanValue(this.elementIn[this.columnIn.columnName]);
    this.readOnly = this.isDisabled(this.elementIn, this.wog_settingsIn);
  }

  cleanValue(value: string) {
    return value.replace('[P] ', '').replace('[B] ', '').replace('[FLR] ', '');
  }

  isDisabled(element: any, wog_settingsIn: wog_settings) {
    return wog_settingsIn.readonly ? wog_settingsIn.readonly(element) : false;
  }

  changed() {
    this.wog_textFieldChanged.emit({ value: this.elementIn[this.columnIn.columnName], element: this.elementIn, refresh: this.wog_settingsIn.onChangeRefresh });
  }
}
