<div class="wrapper">
  <div *ngIf="pendingInstructions.length > 0; else noInstructions">
    <ul>
      <li *ngFor="let instruction of pendingInstructions">
        {{ instruction }}
      </li>
    </ul>
  </div>
  <ng-template #noInstructions>
    No pending instructions
  </ng-template>
</div>
