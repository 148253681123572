<div class="wrapper">
  <div mat-dialog-title>
    <mat-toolbar color="primary">
      <mat-icon class="custoIcon">maps_home_work</mat-icon>
      <span> Building List | {{identifier1}} | {{identifier2}}</span>
      <span class="spacer"></span>
      <button mat-icon-button class="icon" (click)="SaveSelection()" hint="save">
        <mat-icon matTooltip="Save">save</mat-icon>
      </button>
      <button mat-icon-button class="icon" mat-dialog-close hint="cancel">
        <mat-icon matTooltip="Close">close</mat-icon>
      </button>
    </mat-toolbar>
    <mat-card-subtitle>Select premises from property: {{propertyName}}</mat-card-subtitle>
    <hr class="horizontalline">
  </div>
  <div style="max-height: 81vh;" mat-dialog-content fxLayout="column">

    <mat-accordion>
      <mat-expansion-panel hideToggle class="buildingExpansion white-arrow" *ngIf="property" style="width: 100%;">
        <mat-expansion-panel-header>
          <mat-panel-title class="expander-title">
            <div fxLayout="column"
              fxLayout.sm="column"
              fxLayoutAlign="space-between start"
              fxLayoutAlign.sm="start stretch">
              <div fxLayout="row" fxLayoutAlign="space-between stretch">
                <mat-icon style="color: white;">home</mat-icon>&nbsp;&nbsp;
                <h4 class="expansion-main-header">{{property.name}} </h4>
              </div>
            </div>
          </mat-panel-title>
          <mat-panel-description class="expander-description">
            <div class="expander-items">
              <small style="color: white;">Rentable :{{property.rentableArea | customDecimal | number:'1.0-0'}} m²</small>
            </div>
            <div class="expander-items">
              <small style="color: white;">Supplementary: {{property.supplementaryArea | customDecimal | number:'1.0-0'}} m²</small>
            </div>
            <div class="expander-items">
              <small style="color: white;">Usable: {{property.usableArea | customDecimal | number:'1.0-0'}} m²</small>
            </div>

            <button *ngIf="canSelectProperty()" class="buildingExpansion"
              (click)="selectProperty()" (click)="$event.stopPropagation() "
              mat-flat-button color="primary"> <mat-icon>{{this.selectedLevel === 'Property'?
                'check_box':'check_box_outline_blank' }}</mat-icon></button>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-expansion-panel class="buildingExpansion white-arrow" *ngFor="let building of this.buildingDataHolder; let i= index"
          (opened)="panelOpenState= true"
          (closed)="panelOpenState= false">
          <mat-expansion-panel-header>
            <mat-panel-title class="expander-title">
              <div fxLayout="column"
                fxLayout.sm="column"
                fxLayoutAlign="space-between start"
                fxLayoutAlign.sm="start stretch">
                <small style="color: white;">{{building.name}}</small>
              </div>
            </mat-panel-title>
            <mat-panel-description class="expander-description">
              <div class="expander-items">
                <small style="color: white;">Rentable :{{building.rentableArea | customDecimal | number:'1.0-0'}} m²</small>
              </div>
              <div class="expander-items">
                <small style="color: white;">Supplementary: {{building.supplementaryArea | customDecimal | number:'1.0-0'}} m²</small>
              </div>
              <div class="expander-items">
                <small style="color: white;">Usable: {{building.usableArea | customDecimal | number:'1.0-0'}} m²</small>
              </div>

              <button *ngIf="canSelectBuildings()" class="buildingExpansion"
                (click)="selectBuilding(building)" (click)="$event.stopPropagation() "
                mat-flat-button color="primary"> <mat-icon>{{selectedBuildings.includes(building.id)?
                  'check_box':'check_box_outline_blank' }}</mat-icon></button>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div *ngIf="building.BuildingFloors">
            <mat-expansion-panel class="floorExpansion white-arrow" *ngFor="let floor of building.BuildingFloors.floors; let i= index"
              (opened)="panelOpenState= true"
              (closed)="panelOpenState= false">
              <mat-expansion-panel-header>
                <mat-panel-title class="expander-title">
                  <div fxLayout="column"
                    fxLayout.sm="column"
                    fxLayoutAlign="space-between start"
                    fxLayoutAlign.sm="start stretch">
                    <small style="color: black;">{{floor.name}}</small>
                  </div>
                </mat-panel-title>
                <mat-panel-description class="expander-description">
                  <div class="expander-items">
                    <small style="color: black;">Rentable:{{floor.rentableArea | customDecimal | number:'1.0-0'}} m²</small>
                  </div>
                  <div class="expander-items">
                    <small style="color: black;">Supplementary: {{floor.supplementaryArea | customDecimal | number:'1.0-0'}} m²</small>
                  </div>
                  <div class="expander-items">
                    <small style="color: black;">Usable: {{floor.usableArea | customDecimal | number:'1.0-0'}} m²</small>
                  </div>
                  <button *ngIf="canSelectFloors()"
                    class="floorExpansion"
                    (click)="selectFloor(building,floor)" (click)="$event.stopPropagation() "
                    mat-flat-button color="primary"> <mat-icon>{{selectedFloors.includes(floor.id)?
                      'check_box':'check_box_outline_blank' }}</mat-icon></button>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <table *ngIf="filterType !== 'renewal' &&filterType !== 'lcs'&&!filterType.includes('dir_') " id="premises">
                <tr>
                  <th>Pocket</th>
                  <th>Tenant Name</th>
                  <th>Unit Usage</th>
                  <th>Current Instruction</th>
                  <th>Instruction Type</th>
                  <th>Instruction Status</th>
                  <th>Beneficial Occupation Date / Expiry Date</th>
                  <th>Lease Term</th>
                  <th>Project</th>
                  <th>Project Type</th>
                  <th>Pending Instruction</th>
                  <th>Selection</th>

                  <!-- <th>Project Status</th> -->
                </tr>
                <tr *ngFor="let pocket of floor.pockets; let i= index">

                  <td [class]="pocket.name===''?'defaultEmpty':''" style="text-align: center">{{pocket.name === ""?defaultEmptyString:pocket.name}}</td>
                  <td [class]="pocket.tenant===''?'defaultEmpty':''" style="text-align: center">{{pocket.tenant=== ""?defaultEmptyString:pocket.tenant}}</td>
                  <td [class]="pocket.unitUsage===''?'defaultEmpty':''" style="text-align: center">{{pocket.unitUsage ===
                    ""?defaultEmptyString:pocket.unitUsage}}</td>
                  <td [class]="pocket.currentInstruction===''?'defaultEmpty':''" style="text-align: center">{{pocket.currentInstruction ===
                    ""?defaultEmptyString:pocket.currentInstruction}}</td>
                  <td [class]="pocket.instructionType===''?'defaultEmpty':''" style="text-align: center">{{pocket.instructionType ===
                    ""?defaultEmptyString:pocket.instructionType}}</td>
                  <td [class]="pocket.currentInstruction===''?'defaultEmpty':''" style="text-align: center">{{pocket.currentInstruction !==
                    ""?"Active":defaultEmptyString}}</td>
                  <td [class]="pocket.bO_ExpiryDate===''?'defaultEmpty':''" style="text-align: center">{{pocket.bO_ExpiryDate ===
                    ""?defaultEmptyString:pocket.bO_ExpiryDate}}</td>

                  <td [class]="pocket.leaseTerm===''?'defaultEmpty':''" style="text-align: center">{{pocket.leaseTerm ===
                    ""?defaultEmptyString:pocket.leaseTerm}}</td>
                  <td [class]="pocket.currentProject===''?'defaultEmpty':''" style="text-align: center">{{pocket.currentProject ===
                    ""?defaultEmptyString:pocket.currentProject}}</td>
                  <td [class]="pocket.projectType===''?'defaultEmpty':''" style="text-align: center">{{pocket.projectType ===
                    ""?defaultEmptyString:pocket.projectType}}</td>
                  <td [class]="pocket.pendingInstruction===''?'defaultEmpty':''" style="text-align: center">{{pocket.pendingInstruction ===
                    ""?defaultEmptyString:pocket.pendingInstruction}}</td>
                  <td style="text-align: center;padding-top: 10px;">
                    <mat-icon class="icon-click" [style]="pocket.selectable?selectedPockets.includes(pocket.id)?'color:green':'':'color:red'"
                      (click)="selectPremises(pocket,building,floor)">
                      {{pocket.selectable?selectedPockets.includes(pocket.id)?'check_box':'check_box_outline_blank':'indeterminate_check_box' }}
                    </mat-icon>
                  </td>

                  <!-- <td style="text-align: center">{{pocket.projectStatus}}</td> -->
                </tr>
              </table>

              <table *ngIf="filterType === 'renewal' || filterType === 'lcs'" id="premises">
                <tr>
                  <th style="text-align: left">Pocket</th>
                  <th style="text-align: left">Tenant Name</th>
                  <th style="text-align: left">Unit Usage</th>
                  <th style="text-align: center">Usable Area</th>
                  <th style="text-align: center">Rentable Area</th>
                  <th style="text-align: center">Supplementary Area</th>
                  <th style="text-align: center">Total Income Generating</th>
                  <th style="text-align: left">Current Instruction</th>
                  <th style="text-align: left">Instruction Type</th>
                  <th style="text-align: left">BO Date / Expiry Date</th>
                  <th style="text-align: left">Lease Term</th>
                  <th style="text-align: left">Pending Instruction</th>
                  <th style="text-align: left">BO Date / Expiry Date</th>
                  <th style="text-align: center">Selection</th>

                </tr>
                <tr *ngFor="let pocket of floor.pockets; let i= index">
                  <!-- Pocket -->
                  <td [class]="pocket.name===''?'defaultEmpty':''" style="text-align: left">
                    {{pocket.name === ""?defaultEmptyString:pocket.name}}
                  </td>
                  <!-- Tenant Name -->
                  <td [class]="pocket.tenant===''?'defaultEmpty':''" style="text-align: left">
                    {{pocket.tenant=== ""?defaultEmptyString:pocket.tenant}}
                  </td>
                  <!-- Unit Usage -->
                  <td [class]="pocket.unitUsage===''?'defaultEmpty':''" style="text-align: left">
                    {{pocket.unitUsage === ""?defaultEmptyString:pocket.unitUsage }}
                  </td>
                  <!-- Usable Area -->
                  <td [class]="pocket.usableArea===''?'defaultEmpty':''" style="text-align: center">
                    {{pocket.usableArea === ""?defaultEmptyString:pocket.usableArea+ ' m²'}}
                  </td>
                  <!-- Rentable Area -->
                  <td [class]="pocket.rentableArea===''?'defaultEmpty':''" style="text-align: center">
                    {{pocket.rentableArea === ""?defaultEmptyString:pocket.rentableArea+ ' m²'}}
                  </td>
                  <!-- Supplementary Area -->
                  <td [class]="pocket.supplementaryArea===''?'defaultEmpty':''" style="text-align: center">
                    {{pocket.supplementaryArea === ""?defaultEmptyString:pocket.supplementaryArea+ ' m²'}}
                  </td>
                  <!-- Total Income Generating -->
                  <td [class]="pocket.totalIncomeGenerating===''?'defaultEmpty':''" style="text-align: center">
                    {{pocket.totalIncomeGenerating === ""?defaultEmptyString:pocket.totalIncomeGenerating+ ' m²'}}
                  </td>
                  <!-- Current Instruction -->
                  <td [class]="pocket.currentInstruction===''?'defaultEmpty':''" style="text-align: left">
                    {{pocket.currentInstruction === ""?defaultEmptyString:pocket.currentInstruction}}
                  </td>
                  <!-- Instruction Type -->
                  <td [class]="pocket.instructionType===''?'defaultEmpty':''" style="text-align: left">
                    {{pocket.instructionType === ""?defaultEmptyString:pocket.instructionType}}
                  </td>
                  <!-- BO Date / Expiry Date -->
                  <td [class]="pocket.bO_ExpiryDate===''?'defaultEmpty':''" style="text-align: left">
                    {{pocket.bO_ExpiryDate === ""?defaultEmptyString:pocket.bO_ExpiryDate}}
                  </td>
                  <!-- Lease Term -->
                  <td [class]="pocket.leaseTerm===''?'defaultEmpty':''" style="text-align: left">
                    {{pocket.leaseTerm === ""?defaultEmptyString:pocket.leaseTerm}}
                  </td>
                  <!-- Pending Instruction -->
                  <td [class]="pocket.pendingInstruction===''?'defaultEmpty':''" style="text-align: left">
                    {{pocket.pendingInstruction === ""?defaultEmptyString:pocket.pendingInstruction}}
                  </td>
                  <!-- BO Date / Expiry Date -->
                  <td [class]="pocket.pendingBO_ExpiryDate===''?'defaultEmpty':''" style="text-align: left">
                    {{pocket.pendingBO_ExpiryDate === ""?defaultEmptyString:pocket.pendingBO_ExpiryDate}}
                  </td>
                  <!-- Selection -->
                  <td style="text-align: center;padding-top: 10px;">
                    <mat-icon class="icon-click" [style]="pocket.selectable?selectedPockets.includes(pocket.id)?'color:green':'':'color:red'"
                      (click)="selectPremises(pocket,building,floor)">
                      {{pocket.selectable?selectedPockets.includes(pocket.id)?'check_box':'check_box_outline_blank':'indeterminate_check_box' }}
                    </mat-icon>
                  </td>

                  <!-- <td style="text-align: center">{{pocket.projectStatus}}</td> -->
                </tr>
              </table>

              <table *ngIf=" filterType.includes('dir_') " id="premises">
                <tr>
                  <th style="text-align: left">Pocket</th>
                  <th style="text-align: left">Tenant Name</th>
                  <th style="text-align: left">Unit Usage</th>
                  <th style="text-align: center">Usable Area</th>
                  <th style="text-align: center">Rentable Area</th>
                  <th style="text-align: center">Supplementary Area</th>
                  <th style="text-align: center">Total Income Generating</th>
                  <th style="text-align: left">Current Instruction</th>
                  <th style="text-align: left">BO Date / Expiry Date</th>
                  <th style="text-align: left">Lease Term</th>
                  <th style="text-align: left">Pending Instruction</th>
                  <th style="text-align: center">Selection</th>

                </tr>
                <tr *ngFor="let pocket of floor.pockets; let i= index">
                  <!-- Pocket -->
                  <td [class]="pocket.name===''?'defaultEmpty':''" style="text-align: left">
                    {{pocket.name === ""?defaultEmptyString:pocket.name}}
                  </td>
                  <!-- Tenant Name -->
                  <td [class]="pocket.tenant===''?'defaultEmpty':''" style="text-align: left">
                    {{pocket.tenant=== ""?defaultEmptyString:pocket.tenant}}
                  </td>
                  <!-- Unit Usage -->
                  <td [class]="pocket.unitUsage===''?'defaultEmpty':''" style="text-align: left">
                    {{pocket.unitUsage === ""?defaultEmptyString:pocket.unitUsage + ' m²'}}
                  </td>
                  <!-- Usable Area -->
                  <td [class]="pocket.usableArea===''?'defaultEmpty':''" style="text-align: center">
                    {{pocket.usableArea === ""?defaultEmptyString:pocket.usableArea+ ' m²'}}
                  </td>
                  <!-- Rentable Area -->
                  <td [class]="pocket.rentableArea===''?'defaultEmpty':''" style="text-align: center">
                    {{pocket.rentableArea === ""?defaultEmptyString:pocket.rentableArea+ ' m²'}}
                  </td>
                  <!-- Supplementary Area -->
                  <td [class]="pocket.supplementaryArea===''?'defaultEmpty':''" style="text-align: center">
                    {{pocket.supplementaryArea === ""?defaultEmptyString:pocket.supplementaryArea+ ' m²'}}
                  </td>
                  <!-- Total Income Generating -->
                  <td [class]="pocket.totalIncomeGenerating===''?'defaultEmpty':''" style="text-align: center">
                    {{pocket.totalIncomeGenerating === ""?defaultEmptyString:pocket.totalIncomeGenerating+ ' m²'}}
                  </td>
                  <!-- Current Instruction -->
                  <td [class]="pocket.currentInstruction===''?'defaultEmpty':''" style="text-align: left">
                    {{pocket.currentInstruction === ""?defaultEmptyString:pocket.currentInstruction}}
                  </td>
                  <!-- BO Date / Expiry Date -->
                  <td [class]="pocket.bO_ExpiryDate===''?'defaultEmpty':''" style="text-align: left">
                    {{pocket.bO_ExpiryDate === ""?defaultEmptyString:pocket.bO_ExpiryDate}}
                  </td>
                  <!-- Lease Term -->
                  <td [class]="pocket.leaseTerm===''?'defaultEmpty':''" style="text-align: left">
                    {{pocket.leaseTerm === ""?defaultEmptyString:pocket.leaseTerm}}
                  </td>
                  <!-- Pending Instruction -->
                  <td [class]="pocket.pendingInstruction===''?'defaultEmpty':''" style="text-align: left">
                    {{pocket.pendingInstruction === ""?defaultEmptyString:pocket.pendingInstruction}}
                  </td>
                  <!-- Selection -->
                  <td style="text-align: center;padding-top: 10px;">
                    <mat-icon *ngIf="this.selectedLevel === ''||this.selectedLevel === 'Pockets'" class="icon-click"
                      [style]="pocket.selectable?selectedPockets.includes(pocket.id)?'color:green':'':'color:red'"
                      (click)="selectPremises(pocket,building,floor)">
                      {{pocket.selectable?selectedPockets.includes(pocket.id)?'check_box':'check_box_outline_blank':'indeterminate_check_box' }}
                    </mat-icon>
                  </td>

                  <!-- <td style="text-align: center">{{pocket.projectStatus}}</td> -->
                </tr>
              </table>
            </mat-expansion-panel>
          </div>
        </mat-expansion-panel>
      </mat-expansion-panel>

    </mat-accordion>
  </div>
</div>
