import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { featureComponents, featureServices } from './core.declarations';
import { ApiPrefixInterceptor } from './interceptors/api-prefix.interceptor';
import { HttpAuthInterceptor } from './interceptors/http-auth-interceptor';
import { HttpValidParamsInterceptor } from './interceptors/http-valid-params.interceptor';
import { PendingRequestsInterceptor } from './interceptors/pending-requests-interceptor';
import { ServerErrorsInterceptor } from './interceptors/server-errors-interceptor';

export const getAccessToken = () => {
  return localStorage.getItem('id_token');
};

@NgModule({
  declarations: [...featureComponents],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FlexLayoutModule,
    MatSnackBarModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: getAccessToken,
        skipWhenExpired: true
      }
    })
  ],
  providers: [...featureServices],
  exports: [CommonModule, HttpClientModule, FlexLayoutModule, JwtModule]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ApiPrefixInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useExisting: PendingRequestsInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ServerErrorsInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpAuthInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpValidParamsInterceptor,
          multi: true
        },
        { provide: LOCALE_ID, useValue: 'en-ZA' },
        { provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, useValue: { autoActiveFirstOption: true } }
      ]
    };
  }
}
