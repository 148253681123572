import { PaginatorOptions } from './paginator';

export class TableSettings {
  enablePaginator?: boolean = true;
  paginatorOptions?: PaginatorOptions = new PaginatorOptions();
  backgroundColor?: string = '';
  maxHeight?: string = '';
  height?: string = '';
  showLevelLines: boolean = false;
  column_Styling?: TableStyle = new TableStyle();
  row1_Styling?: TableStyle = new TableStyle();
  row2_Styling?: TableStyle = new TableStyle();
  row3_Styling?: TableStyle = new TableStyle();
  row4_Styling?: TableStyle = new TableStyle();

  constructor(options: Partial<TableSettings> = {}) {
    Object.assign(this, options);
  }
}

export class TableStyle {
  backgroundColor?: string = '';
  color?: string = '';
  fontSize?: string = '';
  fontWeight?: string = '';
}
