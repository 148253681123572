import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth-service';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  inflightAuthRequest: any;
  contentType: any = 'application/json';
  blacklist: any = [
    /accounts\/login/,
    /accounts\/error/,
    /token\/refresh/,
    /accounts\/reset/,
    /accounts\/forgot/,
    /forcelink\/rest\/usermanager\/apiKeyLogin/,
    /settings\/forcelink/
  ];
  constructor(private injector: Injector, private router: Router) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has('no-header')) {
      req = req.clone({
        headers: req.headers.delete('Content-Type', 'application/json')
      });
    } else if (req.headers.has('Content-Type')) {
      
      this.contentType = req.headers.get('Content-Type');
    } else {
      req = req.clone({
        setHeaders: {
          'Content-Type': this.contentType,
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache'
        }
      });
    }

    if (this.blacklistCheckup(req.url) || req.headers.get('authExempt') === 'true') {
      const headerReq = req.clone({
        setHeaders: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache'
        }
      });
      return next.handle(headerReq);
    }
    const authService = this.injector.get(AuthService);

    if (!this.inflightAuthRequest) {
      this.inflightAuthRequest = authService.getToken();
    }

    return this.inflightAuthRequest.pipe(
      switchMap((newToken: string) => {
        // unset request inflight
        this.inflightAuthRequest = null;
        // use the newly returned token
        const authReq = req.clone({
          setHeaders: {
            Authorization: `Bearer ${newToken}`,
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache'
          }
        });
        return next.handle(authReq);
      }),
      catchError((error) => {
        // checks if a url is to an admin api or not
        if (error.status === 0) {
          throwError(error);
        }

        if (error.status === 401) {
          // If unauthorized from token then user auth required again.
          if (error.url.includes('token/refresh')) {
            localStorage.clear();
            this.router.navigate(['']);
            return throwError(error);
          }

          if (!this.inflightAuthRequest) {
            this.inflightAuthRequest = authService.refreshToken();
            if (!this.inflightAuthRequest) {
              // remove existing tokens
              localStorage.clear();
              this.router.navigate(['']);
              return throwError(error);
            }
          }

          return this.inflightAuthRequest.pipe(
            switchMap((newToken: string) => {
              // unset inflight request

              this.inflightAuthRequest = null;
              const authReqRepeat = req.clone({
                setHeaders: {
                  Authorization: `Bearer ${newToken}`,
                  'Cache-Control': 'no-cache',
                  Pragma: 'no-cache'
                }
              });
              // resend the request
              return next.handle(authReqRepeat);
            })
          );
        } else {
          return throwError(error);
        }
      })
    );
  }

  blacklistCheckup($url: string): boolean {
    let returnValue = false;
    Object.keys(this.blacklist).forEach((i) => {
      if (this.blacklist[i].exec($url) != null) {
        returnValue = true;
        return;
      }
    });
    return returnValue;
  }
}
