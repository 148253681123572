import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataService } from '@app/core/services/data-service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-update-warehouse-dialog',
  templateUrl: './update-warehouse-dialog.component.html',
  styleUrls: ['./update-warehouse-dialog.component.scss']
})
export class UpdateWarehouseDialogComponent implements OnInit {
  /*
   * Calling this function with step Array:
   *
   * #####__PREMISES__#####
   * 1 --> Constructing Property layout
   * 2 --> Building Premises leases
   * 3 --> Processing Premises Status
   *
   * #####__PARKING__#####
   * 4 --> Constructing Parking layout
   * 5 --> Building Parking leases
   * 6 --> Processing Parking Statuses
   */

  steps = [
    { id: 1, text: 'Constructing Property layout', success: false, failure: false, inProgress: true },
    { id: 2, text: 'Fetching Premises Lease', success: false, failure: false, inProgress: false },
    { id: 3, text: 'Processing Premises Status', success: false, failure: false, inProgress: false },
    { id: 4, text: 'Constructing Parking layout', success: false, failure: false, inProgress: false },
    { id: 5, text: 'Fetching lease details', success: false, failure: false, inProgress: false },
    { id: 6, text: 'Processing Parking Statuses', success: false, failure: false, inProgress: false }
  ];

  filteredSteps: any[] = [];

  constructor(public dialogRef: MatDialogRef<UpdateWarehouseDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: { propertyId: string; stepsToRun: number[] }, private dataService: DataService) {}

  ngOnInit(): void {
    // Filter the steps to only include those that need to run
    this.filteredSteps = this.steps.filter((step) => this.data.stepsToRun.includes(step.id));

    // Start the update process
    this.updateWarehouse(this.data.stepsToRun);
  }

  updateWarehouse(stepsToRun: number[]): void {
    const propertyId = this.data.propertyId;

    const stepActions: { [key: number]: () => Observable<any> } = {
      1: () => this.dataService.dwPremisesConstruct(propertyId),
      2: () => this.dataService.dwPremisesLease(propertyId),
      3: () => this.dataService.dwPremisesStatus(propertyId),
      4: () => this.dataService.dwParkingConstruct(propertyId),
      5: () => this.dataService.dwParkingLease(propertyId),
      6: () => this.dataService.dwParkingStatus(propertyId)
    };

    const runStep = (stepIndex: number) => {
      if (stepIndex >= stepsToRun.length) {
        this.setDone(); // All specified steps complete
        return;
      }

      const stepNumber = stepsToRun[stepIndex];
      const stepAction = stepActions[stepNumber];

      if (stepAction) {
        stepAction().subscribe(
          () => {
            this.updateStep(stepNumber, true); // Step complete
            runStep(stepIndex + 1); // Run next step
          },
          (error: any) => {
            this.updateStep(stepNumber, false); // Step failed
            this.setDone(); // Stop if any step fails
          }
        );
      } else {
        runStep(stepIndex + 1); // Skip undefined steps
      }
    };

    runStep(0); // Start with the first step
  }

  updateStep(stepNumber: number, isSuccess: boolean): void {
    this.steps[stepNumber - 1].success = isSuccess;
    this.steps[stepNumber - 1].failure = !isSuccess;
    this.steps[stepNumber - 1].inProgress = false;
  }

  setDone(): void {
    // Additional logic if needed when all steps are complete
    setTimeout(() => {
      this.dialogRef.close(); // Close dialog after a delay to show completion
    }, 1000); // Delay before closing dialog
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
