import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Color, ScaleType } from '@swimlane/ngx-charts';
import { IPieGridGraph } from '../graph-Interfaces';

@Component({
  selector: 'app-pie-grid-graph',
  templateUrl: './pie-grid-graph.component.html',
  styleUrls: ['./pie-grid-graph.component.scss']
})
export class PieGridGraphComponent implements OnInit, OnChanges {
  @Input() graphSettings: IPieGridGraph = { data: [] };

  dataSource: any;
  height: number = 700;
  width: number = 400;
  minWidth?: number;
  maxWidth: number = 10000;
  title: string = '';

  colorScheme: Color = {
    name: 'cool', // or any other valid name
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#04172e', '#0059e6', '#335672', '#b7c3cd', '#37a8cb', '#57748b']
  };

  constructor() {}

  ngOnInit() {
    this.dataSource = this.graphSettings.data ?? this.dataSource;
    this.height = this.graphSettings.height ?? this.height;
    this.width = this.graphSettings.width ?? this.width;
    this.minWidth = this.graphSettings.minWidth ?? this.minWidth;
    this.maxWidth = this.graphSettings.maxWidth ?? this.maxWidth;
    this.title = this.graphSettings.title ?? this.title;

    if (this.title != '') {
      this.height = this.height - 40;
    }
    this.setProperties();
  }

  setProperties() {
    this.setWidth();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['graphSettings']) {
      this.setProperties();
    }
  }

  setWidth() {
    if (this.minWidth && this.maxWidth) {
      if (this.minWidth >= this.width) {
        this.view = [this.minWidth, this.height];
      } else if (this.maxWidth <= this.width) {
        this.view = [this.maxWidth, this.height];
      } else {
        this.view = [this.width, this.height];
      }
    } else {
      this.view = [this.width, this.height];
    }
  }

  // Customize pie chart options
  view!: [number, number];

  // Function to format label
  labelFormatting(value: any): string {
    return `${value.label}: ${value.value}`;
  }

  // Function to handle click events
  onSelect(event: any): void {}
}
