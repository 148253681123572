import { Component, ComponentFactoryResolver, Inject } from '@angular/core';
import { DataService } from '@app/core/services/data-service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDocumentTypes } from '@app/core/contracts/documentTypes';

@Component({
  selector: 'app-file-viewer',
  templateUrl: './fileViewer.component.html',
  styleUrls: ['./fileViewer.component.scss']
})
export class fileViewerComponent {
  search!: number;
  page!: string;
  name!: string;
  fileTypes!: [IDocumentTypes];
  fileTypeCutomObj: any = [];

  constructor(private dataService: DataService, @Inject(MAT_DIALOG_DATA) public data: any) {}

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit() {
    this.search = this.data.page;
    this.page = this.data.page;
    this.name = this.data.name;
    this.loadDocumentTypes();
  }

  loadDocumentTypes() {
    this.dataService.searchDocumentTypes(this.search).subscribe((data) => {
      this.fileTypes = data;
      this.fileTypes.forEach((filetype) => {
        var fileCount = 0;
        var fileTypeId = this.fileTypes.find((x) => x.name == filetype.name)?.id;
        this.dataService.FileTypeParentCount(this.data.value, fileTypeId).subscribe((count) => {
          fileCount = count;
          this.fileTypeCutomObj.push({ FileTypeName: filetype.name, FileTypeCount: fileCount });
        });
      });
    });
  }

  DownloadLatestDocument(parentName: string, fileTypeName: string, fileTypeCount: number) {
    this.dataService.DownloadLatestFileType(this.data.value, this.fileTypes.find((x) => x.name == fileTypeName)?.id).subscribe((blob) => {
      this.openFile(blob, parentName + '_' + fileTypeName + '(' + fileTypeCount + ')');
    });
  }

  openFile(data: any, filename: string) {
    var a = document.createElement('a');
    a.href = window.URL.createObjectURL(data);
    a.download = filename;
    a.click();
  }
}
