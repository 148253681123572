import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {
  transform(value: number | string): string {
    // Check if value is null, undefined, or empty string
    if (value == null || value === '') {
      return '';
    }

    // Convert value to string and split into integer and decimal parts
    const [integerPart, decimalPart] = String(value).split('.');

    // Format the integer part by inserting commas every three digits from the right
    let formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // If there's a decimal part, add it back to the formatted integer part
    let result = formattedIntegerPart;
    if (decimalPart !== undefined) {
      // Ensure decimal part has two digits
      result += '.' + (decimalPart + '00').slice(0, 2);
    } else {
      // If no decimal part, append .00
      result += '.00';
    }

    return result;
  }
}
