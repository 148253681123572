import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-date-column',
  templateUrl: './date-column.component.html',
  styleUrls: ['./date-column.component.scss']
})
export class DateColumnComponent implements OnInit, OnChanges {
  @Input() valueIn: string = '';

  value!: string;
  hoverText!: string;

  constructor() {}

  ngOnInit() {
    this.value = this.valueIn;
  }

  ngOnChanges() {
    this.value = this.valueIn;
  }
}
