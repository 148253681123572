export const environment = {
  production: false,
  version: require('../../package.json').version,
  API_URL: 'http://4.221.169.222/',
  // API_URL: 'https://api-staging.spaceup.co.za/v1/',
  platform: 'development',
  appInsights: {
    instrumentationKey: '7ef2c046-45df-47af-9a7e-ed4d70f00f8d'
  }
};
