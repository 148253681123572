<h2 mat-dialog-title>Confirm logout</h2>
<mat-dialog-content>
  <p>Are you sure you want to logout?</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button
    mat-dialog-close>Cancel</button>
  <button mat-raised-button
    color="primary"
    [mat-dialog-close]="true"
    cdkFocusInitial>
    Logout
  </button>
</mat-dialog-actions>
