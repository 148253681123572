import { Component } from "@angular/core";
import Swal, { SweetAlertResult } from 'sweetalert2';

@Component({
    template: '',
})
export class SweetAlertComponent {
    showSuccessMessage(title: string){
        Swal.fire({
            position: 'center',
            icon: 'success',
            title,
            timer: 2000
        });
    }

    showErrorMessage(title: string): void {
        Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Error',
            text: title
        });
    }

    showWarningDialog(message: string): Promise<boolean> {
        return Swal.fire({
            title: 'Warning',
            text: message,
            icon: 'warning',
            confirmButtonText: 'Ok',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result: SweetAlertResult) => {
            return result.isConfirmed;
        });
    }

    showConfirmDialog(message: string, title: string): Promise<boolean> {
        return Swal.fire({
            title: title,
            text: message,
            icon: 'info',
            confirmButtonText: 'Ok',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result: SweetAlertResult) => {
            return result.isConfirmed;
        });
    }
}