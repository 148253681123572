import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { IconSettings } from '../../../classes/iconSettings';
import { matOption } from '../../../classes/matOption';

@Component({
  selector: 'app-mat-options-column',
  templateUrl: './mat-options-column.component.html',
  styleUrls: ['./mat-options-column.component.scss']
})
export class MatOptionColumnComponent implements OnInit, OnChanges {
  @Input() iconIn: IconSettings = new IconSettings();
  @Input() elementIn: string = '';

  @Input() matOptions: matOption[] = [{ name: 'There are no Options at the moment', icon: '', color: '', level: [1, 2, 3, 4] }];
  @Input() currentLevel: number = 1;

  @Output() matOptionClicked = new EventEmitter<any>();

  icon!: IconSettings;

  constructor() {}

  toggleClicked(value: any) {
    this.matOptionClicked.emit({ option: value.name, element: this.elementIn });
  }

  ngOnInit() {
    this.icon = this.iconIn;
  }

  ngOnChanges() {
    this.icon = this.iconIn;
  }
}
