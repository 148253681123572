<app-table *ngIf="dataSource"
  [dataSourceIn]="dataSource.filteredData"
  [tableSettingsIn]="tableSettings"
  [displayedColumnsIn]="columnSettings"
  [showPaginator]="false"
  [treeTable]="true"
  [expandAll]="expandAll"
  (iconClicked)="handleIconClicked($event)"
  (wog_dropDownSelectionChanged)="handleWogChanged($event)"
  (wog_numericFieldChanged)="handleWogChanged($event)"
  (wog_textFieldChanged)="handleWogChanged($event)"
  (matOptionClicked)="handleMatOptionClicked($event)">
</app-table>
