import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';

export interface ColumnItem {
  column: string;
  group: string;
}

export interface dropdownObj {
  dropdownOpen: boolean;
  column: string;
}

enum dataFormats {
  date = 'date',
  string = 'string'
}

@Component({
  selector: 'app-column-filter',
  templateUrl: './column-filter.component.html',
  styleUrls: ['./column-filter.component.scss']
})
export class ColumnFilterComponent implements OnInit {
  selectedOptions: any[] = [];
  allSelected = false;
  filteredDate!: Observable<any[]>;
  ShowAllText = true;

  @Input() group: string = '';
  @Input() column: string = '';
  @Input() friendlyName!: string;
  @Input() filteredDataSource: any = [];
  @Input() smartFiltersActivated: [string] = [''];
  @Input() columnFiltersActivated!: [string];
  @Input() columnSearch: boolean = false;
  @Input() columnSearchDatasource: any[] = [];
  @Input() centerHeader: boolean = false;
  @Input() dataFormat: keyof typeof dataFormats = 'string';
  @Output() columnHeaderSelected = new EventEmitter<ColumnItem>();
  @Output() removeColumnFilter = new EventEmitter<ColumnItem>();
  @Output() dropdownClosed = new EventEmitter<dropdownObj>();
  @Output() filterChange = new EventEmitter<any>();

  inputFilter_txt = new UntypedFormControl('');

  private _filterdata(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.columnSearchDatasource.filter((value: string) => value.toLowerCase().includes(filterValue)).sort();
  }

  constructor() {}
  ngOnInit() {
    if (!this.friendlyName) {
      this.friendlyName = this.formatColumnName(this.column);
    }

    this.filteredDate = this.inputFilter_txt.valueChanges.pipe(
      startWith(''),
      map((state: any) => (state ? this._filterdata(state) : this.columnSearchDatasource.slice()).sort())
    );
  }

  selectHeader() {
    this.columnHeaderSelected.emit({ column: this.column, group: this.group });
  }

  removeColumn() {
    this.selectedOptions = [];
    this.removeColumnFilter.emit({ column: this.column, group: this.group });
  }

  columnHeaderClosed(event: any) {
    this.dropdownClosed.emit({ dropdownOpen: event, column: this.column });
  }

  filter(event: any) {
    this.selectedOptions = event.value;
    if (this.columnSearchDatasource.length == this.selectedOptions.length && this.selectedOptions.length != 0) {
      this.ShowAllText = true;
    } else {
      this.ShowAllText = false;
    }

    this.filterChange.emit(event);
  }

  private formatColumnName(column: string) {
    return (
      column.charAt(0).toUpperCase() +
      column
        .slice(1)
        .replace(/([A-Z])/g, ' $1')
        .trim()
    );
  }

  toggleSelectAll() {
    if (!this.allSelected) {
      this.ShowAllText = true;
      this.selectedOptions = [...this.columnSearchDatasource.map((item) => (item.key ? item.key + '/' + item.value : item)), 'all'];
    } else {
      this.ShowAllText = false;
      this.selectedOptions = [];
    }
    this.allSelected = !this.allSelected;
  }

  formatData(value: any) {
    let result;
    if (this.dataFormat == 'date') {
      result = this.formatDate(value);
    } else if (this.dataFormat == 'string') {
      result = value;
    }
    return result;
  }

  formatDate(date: Date): string {
    date = new Date(date);
    if (date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${year}/${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}`;
    } else {
      return '';
    }
  }
}
