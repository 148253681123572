import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ButtonSettings } from '../../../classes/buttonSettings';

@Component({
  selector: 'app-button-column',
  templateUrl: './button-column.component.html',
  styleUrls: ['./button-column.component.scss']
})
export class ButtonColumnComponent implements OnInit, OnChanges {
  @Input() valueIn: string = '';
  @Input() elementIn: string = '';
  @Input() currentLevel: number = 1;
  @Input() buttonSettings: ButtonSettings = new ButtonSettings();
  @Output() buttonClicked = new EventEmitter<any>();
  value!: string;

  constructor() {}

  ngOnInit() {
    this.value = this.valueIn;
  }

  ngOnChanges() {
    this.value = this.valueIn;
  }

  toggleClicked(value: any) {
    this.buttonClicked.emit({ button: value, element: this.elementIn });
  }
}
