<!-- Leases -->

<div *ngIf="showTooltip && type === 'leases'" class="custom-tooltip-content17">
  <div class="status-item">
    <mat-icon class="close-icon">error</mat-icon>
    <span>Lease Expired</span>
  </div>

  <div class="status-item">
    <mat-icon class="close-icon">error_outline</mat-icon>
    <span>Vacant</span>
  </div>

  <div class="status-item">
    <mat-icon style="color: #e74034">report_problem</mat-icon>
    <span style="white-space: nowrap">30 Days Notice</span>
  </div>

  <div class="status-item">
    <mat-icon style="color: #ff8f00">report_problem</mat-icon>
    <span style="white-space: nowrap">3 Month Notice</span>
  </div>

  <div class="status-item">
    <mat-icon style="color: #fff600">report_problem</mat-icon>
    <span style="white-space: nowrap">4 Month Condition Inspection</span>
  </div>

  <div class="status-item">
    <mat-icon style="color: #f27474">report_problem</mat-icon>
    <span style="white-space: nowrap">6 Month notice</span>
  </div>

  <div class="status-item">
    <mat-icon class="check-icon">task_alt</mat-icon>
    <span>Active</span>
  </div>

  <div class="status-item">
    <mat-icon class="check-icon-blue">task_alt</mat-icon>
    <span>PTO (Property Take-on)</span>
  </div>
</div>

<div *ngIf="showTooltip && type === 'leases-sapoa'" class="custom-tooltip-content5">
  <div class="status-item">
    <mat-icon class="check-icon">task_alt</mat-icon>
    <span>Active</span>
  </div>

  <div class="status-item">
    <mat-icon class="close-icon">error</mat-icon>
    <span>Lease Expired</span>
  </div>

  <div class="status-item">
    <mat-icon style="color: #e74034">report_problem</mat-icon>
    <span style="white-space: nowrap">30 Days Notice</span>
  </div>

  <div class="status-item">
    <mat-icon style="color: #ff8f00">report_problem</mat-icon>
    <span style="white-space: nowrap">3 Month Notice</span>
  </div>

  <div class="status-item">
    <mat-icon style="color: #fff600">report_problem</mat-icon>
    <span style="white-space: nowrap">4 Month Condition Inspection</span>
  </div>

  <div class="status-item">
    <mat-icon style="color: #f27474">report_problem</mat-icon>
    <span style="white-space: nowrap">6 Month notice</span>
  </div>

  <div class="status-item">
    <mat-icon class="check-icon-blue">task_alt</mat-icon>
    <span>PTO (Property Take-on)</span>
  </div>
</div>

<!-- Documents -->

<div *ngIf="showTooltip && type === 'documents'" class="custom-tooltip-content6">
  <div class="status-item">
    <mat-icon class="notSet-icon">circle</mat-icon>
    <span>Pending</span>
  </div>
  <div class="status-item">
    <mat-icon class="alert-icon">change_circle</mat-icon>
    <span>Work In Progress</span>
  </div>
  <div class="status-item">
    <mat-icon class="close-icon">error</mat-icon>
    <span>Work In Progress - Late</span>
  </div>
  <div class="status-item">
    <mat-icon class="awaiting">help</mat-icon>
    <span>Awaiting Approval</span>
  </div>
  <div class="status-item">
    <mat-icon class="close-icon">close</mat-icon>
    <span>Rejected</span>
  </div>
  <div class="status-item">
    <mat-icon class="check-icon">task_alt</mat-icon>
    <span>Approved</span>
  </div>
</div>

<!-- Timelines -->

<div *ngIf="showTooltip && type === 'timelinesCompliance'" class="custom-tooltip-content">
  <div class="status-item">
    <mat-icon class="check-icon">task_alt</mat-icon>
    <span>Completed </span>
  </div>
  <div class="status-item">
    <mat-icon class="alert-icon">change_circle</mat-icon>
    <span>In Progress</span>
  </div>
  <div class="status-item">
    <mat-icon class="close-icon">error</mat-icon>
    <span>In Progress - Late</span>
  </div>
  <div class="status-item">
    <mat-icon class="cancelled-icon">do_not_disturb_off</mat-icon>
    <span>Project Cancelled</span>
  </div>
  <div class="status-item">
    <mat-icon class="instruction-cancelled">unpublished</mat-icon>
    <span>Instruction Cancelled</span>
  </div>
  <div class="status-item">
    <mat-icon class="notSet-icon">circle</mat-icon>
    <span>Pending</span>
  </div>
</div>

<div *ngIf="showTooltip && type === 'timelines'" class="custom-tooltip-content2">
  <div class="status-item">
    <mat-icon class="notSet-icon">circle</mat-icon>
    <span>Pending </span>
  </div>
  <div class="status-item">
    <mat-icon class="alert-icon">change_circle</mat-icon>
    <span>In Progress</span>
  </div>
  <div class="status-item">
    <mat-icon class="late-icon">error</mat-icon>
    <span>In Progress - Late</span>
  </div>
  <div class="status-item">
    <mat-icon class="check-icon">task_alt</mat-icon>
    <span>Completed </span>
  </div>
</div>

<!-- Project Status -->

<div *ngIf="showTooltip && type === 'projectStatuses'" class="custom-tooltip-content6">
  <div class="status-item">
    <mat-icon class="check-icon">task_alt</mat-icon>
    <span>Final Completion </span>
  </div>
  <div class="status-item">
    <mat-icon class="alert-icon">change_circle</mat-icon>
    <span>Work In Progress</span>
  </div>
  <div class="status-item">
    <mat-icon class="close-icon">error</mat-icon>
    <span>Work In Progress - Late</span>
  </div>
  <div class="status-item">
    <mat-icon class="cancelled-icon">do_not_disturb_off</mat-icon>
    <span>Project Cancelled</span>
  </div>
  <div class="status-item">
    <mat-icon class="instruction-cancelled">unpublished</mat-icon>
    <span>Instruction Cancelled</span>
  </div>
  <div class="status-item">
    <mat-icon class="notSet-icon">circle</mat-icon>
    <span>Pending</span>
  </div>
</div>

<!-- Project Pack Status -->

<div *ngIf="showTooltip && type === 'projectPackStatus'" class="custom-tooltip-content18">
  <div class="status-item">
    <mat-icon class="check-icon">task_alt</mat-icon>
    <span>Approved</span>
  </div>
  <div class="status-item">
    <mat-icon class="alert-icon">change_circle</mat-icon>
    <span>Work In Progress</span>
  </div>
  <div class="status-item">
    <mat-icon class="close-icon">error</mat-icon>
    <span>Work In Progress - Late</span>
  </div>
  <div class="status-item">
    <mat-icon class="awaiting">help</mat-icon>
    <span>Awaiting Approval</span>
  </div>
  <div class="status-item">
    <mat-icon class="notSet-icon">circle</mat-icon>
    <span>Pending</span>
  </div>
  <div class="status-item">
    <mat-icon class="close-icon">close</mat-icon>
    <span>Rejected</span>
  </div>
</div>

<!-- Instruction Status -->
<div *ngIf="showTooltip && type === 'instructionStatus'" class="custom-tooltip-content6">
  <div class="status-item">
    <mat-icon style="color: rgb(91, 125, 170)">archive</mat-icon>
    <span>Archived</span>
  </div>
  <div class="status-item">
    <mat-icon class="check-icon">task_alt</mat-icon>
    <span>Active</span>
  </div>

  <div class="status-item">
    <mat-icon class="check-icon">done_all</mat-icon>
    <span>Accepted</span>
  </div>
  <div class="status-item">
    <mat-icon class="awaiting">help</mat-icon>
    <span>Awaiting Approval</span>
  </div>

  <div class="status-item">
    <mat-icon style="color: grey">circle</mat-icon>
    <span>Draft</span>
  </div>
  <div class="status-item">
    <mat-icon class="close-icon">close</mat-icon>
    <span>Cancelled</span>
  </div>
</div>

<div *ngIf="showTooltip && type === 'projectTypes'" class="custom-tooltip-content6">
  <div class="status-item">
    <span>Tenant In</span>
  </div>
  <div class="status-item">
    <span>Tenant Out</span>
  </div>
  <div class="status-item">
    <span>Compliance Project</span>
  </div>
  <div class="status-item">
    <span>Tenant Other Project</span>
  </div>
  <div class="status-item">
    <span>Landlord Other Project</span>
  </div>
</div>

<div *ngIf="showTooltip && type === 'complianceStatus'" class="custom-tooltip-content12">
  <div class="status-item">
    <mat-icon class="check-icon">task_alt</mat-icon>
    <span>Final Completion/Compliance</span>
  </div>
  <div class="status-item">
    <mat-icon class="alert-icon">change_circle</mat-icon>
    <span>Work In Progress</span>
  </div>
  <div class="status-item">
    <mat-icon class="close-icon">error</mat-icon>
    <span>Work In Progress - Late</span>
  </div>
  <div class="status-item">
    <mat-icon class="notSet-icon">circle</mat-icon>
    <span>Pending</span>
  </div>
  <div class="status-item">
    <mat-icon class="check-icon-blue">task_alt</mat-icon>
    <span>PTO (Property Take-on)</span>
  </div>
</div>

<div *ngIf="showTooltip && type === 'complianceStatus-pocket'" class="custom-tooltip-content13">
  <div class="status-item">
    <mat-icon class="check-icon">task_alt</mat-icon>
    <span>Final Completion/Compliance</span>
  </div>
  <div class="status-item">
    <mat-icon class="alert-icon">change_circle</mat-icon>
    <span>Work In Progress</span>
  </div>
  <div class="status-item">
    <mat-icon class="close-icon">error</mat-icon>
    <span>Work In Progress - Late</span>
  </div>
  <div class="status-item">
    <mat-icon class="notSet-icon">circle</mat-icon>
    <span>Pending</span>
  </div>
  <div class="status-item">
    <mat-icon class="check-icon-blue">task_alt</mat-icon>
    <span>PTO (Property Take-on)</span>
  </div>
</div>

<div *ngIf="showTooltip && type === 'workScope'" class="custom-tooltip-content3">
  <div class="status-item">
    <span>Pending</span>
  </div>
  <div class="status-item">
    <span>Cancelled</span>
  </div>
  <div class="status-item">
    <span>Construction</span>
  </div>
  <div class="status-item">
    <span>Compliance</span>
  </div>
  <div class="status-item">
    <span>Cosmetic</span>
  </div>
  <div class="status-item">
    <span>As is/No Work</span>
  </div>
</div>

<div *ngIf="showTooltip && type === 'workScope-project-list'" class="custom-tooltip-content6">
  <div class="status-item">
    <span>Pending</span>
  </div>
  <div class="status-item">
    <span>Cancelled</span>
  </div>
  <div class="status-item">
    <span>Construction</span>
  </div>
  <div class="status-item">
    <span>Compliance</span>
  </div>
  <div class="status-item">
    <span>Cosmetic</span>
  </div>
  <div class="status-item">
    <span>As is/No Work</span>
  </div>
</div>

<!-- Amendment Status -->
<div *ngIf="showTooltip && type === 'amendmentStatus'" class="custom-tooltip-content9">
  <div class="status-item">
    <mat-icon class="notSet-icon">circle</mat-icon>
    <span>Pending</span>
  </div>
  <div class="status-item">
    <mat-icon class="check-icon">task_alt</mat-icon>
    <span>Submitted</span>
  </div>
  <div class="status-item">
    <mat-icon class="close-icon">close</mat-icon>
    <span>Cancelled</span>
  </div>
</div>

<!-- <div *ngIf="showTooltip && type === 'dataManagementStatus'" class="custom-tooltip-content10">
  <div class="status-item">
    <mat-icon class="notSet-icon">circle</mat-icon>
    <span>Pending</span>
  </div>
  <div class="status-item">
    <mat-icon class="check-icon">task_alt</mat-icon>
    <span>Submitted</span>
  </div>
  <div class="status-item">
    <mat-icon class="close-icon">close</mat-icon>
    <span>Cancelled</span>
  </div>
</div> -->
<!-- Data Management Status -->
<div *ngIf="showTooltip && type === 'dataManagementStatus'" class="custom-tooltip-content6">
  <div class="status-item">
    <mat-icon class="notSet-icon">circle</mat-icon>
    <span>Pending</span>
  </div>
  <div class="status-item">
    <mat-icon class="alert-icon">change_circle</mat-icon>
    <span>In Process</span>
  </div>
  <div class="status-item">
    <mat-icon class="check-icon">task_alt</mat-icon>
    <span>Completed</span>
  </div>
  <div class="status-item">
    <mat-icon class="close-icon">close</mat-icon>
    <span>Cancelled</span>
  </div>
  <div class="status-item">
    <mat-icon class="check-icon-blue">task_alt</mat-icon>
    <span>PTO (Property Take-on)</span>
  </div>
</div>

<!-- Work Scope -->
<div *ngIf="showTooltip && type === 'workScope-pocket-level'" class="custom-tooltip-content11">
  <div class="status-item">
    <span>Pending (not yet set)</span>
  </div>

  <div class="status-item">
    <span>Construction</span>
  </div>

  <div class="status-item">
    <span>Compliance</span>
  </div>

  <div class="status-item">
    <span>Cosmetic</span>
  </div>

  <div class="status-item">
    <span>As Is/No Work</span>
  </div>

  <div class="status-item">
    <span>Instruction Cancelled</span>
  </div>

  <div class="status-item">
    <span>Project Cancelled</span>
  </div>

  <div class="status-item">
    <span>PTO (property take-on)</span>
  </div>
</div>

<div *ngIf="showTooltip && type === 'dirStatus'" class="custom-tooltip-content14">
  <div class="status-item">
    <span>Pending</span>
  </div>
  <div class="status-item">
    <span>Submitted</span>
  </div>
  <div class="status-item">
    <span>Area Proposal Completed</span>
  </div>
  <div class="status-item">
    <span>Area Proposal Accepted</span>
  </div>
  <div class="status-item">
    <span>Instruction Cancelled</span>
  </div>
  <div class="status-item">
    <span>Completed</span>
  </div>
</div>

<div *ngIf="showTooltip && type === 'dirDataManagementStatus'" class="custom-tooltip-content15">
  <div class="status-item">
    <mat-icon class="notSet-icon">circle</mat-icon>
    <span>Pending</span>
  </div>
  <div class="status-item">
    <mat-icon class="alert-icon">change_circle</mat-icon>
    <span>In Process</span>
  </div>
  <div class="status-item">
    <mat-icon class="check-icon">task_alt</mat-icon>
    <span>Completed</span>
  </div>
  <div class="status-item">
    <mat-icon class="close-icon">close</mat-icon>
    <span>Cancelled</span>
  </div>
</div>
