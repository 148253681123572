import { Component, Output, ViewChild, EventEmitter, OnInit } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { DataService } from '@app/core/services/data-service';
import { FlatTreeControl } from '@angular/cdk/tree';

export interface IClientNode {
  id: string;
  expandable: boolean;
  name: string;
  isProperty: boolean;
  level: number;
}

export interface ClientNode {
  id: string;
  name: string;
  isProperty: boolean;
  children?: ClientNode[];
}

@Component({
  selector: 'app-client-profile-tree',
  templateUrl: './client-profile-tree.component.html',
  styleUrls: ['./client-profile-tree.component.scss']
})
export class ClientProfileTreeComponent implements OnInit {
  @ViewChild('tree') tree: any;
  @Output() nodeSelected = new EventEmitter<IClientNode>();

  private _transformer = (node: ClientNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      id: node.id,
      isProperty: node.isProperty,
      level: level
    };
  };

  selectedNodeId: string = '';

  treeControl = new FlatTreeControl<IClientNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(private dataService: DataService) {
    //
  }

  hasChild = (_: number, node: IClientNode) => node.expandable;

  ngOnInit() {
    this.getTreeData();
  }

  getTreeData() {
    this.dataService.ClientProfileTreeView().subscribe((val) => {
      this.dataSource.data = val;
      this.treeControl.expandAll();
    });
  }

  selectNode(node: IClientNode) {
    if (this.selectedNodeId == node.id) {
      this.selectedNodeId = '';
    } else {
      this.selectedNodeId = node.id;
    }
    this.nodeSelected.emit(node);
  }
}
