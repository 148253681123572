import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { IconSettings } from '../../../classes/iconSettings';

@Component({
  selector: 'app-icon-column',
  templateUrl: './icon-column.component.html',
  styleUrls: ['./icon-column.component.scss']
})
export class IconColumnComponent implements OnInit, OnChanges {
  @Input() iconIn: IconSettings = new IconSettings();
  @Input() currentLevel: number = 1;
  @Output() iconClicked = new EventEmitter<any>();
  @Input() elementIn: string = '';

  icon!: IconSettings;
  tooltip = '';
  constructor() {}

  ngOnInit() {
    this.icon = this.iconIn;
    this.tooltip = this.customFormatToolTip(this.icon.hoverText ?? '');
  }

  ngOnChanges() {
    this.icon = this.iconIn;
    this.tooltip = this.customFormatToolTip(this.icon.hoverText ?? '');
  }

  toggleClicked() {
    this.iconClicked.emit({ option: this.icon.clickResponse, element: this.elementIn });
  }

  // ONLY RELEVANT FOR E-PORTFOLIO
  customFormatToolTip(text: string) {
    if (this.currentLevel == 1) {
      text = text.replace('{level}', 'Building');
    }
    if (this.currentLevel == 2) {
      text = text.replace('{level}', 'Floor');
    }
    if (this.currentLevel == 3) {
      text = text.replace('{level}', '');
    }
    return text;
  }
}
