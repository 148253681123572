import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { GoogleMapsAutcompleteComponent } from './components/google-maps/google-maps-autocomplete.component';
import { fileUploaderComponent } from './components/fileUploader/fileUploader.component';
import { fileViewerComponent } from './components/fileViewer/fileViewer.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { UploadLeaseDocumentDialogComponent } from '@app/shared/components/upload-lease-document/upload-lease-document.component';
import { ColumnFilterComponent } from './components/smart-filter/column-filter/column-filter.component';
import { BasicColumnFilterComponent } from './components/smart-filter/basic-column-filter/basic-column-filter.component';
import { NumberFormatterDirective } from './components/numberFormatterDirective/number-formatter.directive';
import { BuildingSelectorComponent } from './components/buildingSelector/buildingSelectorComponent.component';
import { IframeComponent } from './components/iframe/iframe.component';
import { MultiSelectFilterComponent } from './components/smart-filter/multi-select-filter/multi-select-filter.component';
import { ClientProfileTreeComponent } from './components/ClientProfileTree/client-profile-tree.component';
import { customActionPopUpComponent } from './components/customActionPopUp/customActionPopUp.component';
import { SwitchUserComponent } from './components/switchUser/switch-user.component';
import { CardGraphComponent } from './components/graphs/card-graph/card-graph.component';
import { AdvancedPieGraphComponent } from './components/graphs/advanced-pie-graph/advanced-pie-graph.component';
import { VerticalBarCartComponent } from './components/graphs/vertical-bar-chart/vertical-bar-chart.component';
import { PieGridGraphComponent } from './components/graphs/pie-grid-graph/pie-grid-graph.component';
import { TableComponent } from './components/tree-table/components/table/table.component';
import { TextColumnComponent } from './components/tree-table/components/columns/text-column/text-column.component';
import { IconColumnComponent } from './components/tree-table/components/columns/icon-column/icon-column.component';
import { DateColumnComponent } from './components/tree-table/components/columns/date-column/date-column.component';
import { NumberColumnComponent } from './components/tree-table/components/columns/number-column/number-column.component';
import { MatOptionColumnComponent } from './components/tree-table/components/columns/mat-options-column/mat-options-column.component';
import { HoverColumnComponent } from './components/tree-table/components/columns/hover-column/hover-column.component';
import { HyperlinkColumnComponent } from './components/tree-table/components/columns/hyperlink-column/hyperlink-column.component';
import { ButtonColumnComponent } from './components/tree-table/components/columns/button-column/button-column.component';
import { ColumnControllerComponent } from './components/tree-table/components/columns/column-controller/column-controller.component';
import { ExpandColumnComponent } from './components/tree-table/components/columns/expand-column/expand-column.component';
import { IconEnumColumnComponent } from './components/tree-table/components/columns/icon-enum-column/icon-enum-column.component';
import { MatDropdownColumnComponent } from './components/tree-table/components/columns/mat-dropdown-column/mat-dropdown-column.component';
import { pendingInstructionsPopUpComponent } from './components/pendingInstructionsPopUp/pendingInstructionsPopUp.component';
import { SelectorColumnComponent } from './components/tree-table/components/columns/selector-column/selector-column.component';
import { WogDropdownColumnComponent } from './components/tree-table/components/columns/wog_dropdown/wog_dropdown-column.component';
import { WogNumericFieldColumnComponent } from './components/tree-table/components/columns/wog_numericField/wog_numeric-field-column.component';
import { WogTextFieldColumnComponent } from './components/tree-table/components/columns/wog_textField/wog_text-field-column.component';
import { UpdateWarehouseDialogComponent } from './components/dw_UpdateDialog/update-warehouse-dialog.component';
import { MaintainUserDialogComponent } from './components/maintainUser/maintain-user.component';
import { CustomTooltipComponent } from './components/customTooltip/custom-tooltip.component';
import { CustomTableTooltipComponent } from './components/tree-table/components/tooltip/custom-table-tooltip.component';
import { TooltipDialogComponent } from './components/tree-table/components/tooltip/tooltip-dialog/tooltip-dialog.component';
import { SweetAlertComponent } from './components/sweet-alert/sweet-alert.component';
import { PropertyBuilderComponent } from '@app/shared/components/propertyBuilder/property-builder.component';
import { ReorderStructureDialogComponent } from './components/reorderStructureDialog/reorder-structure.component';
import { ErrorDialogComponent } from './components/dialogs/ErrorDialog/error-dialog.component';
import { ProgressDialogComponent } from './components/dialogs/ProgressDialog/progress-dialog.component';
export const sharedDialogs: any[] = [];

export const sharedComponents: any[] = [
  IframeComponent,
  GoogleMapsAutcompleteComponent,
  fileUploaderComponent,
  fileViewerComponent,
  BuildingSelectorComponent,
  pendingInstructionsPopUpComponent,
  UploadLeaseDocumentDialogComponent,
  ColumnFilterComponent,
  BasicColumnFilterComponent,
  NumberFormatterDirective,
  MultiSelectFilterComponent,
  ClientProfileTreeComponent,
  customActionPopUpComponent,
  SwitchUserComponent,
  CardGraphComponent,
  AdvancedPieGraphComponent,
  VerticalBarCartComponent,
  PieGridGraphComponent,
  TableComponent,
  TextColumnComponent,
  IconColumnComponent,
  DateColumnComponent,
  NumberColumnComponent,
  MatOptionColumnComponent,
  HoverColumnComponent,
  HyperlinkColumnComponent,
  ButtonColumnComponent,
  ColumnControllerComponent,
  ExpandColumnComponent,
  IconEnumColumnComponent,
  MatDropdownColumnComponent,
  SelectorColumnComponent,
  WogNumericFieldColumnComponent,
  WogDropdownColumnComponent,
  WogTextFieldColumnComponent,
  UpdateWarehouseDialogComponent,
  MaintainUserDialogComponent,
  CustomTooltipComponent,
  CustomTableTooltipComponent,
  TooltipDialogComponent,
  SweetAlertComponent,
  PropertyBuilderComponent,
  ReorderStructureDialogComponent,
  ErrorDialogComponent,
  ProgressDialogComponent
];

export const sharedDirectives: any[] = [];

export const sharedPipes: any[] = [];

export const material = [
  MatProgressSpinnerModule,
  MatButtonModule,
  MatCheckboxModule,
  MatSidenavModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSortModule,
  MatListModule,
  MatIconModule,
  MatInputModule,
  MatFormFieldModule,
  MatRadioModule,
  MatSlideToggleModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatButtonToggleModule,
  MatTableModule,
  MatTabsModule,
  MatMenuModule,
  MatTooltipModule,
  MatCardModule,
  MatAutocompleteModule,
  MatPaginatorModule,
  MatBadgeModule,
  MatDialogModule,
  MatChipsModule,
  MatExpansionModule,
  MatToolbarModule,
  MatTreeModule,
  MatSnackBarModule,
  MatStepperModule
];
export const charts = [];
