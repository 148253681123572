import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PendingRequestsInterceptor implements HttpInterceptor {
  pendingRequestsStatus$ = new ReplaySubject<boolean>();
  private pendingRequests = 0;
  private filteredUrlPatterns: RegExp[] = [];
  private filteredMethods: string[] = [];
  private filteredHeaders: string[] = [];
  private forceByPass = false;

  get PendingRequestsStatus$(): Observable<boolean> {
    return this.pendingRequestsStatus$.asObservable();
  }

  get PendingRequests(): number {
    return this.pendingRequests;
  }

  get FilteredUrlPatterns(): RegExp[] {
    return this.filteredUrlPatterns;
  }

  set FilteredMethods(httpMethods: string[]) {
    this.filteredMethods = httpMethods;
  }

  set FilteredHeaders(value: string[]) {
    this.filteredHeaders = value;
  }

  set ForceByPass(value: boolean) {
    this.forceByPass = value;
  }

  public Loading(val: boolean) {
    this.pendingRequestsStatus$.next(val);
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const shouldBypass = this.ShouldBypass(req);

    if (!shouldBypass) {
      // prevents on change errors
      setTimeout(() => {
        this.pendingRequests++;

        if (1 === this.pendingRequests) {
          this.pendingRequestsStatus$.next(true);
        }
      });
    }

    return next.handle(req).pipe(
      finalize(() => {
        if (!shouldBypass) {
          this.pendingRequests--;

          if (0 === this.pendingRequests) {
            this.pendingRequestsStatus$.next(false);
          }
        }
      })
    );
  }

  private ShouldBypassUrl(url: string): boolean {
    return this.filteredUrlPatterns.some((e) => {
      return e.test(url);
    });
  }

  private ShouldBypassMethod(req: HttpRequest<any>): boolean {
    return this.filteredMethods.some((e) => {
      return e.toUpperCase() === req.method.toUpperCase();
    });
  }

  private ShouldBypassHeader(req: HttpRequest<any>): boolean {
    return this.filteredHeaders.some((e) => {
      return req.headers.has(e);
    });
  }

  private ShouldBypass(req: HttpRequest<any>): boolean {
    return this.forceByPass || this.ShouldBypassUrl(req.urlWithParams) || this.ShouldBypassMethod(req) || this.ShouldBypassHeader(req);
  }
}
